import React from 'react';
import { TextInput } from '../form/textInput';
import classNames from 'classnames';
import styles from 'components/jobTalentSearchFilters/jobTalentSearchFilters.module.scss';
import formStyles from 'components/formColumns/forms.module.scss';
import jobStyles from 'components/jobForm/jobForm.module.scss';
import { MdClose as CloseIcon } from 'react-icons/md';

export const JobTalentSearchKeyword = (props) => {
  const { filterOpenMobile, onClickClose, form } = props;
  return (
    <div>
      <span>
        <div className={classNames(styles.compCardTitleContainer)}>
          <span className={classNames(styles.dividerTextLarge)}>FILTER</span>
          <span className={classNames(styles.pullRight, styles.crossIcon)}>
            <a className={classNames(styles.link)} onClick={() => form.reset()}>
              Clear all
            </a>
            <CloseIcon
              className={classNames('d-md-none ms-2', styles.closeFilterMobile)}
              onClick={onClickClose}
              size={20}
            />
          </span>
        </div>

        <div className={classNames(styles.compCardContent, styles.noPaddingBottom)}>
          <form className={classNames('form w-100', formStyles.form)}>
            <div className={classNames(formStyles.formInnerWrapper, 'form-inner-wrapper')}>
              <div className={classNames(jobStyles.fieldWrapper)}>
                <TextInput
                  name="keyword"
                  type="text"
                  placeHolder="Keyword Search"
                  autoFocus
                  className="w-100"
                />
                {/* <Field
                  
                  component={Input}
                  onChange={(event) => {
                    handleFormSearch('keyword', event.target.value);
                  }}
                /> */}
              </div>
            </div>
          </form>
        </div>
      </span>
    </div>
  );
};
