import React from 'react';

import { Header } from '@components/header/header';
import { NavItem } from '@components/nav/navItem';
import { MenuItem } from '@components/menuItem/menuItem';
import { Labeled } from '@components/labeled/labeled';
import { MdSettings as SettingsIcon } from 'react-icons/md';
import { avatarOptions } from '@components/avatar/avatar';

import styles from '@components/nav/nav.module.scss';
import { WizardSteps } from '@components/wizardSteps/wizardSteps';
import { wizardViews } from '@constants/wizardViews';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { routesPath } from '@constants/routesPath';

export const ClientHeader = ({
  newEntry,
  client,
  active,
  children,
  onEdit,
  onRemove,
  onCancel,
  projects,
  clientData,
  pipeline,
  recruiter,
}) => {
  const navigate = useNavigate();
  const activeWizardStep = useSelector((state) => state.commonReducer.wizardCurrentView.step);
  const params = useParams();
  const location = useLocation();
  return (
    <>
      {newEntry && (
        <Header backLink={'/'} newEntry={newEntry} onCancel={onCancel}>
          <>
            <WizardSteps
              activeStep={activeWizardStep}
              data={wizardViews.client(client?.id || params.clientId)}
            />
            {children}
          </>
        </Header>
      )}
      {client && (
        <Header
          // avatar={{ src: client.logo }}
          // title={client.title}
          // link={routes.showClient(client.id)}
          // newEntry={newEntry}
          backLinkButton
          onCancel={onCancel}
        >
          <WizardSteps
            activeStep={activeWizardStep}
            data={wizardViews.client(params?.clientId || params.clientId)}
          />

          {/* <NavItem link={routes.showClient(client.id)} active={active === 'projects'}>
          <Labeled toCount={client.projects}>Projects</Labeled>
        </NavItem>
        <NavItem link={routes.clientContacts(client.id)} active={active === 'contacts'}>
          <Labeled toCount={client.contacts}>Client contacts</Labeled>
        </NavItem>

 */}

          {children}
        </Header>
      )}
      {projects && !pipeline && (
        <Header titleLeft={clientData?.title}>
          <NavItem
            link={routesPath?.getClientProjects(params?.clientId)}
            active={location?.pathname === routesPath.getClientProjects(params?.clientId)}
          >
            <Labeled>Projects</Labeled>
          </NavItem>
          <NavItem
            link={routesPath?.getClientContacts(params?.clientId)}
            active={location?.pathname === routesPath.getClientContacts(params?.clientId)}
          >
            <Labeled toCount={0}>Client Contacts</Labeled>
          </NavItem>
          <NavItem
            title={<SettingsIcon size={20} />}
            zIndex={100}
            className={styles.cogIcon}
            hasDropdown
          >
            {onEdit && <MenuItem onClick={() => onEdit(client)}>Client settings</MenuItem>}
            {onRemove && <MenuItem onClick={() => onRemove(client)}>Remove client</MenuItem>}
          </NavItem>
        </Header>
      )}
      {pipeline && (
        <Header
          avatar={{ src: pipeline?.logo }}
          title={pipeline?.title}
          // link={routes.showClient(client.id)}
          // newEntry={newEntry}
          color={pipeline?.colour?.hex}
          backLinkButton
          // titleLeft={clientData?.title}
        >
          <NavItem
            link={routesPath?.projectPipeline(pipeline?.id, params.pipelineId)}
            active={
              location?.pathname === routesPath.projectPipeline(pipeline?.id, params.pipelineId)
            }
          >
            <Labeled>Pipeline</Labeled>
          </NavItem>
          <NavItem
            link={routesPath?.projectCandidates(pipeline?.id)}
            active={location?.pathname === routesPath.projectCandidates(pipeline?.id)}
          >
            <Labeled toCount={12}>Candidates</Labeled>
          </NavItem>
          {/* <NavItem
            link={routesPath?.projectTasks(pipeline?.id)}
            active={location?.pathname === routesPath.projectTasks(pipeline?.id)}
          >
            <Labeled toCount={12}>Tasks</Labeled>
          </NavItem> */}
          {/* <NavItem
            link={routesPath?.projectNotes(pipeline?.id)}
            active={location?.pathname === routesPath.projectNotes(pipeline?.id)}
          >
            <Labeled toCount={12}>Notes</Labeled>
          </NavItem> */}
          <NavItem
            title={<SettingsIcon size={20} />}
            zIndex={100}
            className={styles.cogIcon}
            link={routesPath?.updateProject(params.pipelineId, pipeline?.id)}
            active={location?.pathname === routesPath.updateProject(params.pipelineId, pipeline?.id)}
          >
          </NavItem>
        </Header>
      )}
      {recruiter && (
        <Header
          title={location?.pathname === routesPath?.recruitersList() ? recruiter?.title : 'Teams'}
        >
          <NavItem
            link={routesPath?.recruitersList()}
            active={location?.pathname === routesPath?.recruitersList()}
          >
            <Labeled toCount={recruiter?.list}>All recruiters</Labeled>
          </NavItem>
          <NavItem
            link={routesPath?.recruitersTeams()}
            active={location?.pathname === routesPath.recruitersTeams()}
          >
            <Labeled toCount={0}>Teams</Labeled>
          </NavItem>
          <NavItem
            link={routesPath?.recruitersTeams()}
            active={location?.pathname === routesPath.getClientContacts(clientData?.id)}
          >
            <Labeled toCount={0}>Archived</Labeled>
          </NavItem>
        </Header>
      )}
    </>
  );
};
