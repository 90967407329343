export const views = {
  talentProfile: 'tp',
  talentNotes: 'tn',
  talentEmails: 'te',
  talentNoteForm: 'tnf',
  contactProfile: 'cp',
  recruiterProfile: 'rp',
  filtersForm: 'ff',
  projectForm: 'pf',
  coverLetter: 'cl',
  confirmationDialog: 'cd',
  talentEmail: 'em',
};
