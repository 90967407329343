import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import Select from 'react-select';
import classNames from 'classnames';
import styles from '../clientForm/clientForm.module.scss';
import stylesForms from '../formColumns/forms.module.scss';

export const SelectInput = (props) => {
  const {
    name,
    label,
    options,
    mode,
    required,
    searchable,
    icon,
    fieldDescription,
    focused,
    isFocused,
    placeholder,
    disabled,
    formatOptionLabel,

    valueColor,
  } = props;

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      padding: 0,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      paddingLeft: icon ? '25px' : 0,
      color: valueColor,
    }),

    placeholder: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      marginLeft: 0,
      padding: 0,
      paddingLeft: icon ? '25px' : 0,
    }),
  };

  const {
    register,
    formState: { errors, touchedFields },
    unregister,
    setValue,
  } = useFormContext();
  const originalValue = useWatch({ name: name }) || [];

  useEffect(() => {
    register(name);
  }, [register, name]);

  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, []);

  const value = () => {
    if (mode === 'multiple') {
      return valueMultiple();
    } else {
      return valueOne();
    }
  };

  const valueMultiple = () => {
    if (originalValue && options) {
      return originalValue?.map((value) => options?.find((option) => option.value == value));
    }

    return [];
  };

  const valueOne = () => {
    if (originalValue != null && options) {
      return options?.find((option) => option.value == originalValue);
    }

    return null;
  };

  const handleSelect = (data) => {
    const { mode, onChange } = props;

    onChange && onChange(name, data);
    if (mode === 'multiple') {
      return handleSelectMultiple(data);
    } else {
      return handleSelectOne(data);
    }
  };

  const handleSelectMultiple = (values) => {
    if (!values) {
      setValue(name, [], { shouldValidate: true });

      return;
    }

    const newValue = values
      ?.map((data) => (data ? data.value : data))
      .filter((value) => value != null);

    setValue(name, newValue, { shouldValidate: true });
  };

  const handleSelectOne = (data) => {
    if (!data) {
      setValue(name, null, { shouldValidate: true });
      return;
    }

    setValue(name, data.value, { shouldValidate: true });
  };

  return (
    <div className={`form-group ${label ? `mt-sm-2 pt-2 ` : `mt-2`}`} style={{ clear: 'both' }}>
      <span
        className={classNames(
          'form-elements',
          stylesForms.inputWrapper,
          { [stylesForms.none]: isFocused },
          { [stylesForms.inputFocused]: focused },
        )}
      >
        <span
          className={classNames('form-elements-wrapper', stylesForms.inputInnerWrapper, {
            [stylesForms.hasIcon]: icon,
          })}
        >
          {label && (
            <label className={stylesForms.label} htmlFor={name}>
              {required && <small className="text-danger">* </small>}
              {label}
            </label>
          )}
          {icon && (
            <label
              style={{ zIndex: 9, marginLeft: '2px' }}
              className={classNames(stylesForms.icon)}
            >
              {icon}
            </label>
          )}

          {fieldDescription && <span className={styles.clientDescription}>{fieldDescription}</span>}
          <span className="form-element">
            {/* {icon} */}
            <Select
              onChange={handleSelect}
              options={options || []}
              value={value()}
              isMulti={mode === 'multiple'}
              styles={customStyles}
              isSearchable={searchable}
              placeholder={placeholder}
              isDisabled={disabled}
              className={'selectInputStylesContainer'}
              classNamePrefix={'selectInputStyles'}
              formatOptionLabel={formatOptionLabel}
              // className={`${stylesForms.input} ${
              //   errors[name]?.message ? 'form-control border-danger' : 'form-control'
              // }`}
            />
          </span>
        </span>
        <small className="text-danger float-start" style={{ clear: 'both' }}>
          {errors[name]?.message}
        </small>
      </span>
    </div>
  );
};
