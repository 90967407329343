import { authHeader } from '../../constants/authenticationHeader';
import { baseSliceWithTags } from '../baseSlice';

const list = (path, module) => {
  const listApi = baseSliceWithTags.injectEndpoints({
    endpoints: (builder) => {
      return {
        [module + 'list']: builder.query({
          query: (payload) => {
            return {
              url: path + (payload?.extendedPath || ''),
              method: 'get',
              csrf: authHeader(),
              params: payload?.params,
            };
          },
          providesTags: [module],
        }),
        [module + 'detail']: builder.query({
          query: (payload) => ({
            url: path + (payload?.extendedPath || ''),
            method: 'get',
            csrf: authHeader(),
            params: payload?.params,
          }),
          providesTags: [module],

          keepUnusedDataFor: 0.0001,
        }),
      };
    },
    overrideExisting: true,
  });
  return listApi;
};

export default list;
