import React, { useState } from 'react';
import classNames from 'classnames';

import styles from '../formColumns/forms.module.scss';

export const GenericInput = (props) => {
  const {
    id,
    name,
    type,
    icon,
    iconRight,
    label,
    meta,
    input,
    className,
    description,
    onClick,
    isFocused,
    navSearch,
    checked,
    ...rest
  } = props;
  const identifier = id || name || (input && input.name);
  const error = meta && meta.error;
  const touched = meta && meta.touched;

  const [focused, setFocused] = useState(props.isFocused);

  const onToggleFocus = () => {
    setFocused(!focused);
  };

  return (
    <div>
      <span
        onClick={onClick}
        className={classNames(
          'form-elements',
          styles.inputWrapper,
          { [styles.none]: isFocused },
          { [styles.inputFocused]: focused },
          className,
        )}
      >
        <span
          className={classNames('form-elements-wrapper', styles.inputInnerWrapper, {
            [styles.hasIcon]: icon,
          })}
        >
          {icon && (
            <label htmlFor={(id && id) || name} className={classNames(styles.icon)}>
              <span>{icon}</span>
            </label>
          )}

          {label && type !== 'checkbox' && (
            <label htmlFor={identifier} className={classNames('element-label', styles.label)}>
              {label}
              {description && (
                <p className={classNames('element-description', styles.description)}>
                  {description}
                </p>
              )}
            </label>
          )}

          <span className={classNames('form-element')}>
            {type === 'textarea' ? (
              <textarea
                {...input}
                {...rest}
                id={identifier}
                name={name}
                role="textbox"
                className={styles.input}
              />
            ) : (
              <input
                {...input}
                {...rest}
                id={identifier}
                name={name}
                type={type}
                className={classNames(
                  { [styles.navSearch]: navSearch },
                  type !== 'checkbox' ? styles.input : styles.checkbox,
                )}
                onBlur={onToggleFocus}
                onFocus={onToggleFocus}
                checked={checked}
              />
            )}

            {type === 'checkbox' && (
              <label
                htmlFor={identifier}
                className={classNames('checkbox-label', styles.inputCheckboxLabel, {
                  [styles.checked]: (input && input.checked) || (rest && rest.checked) || checked,
                })}
              >
                <strong>{label}</strong>
                {description && <p className={styles.fieldLabelDescription}>{description}</p>}
              </label>
            )}
          </span>
        </span>
        {touched && error && (
          <span className={classNames(styles.fieldError, 'form-error-element')}>{error}</span>
        )}
      </span>
    </div>
  );
};

GenericInput.defaultProps = {
  isFocused: false,
};
