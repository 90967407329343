import destroy from '../apiService/destroy';
import form from '../apiService/form';
import list from '../apiService/list';

const search = {};
search.list = () => list('/rest/saved-searches', 'search');
search.form = () => form('/rest/saved-searches', 'search');
search.delete = () => destroy('/rest/saved-searches/delete', 'search');

export default search;
