import React, { useEffect, useState } from 'react';
import { isEmpty, map, size } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@components/button/button';
import { EmptyCard } from '@components/emptyCard/emptyCard';
import PageListSection from '@components/pageListSection/pageListSection';
import styles from '@components/dashboard/dashboard.module.scss';
import style from './teamListing.module.scss';
import { handleCurrentView, handleViewsData } from '../../redux/common/commonSlice';
import { views } from 'constants/views';
import classnames from 'classnames';
import { TeamCard } from 'components/teamCard/teamCard';
import { MdAdd as AddIcon } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { routesPath } from '../../constants/routesPath';
import dataService from '../../services/dataService';
import teams from '../../redux/modules/teams';
import { Spinner } from '../loader/loader';

export const TeamListing = (props) => {
  const {
    contacts,
    clients,
    visibleContacts,
    visibleClients,
    contactsFilter,
    clientsFilter,
    onProjectSearch,
    onProjectAdd,
    onProjectRemove,
    onProjectEdit,
    onClientSearch,
    onClientAdd,
    onClientRemove,
    onClientEdit,
    searchedContactsData,
  } = props;

  const navigate = useNavigate();

  const {
    data: teamsData,
    error: teamsError,
    isFetching: teamsLoading,
  } = teams.list().useTeamslistQuery();

  const [parsedTeams, setParsedTeams] = useState(null);

  useEffect(() => {
    if (teamsData) {
      setParsedTeams(dataService.parseTeamsListing(teamsData?.data));
    }
  }, [teamsData]);

  const dispatch = useDispatch();

  return (
    <div className={classnames(styles.dashboardWrappper, 'mainPanelRight')}>
      <PageListSection
        // title="Teams"
        onSearch={2}
        searchQuery={contactsFilter}
        onAdd={() => navigate(routesPath.recruitersTeams(), { replace: false })}
        searchPlaceholder="Search Teams"
      >
        {teamsLoading ? (
          <Spinner height={100} width={100} />
        ) : isEmpty(parsedTeams) ? (
          <EmptyCard
            isCard
            title="Things are looking a little bare here."
            description="You haven’t added any teams yet."
          >
            <Button submit onClick={onProjectAdd} inline>
              New Team
            </Button>
          </EmptyCard>
        ) : (
          <div
            className={classnames(styles.pageSectionContainer, 'flex-wrap')}
            style={{ width: `${size(contacts) * (364 + 20) - 20}px` }}
          >
            {map(parsedTeams, (team) => (
              <TeamCard
                key={`team-${team.id}`}
                team={team}
                onRemove={onProjectRemove}
                onEdit={onProjectEdit}
                client={clients}
                onClick={() =>
                  dispatch(handleViewsData({ contact: contact, currentView: views.contactProfile }))
                }
              />
            ))}
            <div className={style.createNewTeam}>
              <div>
                <AddIcon size={25} style={{ verticalAlign: 'center' }} />
              </div>
              Create New team
            </div>
          </div>
        )}
      </PageListSection>
    </div>
  );
};
