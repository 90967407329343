import axios from 'axios';

export const dropZonefileUploader = async (file, sku) => {
  let url = `https://api.cloudinary.com/v1_1/botics-ai/image/upload/`;
  const formData = new FormData();

  try {
    formData.append('file', file);

    const response = await axios.post(
      url,
      formData,
      {
        params: {
          folder: `tmr/organisation/uploads/notes/attachments`,
          upload_preset: process.env.REACT_APP_CLOUD_FLARE_UPLOAD_PRESET,
          cloud_name: process.env.REACT_APP_CLOUD_FLARE_NAME,
          API_KEY: process.env.REACT_APP_CLOUD_FLARE_API_KEY,
          API_SECRET: process.env.REACT_APP_CLOUD_FLARE_SECRET_KEY,
        },
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return { attachment: response.data?.secure_url, name: response.data?.original_filename };
  } catch (error) {
    return error;
  }
};
