import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { MenuItem as ReactMenuItem } from 'react-menu-list';
import styles from '../nav/nav.module.scss';
import { AdvancedLink } from '../advancedLink/advancedLink';
import { Button } from '../button/button';

export const MenuItem = ({ link, externalLink, onClick, disabled, className, children }) => (
  <div>
    <ReactMenuItem
      className={`menu-item
          ${disabled ? 'menu-item-disabled' : ''} ${styles.menuItem} ${className}`}
    >
      {externalLink && (
        <AdvancedLink href={externalLink} target="_blank" rel="noopener noreferrer">
          {children}
        </AdvancedLink>
      )}
      {link && <Link to={link}>{children}</Link>}
      {onClick && (
        <Button
          disabled={disabled}
          style={{ paddingLeft: 10 + 'px' }}
          settingsMenu
          onClick={onClick}
        >
          {children}
        </Button>
      )}
    </ReactMenuItem>
  </div>
);
