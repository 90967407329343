import React from 'react';
import { CoverLetter } from 'components/coverLetter/coverLetter';
import { OverLay } from '../../components/overlay/overLay';
import { OverLayHeading } from '../../components/overLayHeading/overLayHeading';
import { isEmpty } from 'lodash';
import { views } from '../../constants/views';
import { ConfirmationDialog } from '../../components/confirmationDialog/confirmationDialog';
import { Heading } from '../../components/heading/heading';

export const OverlayContainer = (props) => {
  const {
    closeOverlay,
    children,
    talentCoverLetter,
    currentView,
    talentName,
    talentJobTitle,
    talentAvatar,
    acceptConfirmation,
    declineConfirmation,
  } = props;

  return (
    <div>
      <OverLay
        onClose={closeOverlay}
        fullSize={!isEmpty(currentView) && currentView === views.coverLetter}
        isOpen={!isEmpty(currentView)}
      >
        {currentView === views.coverLetter && (
          <CoverLetter
            onClose={closeOverlay}
            talentCoverLetter={talentCoverLetter}
            talentJobTitle={talentJobTitle}
            talentName={talentName}
            talentAvatar={talentAvatar}
          />
        )}

        {currentView === views.confirmationDialog && (
          <>
            <ConfirmationDialog
              acceptLabel={'Yes, delete'}
              cancelLabel={'Cancel'}
              onConfirm={acceptConfirmation}
              onCancel={declineConfirmation}
              onClose={declineConfirmation}
              visible
            >
              {/* <Heading size="xsmall">Just checking...</Heading> */}
              <Heading size="medium">Are you sure you want to delete this item?</Heading>
            </ConfirmationDialog>
          </>
        )}

        {/* 
        {currentView === views.clientContactForm && (
          <>
            <OverlayHeading title="Contact details." icon={<UserAddIcon size={20} />} />
            <ContactFormContainer />
          </>
        )}

        {currentView === views.emailComposer && <EmailComposerContainer />}

        {currentView === views.confirmationDialog && (
          <>
            <ConfirmationDialog
              acceptLabel={'Yes, delete'}
              cancelLabel={'Cancel'}
              onConfirm={acceptConfirmation}
              onCancel={declineConfirmation}
              onClose={declineConfirmation}
              visible
            >
              <Heading size="xsmall">Just checking...</Heading>
              <Heading size="medium">Are you sure you want to delete this item?</Heading>
            </ConfirmationDialog>
          </>
        )} */}
      </OverLay>
      {children}
    </div>
  );
};
