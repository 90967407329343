import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from '@components/searchForm/searchForm.module.scss';
import { Button } from '../button/button';
import { TextInput } from '../form/textInput';
import { CheckBoxGroup } from '../form/checkBoxGroup';
// import { Badge } from '../badge/badge';
import { SelectInput } from '../form/selectInput';
import { Section } from '../section/section';
import { FormColumns } from '../formColumns/formColumns';
import { Form } from '../form/form';
import { Fieldset } from '../fieldSet/fieldSet';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yupSchemaService from '../../services/yupSchemaService';
import jobs from '../../redux/modules/jobs';
import { apiUrls } from '../../constants/apiurls';
import { Spinner } from '../loader/loader';
import dataService from '../../services/dataService';
import { GenericInput } from '../genericInput/genericInput';
import { fetchAutoCompleteData } from '../../services/autoCompleteService';
import { MemberShipGradesCheckBox } from '../memberShipGradesCheckBox/memberShipGradesCheckBox';
import { searchAliasis } from '../../constants/searchFilters';
import { handleCandidateSearchFilters, handleViewsData } from 'redux/common/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import stylesForms from '@components/formColumns/forms.module.scss';
import { CheckBox } from '../form/checkBox';
import { views } from '../../constants/views';
import { SearchFiltersSwitch } from '../searchFiltersSwitch/searchFiltersSwitch';

export const SearchFiltersForm = (props) => {
  const {
    isOpen,
    isAside,
    isMainFilter,
    isSearchSubmitted,
    setStates,
    showMainFilter,
    showContent,
  } = props;
  const dispatch = useDispatch();

  const candidatesFiltersUrl = useSelector((state) => state.commonReducer.candidatesFiltersUrl);
  const [state, setState] = useState({
    jobs: [],
    potitionLevels: [],
    departments: [],
    languages: [],
    maritalStatus: [],
    genders: [],
    qualifications: [],
    membershipGrades: [],
    userTags: [],
    loading: false,
  });

  const [initialValues, setInitialValues] = useState({});

  const form = useForm({
    resolver: yupResolver(yupSchemaService.searchCandidates()),
    mode: 'all',
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (candidatesFiltersUrl) {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      form.reset(dataService.parseSavedSearchUrl(candidatesFiltersUrl));
      let values = form.watch();
      form.handleSubmit(onSubmit(values));

      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  }, [candidatesFiltersUrl]);
  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const autoCompletedFields = [
        { type: 'positionLevels', url: apiUrls.getPositionLevels() },
        { type: 'departments', url: apiUrls.getDepartments() },
        { type: 'language', url: apiUrls.getLanguages() },
        { type: 'marital', url: apiUrls.getMaritalStatus() },
        { type: 'gender', url: apiUrls.getGenders() },
        { type: 'qualifications', url: apiUrls.getQualifications() },
        { type: 'membership', url: apiUrls.getMemberShipGrades() },
        { type: 'tags', url: apiUrls.getUserTags() },
      ];
      const promises = autoCompletedFields.map((field) =>
        fetchAutoCompleteData(field.type, field.url),
      );

      const response = await Promise.all(promises);
      setState((prevState) => ({
        ...prevState,
        potitionLevels: response[0],
        departments: response[1],
        languages: response[2],
        maritalStatus: response[3],
        genders: response[4],
        qualifications: response[5],
        membershipGrades: response[6],
        userTags: response[7],
        loading: false,
      }));
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const onSubmit = (values) => {
    let filterExpression = {};

    for (let fil in searchAliasis) {
      if (values[fil]) {
        if (Array.isArray(values[fil])) {
          let index = 0;
          values[fil].map((val) => {
            if (val) {
              filterExpression[`filters[${searchAliasis[fil]['alias']}][${[index]}]`] = val;
              index++;
            }
          });
        } else {
          filterExpression[`filters[${searchAliasis[fil]['alias']}][0]`] = values[fil];
        }
      }
    }
    if (!isEmpty(filterExpression)) {
      dispatch(handleCandidateSearchFilters(filterExpression));
      setStates((prevState) => ({
        ...prevState,
        isSearchSubmitted: true,
      }));
    }
  };

  useEffect(() => {
    if (!isEmpty(form.watch())) {
      let values = form.watch();

      let filterExpression = {};

      for (let fil in searchAliasis) {
        if (values[fil]) {
          if (Array.isArray(values[fil])) {
            let index = 0;
            values[fil].map((val) => {
              if (val) {
                filterExpression[`filters[${searchAliasis[fil]['alias']}][${[index]}]`] = val;
                index++;
              }
            });
          } else {
            filterExpression[`filters[${searchAliasis[fil]['alias']}][0]`] = values[fil];
          }
        }
      }
      if (!isEmpty(filterExpression) && isSearchSubmitted) {
        dispatch(handleCandidateSearchFilters(filterExpression));
      }
    }
  }, [form.watch()]);

  return (
    <>
      {isMainFilter && (
        <div style={{ marginBottom: '-27px', zIndex: '1' }}>
          <SearchFiltersSwitch
            size={'large'}
            onClick={() =>
              setStates((prevState) => ({
                ...prevState,
                showMainFilter: !prevState.showMainFilter,
              }))
            }
            isOpen={!showMainFilter}
          />
          {!showMainFilter && (
            <p className="me-2 mt-1" style={{ color: '#3CA8FD', marginLeft: '-10px' }}>
              Show Filters
            </p>
          )}
        </div>
      )}

      {showContent && (
        <div
          className={classNames(
            styles.searchFilters,
            { [styles.asideFilters]: isAside },
            { [styles.mailnFilters]: isMainFilter },
            { [styles.showFilters]: isOpen },
          )}
        >
          {' '}
          <FormProvider {...form}>
            {state.loading ? (
              <Spinner height={100} width={100} />
            ) : (
              <Form onSubmit={form.handleSubmit(onSubmit)} initialValues={initialValues}>
                {!isMainFilter && (
                  <div className={styles.updateFiltersContainer}>
                    <div className={styles.updateFilters}>
                      <Button type="submit" regular>
                        Update filters
                      </Button>
                    </div>
                  </div>
                )}

                <Section
                  title="Search by job details"
                  contentClassName={styles.sectionContent}
                  headingSize="large"
                  isToggleable
                >
                  <div className={classNames({ row: isMainFilter })}>
                    <div className={classNames({ 'col-lg-4 order-1': isMainFilter })}>
                      <SelectInput
                        name="job_titles"
                        label="With these job titles"
                        className={styles.selectJobTitles}
                        options={state.userTags}
                        mode="multiple"
                      />
                      <CheckBox
                        name="currentPositionOnly"
                        label="Current position only"
                        type="checkbox"
                        classNameLabel="my-2 mb-3"
                      />
                    </div>
                    <div className={classNames({ 'col-lg-12 order-last': isMainFilter })}>
                      <span className={classNames(stylesForms.label)}>Rank / Experience</span>
                      {showContent ? (
                        <CheckBoxGroup
                          classNameLabel="mb-2"
                          options={state.potitionLevels}
                          name="qualification_achieved"
                          type="checkbox"
                          label="Rank / Experience"
                          verticalDisplay={isMainFilter}
                        />
                      ) : (
                        <CheckBoxGroup
                          classNameLabel="mb-2"
                          options={state.potitionLevels}
                          name="qualification_achieved"
                          type="checkbox"
                          label="Rank / Experience"
                        />
                      )}
                      {}
                    </div>

                    <div className={classNames({ 'col-lg-4 order-4': isMainFilter })}>
                      <SelectInput
                        name="current_department"
                        label="Current department"
                        options={state.departments}
                      />
                    </div>
                    <div className={classNames({ 'col-lg-4 order-5': isMainFilter })}>
                      <TextInput
                        name="company"
                        type="text"
                        label="From company"
                        placeHolder="Type company name"
                      />
                    </div>
                  </div>
                </Section>
                <Section
                  title="Search by personal details"
                  contentClassName={styles.sectionContent}
                  headingSize="large"
                  isToggleable
                >
                  <div className={classNames({ row: isMainFilter })}>
                    <div className={classNames({ 'col-lg-5 ': isMainFilter })}>
                      <SelectInput
                        name="fluently_speaks"
                        label="Fluently speaks"
                        placeholder="Select languages"
                        options={state.languages}
                      />
                    </div>
                    <div className={classNames({ 'col-lg-3 ': isMainFilter })}>
                      <SelectInput
                        name="nationality"
                        label="Nationality"
                        placeholder="Select nationality"
                        options={state.languages}
                      />
                    </div>
                    <div className={classNames({ 'col-lg-2 ': isMainFilter })}>
                      <div>
                        <SelectInput
                          name="marital_status"
                          label="Marital status"
                          options={state.maritalStatus}
                          placeholder="Select"
                        />
                      </div>
                    </div>
                    <div className={classNames({ 'col-lg-2 ': isMainFilter })}>
                      <SelectInput name="gender" label="Gender" options={state.genders} />
                    </div>
                  </div>

                  {/* </FormColumns> */}
                </Section>
                <Section
                  title="Search by qualifications"
                  contentClassName={styles.sectionContent}
                  headingSize="large"
                  isToggleable
                >
                  <div className={styles.education}>
                    <div className={classNames({ row: isMainFilter })}>
                      <div className={classNames({ 'col-lg-8 ': isMainFilter })}>
                        <span className={classNames(stylesForms.label, 'mt-sm-2 pt-2')}>
                          Qualifications acheived
                        </span>
                        {isMainFilter ? (
                          <CheckBoxGroup
                            classNameLabel="mb-2"
                            options={state.qualifications}
                            name="rank_experience"
                            type="checkbox"
                            label="Rank / Experience"
                            verticalDisplay
                          />
                        ) : (
                          <CheckBoxGroup
                            classNameLabel="mb-2"
                            options={state.qualifications}
                            name="rank_experience"
                            type="checkbox"
                            label="Rank / Experience"
                          />
                        )}
                      </div>
                      <div className={classNames({ 'col-lg-4 ': isMainFilter })}>
                        <TextInput
                          name="studied_at_institution"
                          type="text"
                          label="Studied at institution"
                          placeholder="Type institution name"
                          className={styles.institutionInput}
                        />
                      </div>
                    </div>
                  </div>

                  <MemberShipGradesCheckBox
                    name="membership_grades"
                    label="Membership grades"
                    className={styles.messageInput}
                    options={state.membershipGrades}
                    type="radio"
                  />
                </Section>
              </Form>
            )}
          </FormProvider>
        </div>
      )}
    </>
  );
};
