import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { MdClose as CloseIcon } from 'react-icons/md';

import styles from './modal.module.scss';

export const Modal = ({ fixed, onClose, children }) => (
  <div className={classNames(styles.modalWrapper, { [styles.fixed]: fixed })}>
    {onClose && (
      <div className={styles.modalClose} onClick={onClose}>
        <CloseIcon size={20} />
      </div>
    )}
    {children}
  </div>
);
