import React, { useEffect, useState } from 'react';
import { FaBullseye as RadarIcon } from 'react-icons/fa';
import { MdSearch as SearchIcon } from 'react-icons/md';
import { AiFillFlag as FlagIcon } from 'react-icons/ai';
import { MdOutlineDoubleArrow as SortIcon } from 'react-icons/md';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import ReactTooltip from 'rc-tooltip';
import { Tooltip } from 'components/tooltip/tooltip';
import { MdAdd as AddIcon } from 'react-icons/md';
import { FaEllipsisH as EllipsisIcon } from 'react-icons/fa';

import { Form } from '../form/form';
import { Heading } from '../heading/heading';
import styles from './searchForm.module.scss';
import { Button } from '../button/button';
import yupSchemaService from '../../services/yupSchemaService';
import { TextInput } from '../form/textInput';
import { apiUrls } from '../../constants/apiurls';
import { fetchAutoCompleteData } from '../../services/autoCompleteService';
import { SelectInput } from '../form/selectInput';
import users from '../../redux/modules/users';
import dataService from '../../services/dataService';
import { JobApplicantsTable } from '../jobApplicantsTable/jobApplicantsTable';
import tableStyles from '../table/table.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, includes } from 'lodash';
import { SavedSearches } from '../savedSearches/savedSearches';
import search from '../../redux/modules/search';
import toast from 'react-hot-toast';
import { handleCandidateFiltersUrls, handleViewsData } from '../../redux/common/commonSlice';
import { Spinner } from 'reactstrap';
import { ActionsBar } from '../actionsBar/actionsBar';
import { Avatar } from '../avatar/avatar';
import { SearchFiltersForm } from '@components/searchFiltersForm/searchFiltersForm';

import { views } from '@constants/views';

import { ProfileCard } from 'components/profileCard/profileCard';
import { TalentProfile } from 'components/talent/talentProfile';
import { TalentNotes } from 'components/talent/talentNotes';
import { SidebarTabs } from 'components/sidebar/sidebarTabs';
import { NavItem } from 'components/nav/navItem';
import { Labeled } from 'components/labeled/labeled';
import { Section } from 'components/section/section';
import projects from '../../redux/modules/projects';
import { ProjectsDropdown } from '../projectsDropdown/projectsDropdown';
import { RoundButton } from '../roundButton/roundButton';

export const SearchForm = (props) => {
  const { isSubmitting, setIsAppear } = props;
  const dispatch = useDispatch();
  const { data: projectsData, isLoading: projectsLoading } = projects.list().useProjectlistQuery();

  const [selectedSearh, setSeletedSearch] = useState(null);

  const [country, setCountry] = useState(null);

  const candidatesSearchFilters = useSelector(
    (state) => state.commonReducer.candidateSearchFilters,
  );

  const [state, setState] = useState({
    loading: false,
    countries: [],
    submitForm: false,
    parsedUsersData: null,
    isSeacrhSubmitted: false,
    showMainFilter: false,
  });

  const [initialValues, setInitialValues] = useState({});

  const form = useForm({
    resolver: yupResolver(yupSchemaService.searchCandidates()),
    mode: 'all',
    defaultValues: initialValues,
  });

  const getParams = () => {
    let filters = {};
    filters['keyword'] = form.getValues('keyword');
    if (!isEmpty(candidatesSearchFilters)) {
      for (let filter in candidatesSearchFilters) {
        filters[filter] = candidatesSearchFilters[filter];
      }
    }
    return filters;
  };

  const {
    data: usersData,
    isFetching: usersLoading,
    error: usersError,
    isSuccess: usersSuccess,
    refetch: usersRefetch,
  } = users.list().useUserslistQuery(
    {
      params: getParams(),
      extendedPath: apiUrls.searchUsers(),
    },
    { skip: !form.getValues('keyword') && !candidatesSearchFilters },
  );

  const {
    data: savedSearch,
    isLoading: savedSearchLoading,
    isSuccess: savedSearchSuccess,
    error: savedSearchError,
  } = search.list().useSearchdetailQuery(
    {
      params: { id: selectedSearh },
      extendedPath: '/get',
    },
    { skip: !selectedSearh },
  );

  const [createSaveSearch, createSaveSearchData] = search.form().useSearchcreateMutation();

  const [deleteSavedSearch, deleteSavedSearchData] = search.delete().useSearchdeleteMutation();
  const currentUIState = true;

  useEffect(() => {
    getInitailData();
  }, []);

  useEffect(() => {
    if (candidatesSearchFilters) {
      usersRefetch();
    }
  }, [candidatesSearchFilters]);

  useEffect(() => {
    if (createSaveSearchData?.isSuccess) {
      toast.success('Search Saved Successfully!');
    }
    if (createSaveSearchData.isError) {
      toast.error('Failed To Save Search!');
    }
  }, [createSaveSearchData]);

  useEffect(() => {
    if (usersData?.data) {
      let result = dataService.usersTable(
        usersData?.data?.results,
        getActions,
        getName,
        getFlag,
        getAlikeAttributes,
        SortIcon,
        country,
      );
      setState((prevState) => ({
        ...prevState,
        parsedUsersData: result,
      }));
      setIsAppear(true);
    }
  }, [usersData]);

  useEffect(() => {
    if (savedSearch?.data) {
      dispatch(handleCandidateFiltersUrls(savedSearch.data.search_url));
    }
  }, [savedSearch]);

  useEffect(() => {
    if (deleteSavedSearchData?.isSuccess) {
      toast.success('Search Deleted Successfully!');
    }
    if (deleteSavedSearchData.error) {
      toast.error('Failed To Delete Search!');
    }
  }, [deleteSavedSearchData]);

  const getInitailData = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const response = await fetchAutoCompleteData('language', apiUrls.getCountries());

      setState((prevState) => ({
        ...prevState,
        loading: false,
        countries: response,
      }));
    } catch (err) {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const onSubmit = (values) => {
    setState((prevState) => ({ ...prevState, submitForm: true }));
  };

  const onCountryChange = (name, data) => {
    setCountry(data?.label);
    if (usersData?.data) {
      let result = dataService.usersTable(
        usersData?.data?.results,
        getActions,
        getName,
        getFlag,
        getAlikeAttributes,
        SortIcon,
        data?.label,
      );
      setState((prevState) => ({
        ...prevState,
        parsedUsersData: result,
      }));
    }
  };

  const getName = (name, avatr, user) => {
    return (
      <div
        className="d-flex"
        onClick={() =>
          dispatch(
            handleViewsData({
              talent: { user: user },
              currentView: views.talentProfile,
            }),
          )
        }
      >
        <Avatar
          name={name}
          size={25}
          src={avatr}
          style={{
            zIndex: '2',
            display: 'inline-block',
            float: 'left',
            position: 'relative',
            marginRight: '5px',
          }}
          className={classNames('jobsListingAvatar')}
        />
        <span> {name}</span>
      </div>
    );
  };

  const getActions = (applicant) => {
    return (
      <>
        <ProjectsDropdown projectsData={projectsData.data} applicant={applicant} zIndex={12} inline>
          <RoundButton inline square submit size={'small'}>
            <AddIcon size={15} style={{ verticalAlign: 'center' }} />
          </RoundButton>
        </ProjectsDropdown>
      </>
    );
  };

  const getAlikeAttributes = (attribute) => <span className={styles.greyText}>{attribute}</span>;

  const getFlag = (avatar) => {
    return (
      <>
        {avatar && (
          <ReactTooltip
            placement="top"
            overlay={
              <Tooltip
                title="Flagged candidate"
                content="Candidate has either been added to a pipeline
                          before, or a note or attachment has been left on candidate."
              />
            }
          >
            <Button inline square size={'small'} className={'centerAlignButton'}>
              <span className={classNames(tableStyles.alert, tableStyles.small, tableStyles.green)}>
                <FlagIcon size={15} />
              </span>
            </Button>
          </ReactTooltip>
        )}
      </>
    );
  };

  const urlParser = (data) => {
    let url = '/rest/users/search?';
    for (let key in data) {
      url = url + '&' + key + '=';
      url = url + data[key];
    }
    return url;
  };

  const onSaveSearches = (values) => {
    let saveSearches = {
      company_role_id: values.company_role_id,
      search_url: urlParser(getParams()),
      title: values.title,
    };
    isEmpty(getParams())
      ? toast.error('First make a search to save it!')
      : createSaveSearch({ body: saveSearches });
  };

  const onLoad = (search) => {
    setSeletedSearch(search.id);
    form.reset({ keyword: search?.search_url?.split('keyword=')[1] });
    // form.reset({'keyword'})
  };

  const onDelete = (search) => {
    deleteSavedSearch({ body: { id: search.id } });
  };

  return (
    <>
      <FormProvider {...form}>
        <>
          <div className={classNames(styles.searchWrapper, styles.page)}>
            <Form form={form} onSubmit={form.handleSubmit(onSubmit)} initialValues={initialValues}>
              <div className={styles.searchBox}>
                <div className={styles.header}>
                  <RadarIcon size={50} />
                  <Heading size="large" className={styles.filtersHeading}>
                    {'Find candidates\\n on Holedo'}
                  </Heading>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <TextInput
                      name="keyword"
                      type="text"
                      placeHolder="Keyword search"
                      icon={<SearchIcon />}
                      className={styles.messageInput}
                    />
                  </div>
                  <div className="col-lg-4">
                    <SelectInput
                      onChange={onCountryChange}
                      name="location"
                      placeholder="Country, region or city"
                      options={state.countries}
                      mode="single"
                    />
                  </div>
                  <div className="col-lg-2 mt-2">
                    <Button type="submit" disabled={isSubmitting} submit>
                      Search
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <SavedSearches
                      onSaveSearches={onSaveSearches}
                      onLoad={onLoad}
                      onDelete={onDelete}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>

          <div className="d-flex flex-column align-items-center justify-content-center my-3 mx-5">
            {usersLoading ? (
              <Spinner height={100} width={100} />
            ) : (
              <>
                {state?.parsedUsersData && (
                  <JobApplicantsTable
                    isSeacrh={true}
                    className={'w-100'}
                    data={state?.parsedUsersData}
                  />
                )}
                {!state.parsedUsersData && (
                  <SearchFiltersForm
                    isOpen={currentUIState?.currentView === views.filtersForm}
                    setStates={setState}
                    isSearchSubmitted={state.isSeacrhSubmitted}
                    showMainFilter={state.showMainFilter}
                    showContent={state.showMainFilter}
                    isMainFilter
                  />
                )}

                {includes(
                  [
                    views.talentProfile,
                    views.talentEmail,
                    views.talentNotes,
                    views.talentNoteForm,
                    views.talentEmails,
                  ],
                  currentUIState?.currentView,
                ) && !isEmpty(currentUIState?.talent) ? (
                  <>
                    <ProfileCard
                      profileUrl={currentUIState?.talent?.user?.holedo_url}
                      firstHeading={currentUIState?.talent?.user?.full_name}
                      secondHeading={currentUIState?.talent?.job?.title}
                      thirdHeading={currentUIState?.talent.user?.country?.title}
                      label={currentUIState?.talent.user?.date_of_birth}
                      avatar={{
                        src: currentUIState?.talent.user?.avatar,
                        name: currentUIState?.talent.user?.full_name,
                      }}
                      slug={currentUIState?.talent?.user?.slug}
                    ></ProfileCard>

                    <SidebarTabs>
                      <NavItem
                        onClick={() => dispatch(handleCurrentView(views.talentProfile))}
                        active={currentUIState?.currentView === views.talentProfile}
                        link={''}
                      >
                        Profile
                      </NavItem>

                      <NavItem
                        onClick={() => dispatch(handleCurrentView(views.talentNotes))}
                        active={currentUIState?.currentView === views.talentNotes}
                        link={''}
                      >
                        <Labeled toCount={talent?.comments}>Notes</Labeled>
                      </NavItem>

                      <NavItem
                        onClick={() => dispatch(handleCurrentView(views.talentEmail))}
                        active={currentUIState?.currentView === views.talentEmail}
                        link={''}
                      >
                        <Labeled toCount={talent?.comments}>Emails</Labeled>
                      </NavItem>
                    </SidebarTabs>

                    {currentUIState?.currentView === views.talentProfile && (
                      <TalentProfile
                        // getTags={() => this.getAllTags(talent?.user_id)}
                        userId={currentUIState?.talent?.user_id || currentUIState?.talent?.user?.id}
                        userTags={currentUIState?.talent?.user?.tags}
                        isLoading={'this.state.isLoading'}
                        talent={currentUIState?.talent}
                        onTagAdd={'(tag) => handleAddTag(tag)'}
                        onTagRemove={'(tag) => handleRemoveTag(tag)'}
                      />
                    )}
                    {includes(
                      [views.talentNotes, views.talentNoteForm],
                      currentUIState?.currentView,
                    ) && (
                      <TalentNotes
                        talent={currentUIState?.talent}
                        userId={currentUIState?.talent?.user_id}
                        isLoading={false}
                        isNoteFormOpen={currentView === views.talentNoteForm}
                        onFormOpen={() => dispatch(handleCurrentView(views.talentNoteForm))}
                      />
                    )}
                  </>
                ) : (
                  ''
                )}
                {currentUIState?.currentView === views.contactProfile && currentUIState?.contact ? (
                  <>
                    <ProfileCard
                      firstHeading={
                        currentUIState.contact?.first_name + ' ' + currentUIState.contact?.last_name
                      }
                      secondHeading={currentUIState.contact?.Founder}
                      avatar={{
                        ...avatarOptions(
                          currentUIState.contact?.user ?? currentUIState.contact,
                          'contact',
                        ),
                      }}
                    />
                    <Section title="Contact details" headingSize="large">
                      <ContactDetails
                        phones={[
                          { type: 'Mobile', number: currentUIState.contact?.contact_number },
                        ]}
                        skype={
                          currentUIState.contact?.skype !== null
                            ? currentUIState.contact?.skype
                            : 'N/A'
                        }
                        email={
                          currentUIState.contact?.email ? currentUIState.contact?.email : 'N/A'
                        }
                        address={{
                          address_line_1: contact?.user?.address_line_1,
                          address_line_2: contact?.user?.address_line_2,
                          address_line_3: contact?.user?.address_line_3,
                          city: contact?.user?.city,
                          country: contact?.user?.country,
                        }}
                      />
                    </Section>
                  </>
                ) : (
                  ''
                )}

                {currentUIState?.currentView === views.recruiterProfile && recruiter && (
                  <>
                    <ProfileCard
                      firstHeading={'fullName(recruiter)'}
                      secondHeading={level && <div>asas</div>}
                      avatar={"{ ...avatarOptions(recruiter, 'user') }"}
                    />
                    <Section title="Recruiter details" headingSize="large">
                      <ContactDetails email={recruiter?.email} />
                    </Section>
                  </>
                )}
              </>
            )}
          </div>
        </>
      </FormProvider>
    </>
  );
};
