import { Heading } from 'components/heading/heading';
import classNames from 'classnames';
import styles from './kanbanColumn.module.scss';
import { find } from 'lodash';
import { MdMoreVert as VerticalIcon } from 'react-icons/md';
import { Button } from '@components/button/button';
import { RoundButton } from 'components/roundButton/roundButton';
import { MdDelete as DeleteIcon } from 'react-icons/md';
import { useState } from 'react';
import { ProjectBinPipelineContainer } from 'containers/projectBinPipelineContainer/ProjectBinPipelineContainer';

export const KanbanColumn = ({ title, id, candidateCount, actionBar, selectedCards, binnedCardCount }) => {
  const [showCandidateBin, setShowCandidateBin] = useState(false);

  if (id === 'bin') { // Check if it's the bin column
    if (!showCandidateBin) {
      return (
        <div>
          <div className="d-flex justify-content-center">
            <RoundButton
              theme={'white'}
              size={'medium'}
              onClick={() => setShowCandidateBin(true)} // Open the bin
            >
              <DeleteIcon color="#B7BEC3" />
            </RoundButton>
          </div>
          <div className="d-flex justify-content-center mt-1" style={{ color: '#B7BEC3' }}>
            <span className="mt-1">Candidate Bin </span>
            <p className="p-1 px-2 mx-2 d-inline">
              {binnedCardCount}
            </p>
          </div>
        </div>
      );
    }
    else {
      return (
        <div>
          <div className="row mt-4" style={{ background: '#FF3434' }}>
            <div className="col-lg-6 mt-2 text-light">
              CANDIDATE BIN{' '}
              <p className="mt-2 d-inline">
                {candidateCount}
              </p>
            </div>
            <div className="col-lg-6">
              <div 
                  style={{ marginTop: '-43px', padding: '14px', marginLeft: '-50px', zIndex: 10 }}>
                <RoundButton
                  theme={'red'}
                  size={'medium'}
                  onClick={() => setShowCandidateBin(false)} // Close the bin
                >
                  <DeleteIcon color="#FFFFFF" />
                </RoundButton>
              </div>
            </div>
          </div>
          <ProjectBinPipelineContainer></ProjectBinPipelineContainer>
        </div>
      )
    }
  }

  // Default rendering for regular columns
  return (
    <div className={classNames('d-flex justify-content-between', styles.kanbanColumn)}>
      <div className="titleBlock">
        <Heading size={'large'}>{title}</Heading>
        <p className={styles.countCandidates}> {candidateCount} Candidates</p>
      </div>
      <button className={styles.vertIcon}>
        <VerticalIcon />
      </button>
    </div>
  );
};
