import React from 'react';
import { map } from 'lodash';
import classNames from 'classnames';

import styles from './list.module.scss';

export const List = (props) => {
  const { className, single, children } = props;
  return (
    <div className={classNames('list', styles.list, className)}>
      {!single && (
        <>
          {map(children, (child, key) => (
            <div key={key} className={styles.listItem}>
              {child}
            </div>
          ))}
        </>
      )}
      {single && <div className={styles.listItem}>{children}</div>}
    </div>
  );
};
