import { toBeVisible } from '@testing-library/jest-dom/dist/matchers';
import React from 'react';

const RolesAndPermissionsSection = ({
  handleChange,
  value,
  dataUpdate,
  dataDelete,
  dataSubmit,
  visible,
}) => {
  const roles = [
    {
      h: 'Jobs',
      jobs: [
        {
          name: 'post_jobs',
          h5: 'Post Jobs',
          p: 'Has the ability to post jobs',
        },
        {
          name: 'create_job_templates',
          h5: 'Can save job templates',
          p: 'Has the ability to create and save job templates',
        },
        {
          name: 'edit_job_templates',
          h5: 'Can edit job templates',
          p: 'Has the ability to edit existing job templates',
          b: 'BorderNone',
        },
      ],
    },
    {
      h: 'Clients',
      jobs: [
        {
          name: 'create_clients',
          h5: 'Create clients and assign members',
          p: 'Has the ability to create new clients and assign team members',
        },
        {
          name: 'edit_clients',
          h5: 'Edit client details',
          p: 'Has the ability to edit client details',
          b: 'BorderNone',
        },
      ],
    },
    {
      h: 'Projects',
      jobs: [
        {
          name: 'create_projects',
          h5: 'Create projects and assign members',
          p: 'Has the ability to create new projects and assign team members',
        },
        {
          name: 'edit_projects',
          h5: 'Edit projects details',
          p: 'Has the ability to edit projects details',
          b: 'BorderNone',
        },
      ],
    },
    {
      h: 'Pipelines',
      jobs: [
        {
          name: 'create_pipelines',
          h5: 'Create projects pipelines',
          p: 'Has the ability to create new projects pipelines',
        },
        {
          name: 'manage_pipeline_candidates',
          h5: 'Manage candidates within projects pipelines',
          p: 'Has the ability to move candidates within a pipeline',
          b: 'BorderNone',
        },
      ],
    },
    {
      h: 'Tasks',
      jobs: [
        {
          name: 'create_tasks',
          h5: 'Create tasks',
          p: 'Has the ability to create new tasks',
        },
        {
          name: 'view_tasks',
          h5: 'Can view all members tasks',
          p: 'Has the ability to view all members tasks',
        },
        {
          name: 'assign_tasks',
          h5: 'Can assign tasks to other members',
          p: 'Has the ability to assign tasks to other members',
          b: 'BorderNone',
        },
      ],
    },
    {
      h: 'Calendars',
      jobs: [
        {
          name: 'schedule_calendar',
          h5: 'Schedule tasks to calendar',
          p: 'Has the ability to schedule tasks onto his/her calendar',
        },
        {
          name: 'view_calendars',
          h5: 'Can view other members calendars',
          p: 'Has the ability to view tasks scheduled on other members calendars',
        },
        {
          name: 'schedule_member_calendars',
          h5: 'Can schedule tasks on other members calendars',
          p: 'Has the ability to schedule tasks on other members calendars',
          b: 'BorderNone',
        },
      ],
    },
    ,
    {
      h: 'Configure settings',
      jobs: [
        {
          name: 'configure_settings',
          h5: 'Has access to configure settings',
          p: 'Has the ability to view and edit the settings configurations',
          b: 'BorderNone',
        },
      ],
    },
    ,
    {
      h: 'Billing details',
      jobs: [
        {
          name: 'manage_billing',
          h5: 'Can access and edit billing settings',
          p: 'Has the ability to access and edit the overall billing settings for the account',
          b: 'BorderNone',
        },
      ],
    },
  ];
  return (
    <div>
      <div className="rolesSection">
        <div className="search">
          <input
            type={'text'}
            name="title"
            value={value.title}
            onChange={handleChange}
            placeholder="Role Title E.g. Project Manager"
          />
        </div>
        {roles.map((x) => (
          <div className="third">
            <h4>{x.h}</h4>
            {x.jobs.map((y) => (
              <div className={`second ${y.b}`}>
                <div className={'forth'}>
                  <h5>{y.h5}</h5>
                  <p>{y.p}</p>
                </div>
                <label className="switch">
                  <input
                    type="checkbox"
                    name={y.name}
                    checked={value[y.name]}
                    onChange={handleChange}
                  />
                  <span className="slider round">
                    {value[y.name] ? (
                      <span className="yes">YES</span>
                    ) : (
                      <span className="no">NO</span>
                    )}
                  </span>
                </label>
              </div>
            ))}
          </div>
        ))}
        {visible.openAdd == true && visible.type == 'new' ? (
          <div className="button btnWidth btnColor" onClick={dataSubmit}>
            Add role
          </div>
        ) : (
          <>
            <div className="button btnWidth btnColor" onClick={dataUpdate}>
              Save role
            </div>
            <div style={{ fontSize: '15px' }} className="button btnWidth" onClick={dataDelete}>
              Delete role
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RolesAndPermissionsSection;
