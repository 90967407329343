import React from 'react';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';

import { MdClose as CloseIcon } from 'react-icons/md';
import styles from './sidebar.module.scss';

export const Sidebar = ({ isOpen, onClose, children, ...rest }) => (
  <div className={classNames('sidebar', styles.sideBar, { [styles.opened]: isOpen })} {...rest}>
    {onClose && (
      <span className={styles.sideBarClose} onClick={onClose}>
        <CloseIcon size={20} />
      </span>
    )}
    {children}
  </div>
);
