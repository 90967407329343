import React from 'react';
import { apiUrls } from '@constants/apiurls';
// import recruiters from '@redux/modules/recruiters';
import styles from '@components/dashboard/dashboard.module.scss';
import { routesPath } from '@constants/routesPath';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ClientHeader } from '@components/clientHeader/clientHeader';
import clients from '@redux/modules/clients';
import { Spinner } from 'components/loader/loader';

export const RecruitersContainer = () => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div className={styles.dashboardWrappper}>
      <ClientHeader recruiter={{ title: 'Recruiters', list: ['a', 'b', 'c', 'd', 'e', 'f'] }} />
      <Outlet />
    </div>
  );
};
