import React from 'react';

import styles from './divider.module.scss';
import { Heading } from '../heading/heading';

export const Divider = ({ size, className, children, ...rest }) => (
  <div className={`divider ${styles.dividerWrapper} ${size && styles.size} ${className}`} {...rest}>
    <Heading className={styles.heading} size="xsmall">
      {children}
    </Heading>
  </div>
);

Divider.defaultProps = {
  size: 'default',
};
