import React, { useState } from 'react';
import { BsFillFileEarmarkTextFill as FileTextIcon } from 'react-icons/bs';
import { AiFillFlag as FlagIcon } from 'react-icons/ai';
import { MdAdd as AddIcon } from 'react-icons/md';
import { EmptyCard } from 'components/emptyCard/emptyCard';

import { FaEllipsisH as EllipsisIcon } from 'react-icons/fa';

import classNames from 'classnames';
import { size } from 'lodash';
import ReactTooltip from 'rc-tooltip';

import { Tooltip } from '@components/tooltip/tooltip';
import { Heading } from '@components/heading/heading';
import { Button } from '@components/button/button';

import styles from './jobApplicantsListing.module.scss';
import { Avatar } from '../avatar/avatar';
import { OverlayContainer } from '../../containers/overlayContainer/OverlayContainer';
import { views } from '../../constants/views';
import { ProjectsDropdown } from '../projectsDropdown/projectsDropdown';

export const JobApplicantsListing = (props) => {
  const {
    applicant,
    openOverlay,
    coverLetter,
    job,
    clientProjectsOptions,
    create,
    openTalentSidebar,
    emptyMessage,
    handleCoverLetterOpening,
    coverLetterOpen,
    projects,
  } = props;
  const avatarSize = 45;

  const [isCoverLetter, setIsCoverLetter] = useState(false);

  return (
    <div className={classNames(styles.col_1_3, '')}>
      <div className={classNames(styles.compCardContainer, styles.smallMarginsGrid, styles.card)}>
        {emptyMessage && (
          <EmptyCard
            title="There were no results for your search"
            description="Tweak your search criteria for better results."
          />
        )}
        {!emptyMessage && (
          <>
            <div className={classNames(styles.compCardContent, 'pb-0 mb-0')}>
              <div className={classNames(styles.avatarLink, 'd-flex', 'align-items-center')}>
                <Avatar
                  src={applicant?.avatar}
                  className={classNames(styles.avatar, styles.img_avatar)}
                  size={avatarSize}
                  name={applicant?.full_name}
                  onClick={openTalentSidebar}
                />

                <div onClick={openTalentSidebar} className="headingSecondHeadingFlag ms-3">
                  <div className="headingFlag d-flex">
                    <Heading size="medium" className={styles.firstHeading}>
                      {applicant?.full_name}
                    </Heading>
                    <div className={classNames(styles.flagLinkContainer)}>
                      {applicant?.notes?.length && (
                        <ReactTooltip
                          placement="top"
                          overlay={
                            <Tooltip
                              title="Flagged candidate"
                              content="Candidate has either been added to a pipeline
                                    before, or a note or attachment has been left on candidate."
                            />
                          }
                        >
                          <span className={classNames(styles.alert, styles.small, styles.green)}>
                            <FlagIcon size={14} />
                          </span>
                        </ReactTooltip>
                      )}
                      {coverLetterOpen && isCoverLetter && (
                        <OverlayContainer
                          closeOverlay={(e) => {
                            e.stopPropagation();
                            handleCoverLetterOpening();
                            setIsCoverLetter(false);
                          }}
                          currentView={coverLetterOpen && views.coverLetter}
                          talentCoverLetter={coverLetter}
                          talentName={applicant?.full_name}
                          talentJobTitle={applicant.secondHeading}
                          talentAvatar={applicant.avatar}
                        />
                      )}

                      {coverLetter && (
                        <a
                          className={classNames(
                            styles.alert,
                            styles.small,
                            styles.blue,
                            styles.cursor_pointer,
                          )}
                        >
                          <FileTextIcon
                            size={13}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCoverLetterOpening();
                              setIsCoverLetter(true);
                            }}
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <ProjectsDropdown
                  projectsData={projects}
                  applicant={applicant}
                  // projects={clientProjectsOptions}
                  // onClick={(project, column) => {
                  //   create('cards', {
                  //     applicant: applicant.url,
                  //     pipeline: project.pipeline.url,
                  //     column,
                  //     before: null,
                  //   });
                  // }}
                  zIndex={12}
                  inline
                >
                  <div className="d-flex  justify-content-center ms-3 ">
                    <Button
                      onClick={(e) => e.stopPropagation()}
                      inline
                      square
                      submit
                      size={'small'}
                    >
                      <center>
                        <AddIcon size={15} />
                      </center>
                    </Button>
                  </div>
                </ProjectsDropdown>
                <Button
                  // onClick={openapplicantSidebar}
                  inline
                  square
                  white
                  size={'small'}
                >
                  <center>
                    <EllipsisIcon className="d-flex ms-2 mt-3  align-content-center" size={15} />
                  </center>
                </Button>
              </div>
            </div>
            <p
              style={{ lineHeight: '0', fontSize: '12px' }}
              className={classNames(styles.secondHeading, 'm-0 d-block ms-5 ps-5 mb-3')}
            >
              {applicant.current_experience
                ? applicant?.current_experience.title +
                  ', ' +
                  applicant?.current_experience.company_name +
                  ', ' +
                  applicant?.current_experience.area
                : ''}
              &nbsp;
            </p>
          </>
        )}
      </div>
    </div>
  );
};
