import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { map, isEmpty } from 'lodash';
import { SelectInput } from '../form/selectInput';
import { TextInput } from '../form/textInput';
import { JobClientForm } from '../jobClientForm/jobClientForm';
import { fetchAutoCompleteData } from '@services/autoCompleteService';
import { apiUrls } from '@constants/apiurls';
import styles from '../jobForm/jobForm.module.scss';

export const JobFormCompanyDetails = (props) => {
  const { clients, clientValue, showClientForm,
    isFetchingClients } = props;

  return (
    <div className={classNames(styles.compCardContainer)}>
      <div className={classNames(styles.compCardTitleContainer)}>
        <span className={classNames(styles.dividerTextLarge)}>COMPANY DETAILS</span>
      </div>
      <div className={classNames(styles.compCardContent, 'd-block')}>
        <span className={classNames(styles.inputTitle)}>
          Who is this job post for?*
          <div className={classNames(styles.textSmall)}>{'Select a client!'}</div>
        </span>
        {isFetchingClients ? (
          <p>Loading...</p>
        ) : (
          <div className={classNames(styles.fieldWrapper)}>
            <SelectInput options={clients} name="client_id" />
          </div>
        )}
      </div>

      <JobClientForm show={!isEmpty(clientValue) || showClientForm} />
    </div>
  );
};
