import React from 'react';
import classNames from 'classnames';

import styles from './sidebar.module.scss';

export const SidebarWrapper = ({ isOpen, children, className, ...rest }) => (
  <div
    className={classNames(styles.sidebarPageWrapper, { [styles.opened]: isOpen }, className)}
    {...rest}
  >
    {children}
  </div>
);
