import React from 'react';
import classNames from 'classnames';

import styles from '../formColumns/forms.module.scss';

export const Form = (props) => {
  const { children, actions, className, onSubmit } = props;

  return (
    <form onSubmit={onSubmit} className={classNames('form', styles.form, className)}>
      <div className={classNames(styles.formInnerWrapper, 'form-inner-wrapper')}>{children}</div>
      {actions && (
        <div className={classNames(styles.formActionsWrapper, 'form-actions-wrapper')}>
          {actions.map((a, i) => ({ ...a, key: i }))}
        </div>
      )}
    </form>
  );
};
