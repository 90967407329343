import React, { useState } from 'react';

import { NoteForm } from 'components/noteForm/noteForm';
import notes from '../../redux/modules/notes';
import { yupResolver } from '@hookform/resolvers/yup';
import yupSchemaService from '../../services/yupSchemaService';
import { FormProvider, useForm } from 'react-hook-form';
import { views } from '../../constants/views';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

export const NoteFormContainer = (props) => {
  const {
    submitForm,
    setTalentNoteFormFieldValue,
    upload,
    location,
    userId,
    getNotes,
    requireConfirmation,
    initialValue,
    setInitialValue,
    ...formOptions
  } = props;

  const [state, setState] = useState({ open: false, file: null });

  const [initialValues, setInitialValues] = useState(() => {
    return initialValue;
  });

  useEffect(() => {
    if (initialValue) {
      setState((prevState) => ({
        ...prevState,
        open: true,
      }));
    }
  }, [initialValue]);

  useEffect(() => {
    if (!state?.open) {
      setInitialValue(null);
    }
  }, [state.open]);

  const form = useForm({
    resolver: yupResolver(yupSchemaService.applicantFormNotes()),
    mode: 'all',
    defaultValues: initialValues,
    // shouldUnregister: true,
  });

  const [createNote, noteCreateData] = notes.form().useNotecreateMutation();
  const [updateNote, updateNoteData] = notes.form().useNoteupdateMutation();

  const handleSubmitForm = (values) => {
    if (!initialValue) {
      if (state?.file) {
        values.attachment = JSON.stringify(state?.file);
      }
      values.user_id = userId;
      createNote({ body: values });
    } else {
      updateNote(values);
    }
  };

  useEffect(() => {
    if (initialValue) {
      form.reset(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (updateNoteData?.isSuccess) {
      toast.success('Note updated successfully!');
      setState((prevState) => ({
        ...prevState,
        open: false,
      }));
    }
    if (updateNoteData?.isError) {
      toast.error('Error updating note!');
    }
  }, [updateNoteData]);

  useEffect(() => {
    if (noteCreateData) {
      if (noteCreateData?.isSuccess) {
        toast.success('New note added to profile');
        setState((prevState) => ({
          ...prevState,
          open: false,
        }));
        setInitialValues(false);
      }
      if (noteCreateData?.error) {
        toast.error('Failed to add note');
      }
    }
  }, [noteCreateData]);

  return (
    <FormProvider {...form}>
      <NoteForm
        initialValues={initialValues}
        initialValue={initialValue}
        userId={userId}
        notes={getNotes}
        onSubmit={submitForm}
        setTalentNoteFormFieldValue={setTalentNoteFormFieldValue}
        handleSubmitForm={handleSubmitForm}
        state={state}
        setState={setState}
        form={form}
        file={state.file}
        onCancel={() => {
          setState((prevState) => ({
            ...prevState,
            open: prevState?.open,
          }));
          setInitialValue(null);
        }}
        {...formOptions}
      />
    </FormProvider>
  );
};
