import React from 'react';
import classNames from 'classnames';
import { filter, isEmpty } from 'lodash';

import { FaEllipsisH as EllipsisIcon } from 'react-icons/fa';
import { MenuItem } from '../menuItem/menuItem';
import { Dropdown } from '../dropDown/dropDown';

import styles from './jobIndexCardMenu.module.scss';

export const JobIndexCardMenu = (props) => {
  const { className, toRight, clickHandlers, jobsFilter, jobStatuses } = props;

  // find current filter key
  // let currentFilter = !isEmpty(jobStatuses) && filter(jobStatuses, { url: jobsFilter })[0];
  let currentFilter = {};
  currentFilter.key = 'active';

  let visibleMenuItems = [];
  switch (currentFilter.key) {
    case 'active': {
      visibleMenuItems = [
        <MenuItem key={'deleted'} onClick={clickHandlers.deletedHandler}>
          Delete
        </MenuItem>,
        // <MenuItem key={'archived'} onClick={clickHandlers.archivedHandler}>
        //   Archive
        // </MenuItem>,
        // <MenuItem key={'duplicate'} onClick={clickHandlers.duplicateHandler}>
        //   Duplicate
        // </MenuItem>,
      ];
      break;
    }
    case 'archived': {
      visibleMenuItems = [
        <MenuItem key={'active'} onClick={clickHandlers.activatedHandler}>
          Activate
        </MenuItem>,
        <MenuItem key={'deleted'} onClick={clickHandlers.deletedHandler}>
          Delete
        </MenuItem>,
      ];
      break;
    }
    case 'expired':
    case 'draft': {
      visibleMenuItems = [
        <MenuItem key={'active'} onClick={clickHandlers.activatedHandler}>
          Activate
        </MenuItem>,
        <MenuItem key={'archived'} onClick={clickHandlers.archivedHandler}>
          Archive
        </MenuItem>,
        <MenuItem key={'deleted'} onClick={clickHandlers.deletedHandler}>
          Delete
        </MenuItem>,
      ];
      break;
    }
    default:
      visibleMenuItems = [];
      break;
  }

  return (
    <div className={classNames('card-menu', styles.menuButton, className)}>
      <Dropdown trigger={<EllipsisIcon size={20} />} zIndex={12} toRight={toRight}>
        {visibleMenuItems}
      </Dropdown>
    </div>
  );
};
