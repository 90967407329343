import React from 'react';

import styles from '../formColumns/forms.module.scss';

export const Button = (props) => {
  const {
    inline,
    regular,
    white,
    blue,
    square,
    type,
    size,
    submit,
    cancel,
    destroy,
    align,
    nav,
    children,
    className,
    important,
    secondary,
    settingsMenu,
    ...rest
  } = props;
  return (
    <span
      style={{ float: align ? align : 'none' }}
      className={`form-elements ${styles.buttonWrapper} ${inline ? styles.inline : ''} ${
        className ? styles[className] : ''
      }`}
    >
      <button
        {...rest}
        type={type}
        className={`form-element ${styles.button}
        ${submit ? styles.submit : ''}
        ${size ? styles[size] : ''}
        ${secondary ? styles.secondary : ''}
        ${important ? styles.important : ''}
        ${regular ? styles.regular : ''}
        ${white ? styles.white : ' '}
        ${blue ? styles.blue : ''}
        ${square ? styles.square : ''}
        ${cancel ? styles.cancel : ''}
        ${nav ? styles.nav : ''}
        ${destroy ? styles.red : ''}
        ${settingsMenu ? styles.settingsMenu : ''}
        `}
      >
        {children}
      </button>
    </span>
  );
};

Button.defaultProps = {
  className: 'form-submit-button',
  type: 'button',
  important: false,
};
