import { NavContainer } from '../containers/nav/NavContainer';
import styles from './CoreLayout.scss';

export const CoreLayout = ({ children }) => {
  return (
    <div className={styles.container}>
      <NavContainer />

      {children}
    </div>
  );
};
