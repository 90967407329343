import classNames from 'classnames';
import { MdPerson as UserIcon } from 'react-icons/md';

import styles from './inviteRecruiter.module.scss';
import Avatar from 'react-avatar';

const HospitalityIcon = () => (
  <div className={styles.hospitalityIcon}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 61.54">
      <path
        fill="#ffffff"
        d="M75,31.15A101.57,101.57,0,0,0,68.4,42.62c-4.83.25-13.4.84-22.37,
        2.13C51.5,35.29,56.6,28.44,63.89,24.48c11-6-36.12-11.66-50.28,12.44-2.36,
        4,0,13,9.89,10.27,2.81-.77,5-3.89,1-5.36-5.3-2,4.21-18.57,32.35-18.63,0,0-11,
        5.2-21.18,23.37A85.71,85.71,0,0,0,26,49.19c-4.63,1.58-6.18,2.75-5.92,4.57s3.27,
        1.29,12-.85c-7.46,12.31-12.47,15.4-17.24,15.4-5.42,0-6.42-3.45-6.42-6S8.21,59,
        6.74,59s-10,5.14-5.37,15.42,24.49,7.44,35.55-13c2.09-3.87,4-7.48,5.83-10.83,
        5.81-.91,12.44-1.33,22.53-1.28C62,56.88,59.47,63.7,56.48,68.63c-2.62-2.48-6.37-5-10.71-4.73-5.67.31-8.51,
        4.62-8.28,8.79.25,4.49,4.46,8.38,10.14,8.07a16.26,16.26,0,0,0,9.25-3.25,18,18,0,0,0,
        1.62-1.25c9.92,5.29,26.37,4.45,33.06-6.52,2.7-4.43,3.07-13.95-3.88-13.95-3.68,0-4.62,
        2.65-3.27,5.62C87,67,73.75,78.73,61.9,72.71c4.26-5.14,9.34-13.83,15.64-28.05,3-6.88,
        9.91-22.26,14.12-20.77,2.6.92-2.68,6,2,7.61,1.54.52,4.42.94,5.76-2.84C103.34,17.56,
        86.58,13.8,75,31.15ZM47.39,76a4,4,0,0,1-4.49-3.57,3.66,3.66,0,0,1,3.67-3.89c3.75-.21,
        6.5,4.18,6.5,4.18A6.54,6.54,0,0,1,47.39,76Z"
        transform="translate(0 -19.23)"
      />
    </svg>
  </div>
);

export const InviteRecruiter = ({ avatar }) => (
  <div className={classNames(styles.inviteWrapper)}>
    {avatar ? (
      <Avatar />
    ) : (
      <div className={styles.cardIcon}>
        <UserIcon size={20} />
      </div>
    )}
    <HospitalityIcon />
  </div>
);
