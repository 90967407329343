import classNames from 'classnames';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import styles from '../formColumns/forms.module.scss';

export const CheckBoxGroup = (props) => {
  const {
    options,
    type,
    name,
    label,
    description,
    error,
    disabled,
    className,
    classNameLabel,
    onChange,
    verticalDisplay,
    ...rest
  } = props;

  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const originalValue = useWatch({ name: name }) || [];

  const changeHandler = (namee, value) => {
    let find = originalValue?.find((val) => val === value);
    if (find >= 0) {
      setValue(namee, false, { shouldValidate: true });
      onChange && onChange(name);
      return;
    }
    setValue(namee, value, { shouldValidate: true });
    onChange && onChange(name, value);
  };

  return (
    <span className={classNames('form-elements', styles.inputWrapper, className)}>
      <span
        className={classNames('form-elements-wrapper', styles.inputInnerWrapper, {
          'd-flex gap-3': verticalDisplay,
        })}
      >
        {options &&
          options.map((option, i) => {
            return (
              <label
                key={`option-${option.id}`}
                className={classNames(
                  'form-element',
                  styles.inputCheckboxLabel,
                  {
                    [styles.checked]: originalValue?.find((op) => op == option?.id),
                    [styles.disabled]: disabled,
                  },
                  classNameLabel,
                )}
              >
                <input
                  type={type}
                  value={option.id}
                  {...register(name + '.' + i)}
                  key={option.id}
                  onChange={() => changeHandler(name + '.' + i, option.id)}
                />
                <strong>{option.title}</strong>
                {option?.description && (
                  <p className={styles.fieldLabelDescription}>{option.description}</p>
                )}
              </label>
            );
          })}
      </span>

      {errors[name]?.message && (
        <span className={classNames(styles.fieldError, 'form-error-element')}>
          {errors[name]?.message}
        </span>
      )}
    </span>
  );
};
