import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { isInteger, map } from 'lodash';
import { MdMore as TagsIcon } from 'react-icons/md';
import { MdVisibility as VisibleIcon } from 'react-icons/md';
import { MdOutlineVisibilityOff as InVisibleIcon } from 'react-icons/md';
import { MdDownload as DownloadIcon } from 'react-icons/md';
import { MdArrowDropDown as CaretIcon } from 'react-icons/md';
import { MdViewWeek as ColumnsIcon } from 'react-icons/md';
import { WithContext as ReactTags } from 'react-tag-input';
import { MdCompareArrows as PipelineMoveIcon } from 'react-icons/md';
import { MdEmail as EmailIcon } from 'react-icons/md';
import { MdRemove as RemoveIcon } from 'react-icons/md';
// import { MdArrowDownward as CaretIcon } from 'react-icons/md';

// import { MdDownload as DownloadIcon } from 'react-icons/md';

import { ContextMenu } from '../contextMenu/contextMenu';
import { Heading } from '../heading/heading';
import { Button } from '../button/button';
import styles from 'components/actionsBar/actionsBar.module.scss';
import { Dropdown } from '../dropDown/dropDown';
import { MenuSlot } from '../menuSlot/menuSlot';
import tags from 'redux/modules/tags';
import dataService from '../../services/dataService';
import toast from 'react-hot-toast';
import { MenuItem } from '../menuItem/menuItem';

export const KanbanTableAction = (props) => {
  const {
    className,
    count,
    tableColumns,
    isShowOnlySelectedActive,
    onShowSelected,
    setIsFiltered,
    children,
    isFiltered,
    data,
    pipelineStages,
    handleKanbanMovement,
    currentPosition,
    handleUserDelete,
    selectedRows,
    pipelineData,
  } = props;

  const [createTag, tagsCreateData] = tags.form().useTagcreateMutation();
  const [deleteTag, tagsDeleteData] = tags.delete().useTagdeleteMutation();

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const [tages, setTags] = useState([]);

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  useEffect(() => {
    if (tagsCreateData.isSuccess) {
      toast.success('Tag Added To Candidates!');
      let result = dataService.parseTags(tagsCreateData?.data);
      setTags([...tages, result]);
    }
    if (tagsCreateData.isError) {
      toast.error('Failed To Add Tags!');
    }
  }, [tagsCreateData]);

  const hanldeProjectUserUpdate = (stage) => {};

  const handleDelete = (i) => {
    deleteTag({
      body: {
        id: tages[i].tagId,
      },
    });

    setTags(tages.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    createTag({
      body: {
        name: tag.text,
        users: data.map((dat) => {
          return { id: dat.id };
        }),
      },
    });
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tages.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
  };

  return (
    <div
      style={{ position: 'relative', top: '-4px', width: '30%' }}
      className={classNames(styles.actionsBar, className)}
    >
      {isInteger(count) && (
        <div className={styles.resultsCount}>
          <Heading size="small" className={styles.resultsCountHeading}>
            {count} results
          </Heading>
        </div>
      )}

      {data?.length && (
        <div className={styles.contextMenu}>
          <ContextMenu visible>
            <Button
              onClick={() => {
                setIsFiltered((prevState) => !prevState);
                onShowSelected();
              }}
              className={{ [styles.active]: isShowOnlySelectedActive }}
            >
              {/* {!isFiltered ? <VisibleIcon size={20} /> : <InVisibleIcon size={20} />} */}

              <EmailIcon size={20} />
            </Button>

            <Dropdown
              heading="Move To"
              // disabled={!data?.length}
              trigger={
                <Button>
                  <PipelineMoveIcon size={20} />
                  <CaretIcon size={18} />
                </Button>
              }
            >
              {pipelineData?.length &&
                map(pipelineData, (stage, index) => {
                  return (
                    <MenuItem
                      key={`move-to-${stage}`}
                      onClick={() => {
                        hanldeProjectUserUpdate(stage);
                      }}

                      // disabled={currentPosition.fromPosition == index}
                      //   card, from, to, destination
                      // disabled={ !canMove({ column }, id, pipeline) }
                      // onClick={ () => canMove({ stage }, id, pipeline) && onMoveSelected(column) }
                    >
                      {stage.title}
                    </MenuItem>
                  );
                })}
            </Dropdown>
            <Button
              onClick={onShowSelected}
              className={{ [styles.active]: isShowOnlySelectedActive }}
            >
              {!isFiltered ? (
                <div>
                  <DownloadIcon size={20} />
                  <span style={{ color: '#B1B7BC' }}>CV</span>
                </div>
              ) : (
                <>
                  <DownloadIcon size={20} />
                  <span style={{ color: '#B1B7BC' }}>CV</span>
                </>
              )}
            </Button>

            <Button
              onClick={() => handleUserDelete(currentPosition)}
              className={{ [styles.active]: isShowOnlySelectedActive }}
            >
              <RemoveIcon size={20} />
            </Button>
          </ContextMenu>
        </div>
      )}

      <div className={styles.pushRight}>{children}</div>
    </div>
  );
};
