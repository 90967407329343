import { Provider } from 'react-redux';
import store from './redux/store';
import { Router } from './router';
import { BrowserRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <DndProvider backend={HTML5Backend}>
            <Router />
          </DndProvider>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
