import React from 'react';

import { Header } from 'components/header/header';
import { NavItem } from 'components/nav/navItem';
import { Labeled } from 'components/labeled/labeled';
import { JobLabel } from 'components/jobLabel/jobLabel';

import classNames from 'classnames';
import styles from './jobHeader.module.scss';
import { routesPath } from '../../constants/routesPath';
import { wizardViews } from '../../constants/wizardViews';
import { WizardSteps } from '../wizardSteps/wizardSteps';
import { Router, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const JobHeader = ({
  newEntry,
  children,
  onCancel,
  customButtons,
  job,
  colors,
  jobId,
  client,
  clientLogo,
  titleLeft = 'New Job Post',
  active,
  location,
  locationOptions,
}) => {
  const params = useParams();
  const activeWizardStep = useSelector((state) => state.commonReducer.wizardCurrentView.step);
  const navigate = useNavigate();
  

  return (
    <>
      {newEntry && (
        <Header
          titleLeft={titleLeft}
          newEntry={newEntry}
          onCancelButton={() => navigate('/jobs', { replace: false })}
          customButtons={customButtons}
        >
          <>
            <WizardSteps
              activeStep={activeWizardStep}
              data={wizardViews.job(job?.id || params?.jobId)}
              from={'job'}
            />
            {children}
          </>
        </Header>
      )}
      {job && (
        <Header
          doubleAvatar={
            <div className={classNames(styles.doubleAvatarWrapper)}>
              <JobLabel
                colors={colors}
                job={job}
                client={client}
                clientLogo={clientLogo}
                showClient={false}
                classIndicator="jobHeaderIndicator"
              />
            </div>
          }
          // link={routes.editJob(jobId)}
          newEntry={'newEntry'}
          customButtons={customButtons}
        >
          <NavItem
            link={routesPath?.jobEdit(params?.jobId)}
            active={location?.pathname === routesPath.jobEdit(params?.jobId)}
          >
            <Labeled>Edit post</Labeled>
            <div style={{ position: 'absolute', left: '-1100%', top: '42%' }}>
              <WizardSteps
                activeStep={activeWizardStep}
                data={wizardViews.job(job?.id || params?.jobId)}
                from={'job'}
              />
            </div>
          </NavItem>
          <NavItem
            link={routesPath?.jobApplicants(params?.jobId)}
            active={location?.pathname === routesPath.jobApplicants(params?.jobId)}
          >
            <Labeled toCount={job?.job_applications?.length}>Applicants</Labeled>
          </NavItem>
          {/* <NavItem link={ routes.jobMatches(jobId) } active={ active === locationOptions.locationMatches }>
              <Labeled toCount={ 0 }>Matches</Labeled>
            </NavItem> */}

          {children}
        </Header>
      )}
    </>
  );
};
