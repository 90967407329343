import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';

import { List } from 'components/list/list';
import { MdEmail as EmailIcon } from 'react-icons/md';
import { MdPhoneIphone as MobilePhoneIcon } from 'react-icons/md';
import { MdLocalPhone as HomePhoneIcon } from 'react-icons/md';
import { MdPrint as WorkPhoneIcon } from 'react-icons/md';
import { MdPlace as PlaceIcon } from 'react-icons/md';
import { FaFacebookSquare as FacebookIcon } from 'react-icons/fa';
import { FaTwitterSquare as TwitterIcon } from 'react-icons/fa';
import { FaSkype as SkypeIcon } from 'react-icons/fa';
import { FaGooglePlus as GoogleIcon } from 'react-icons/fa';
import { FaInstagram as InstagramIcon } from 'react-icons/fa';
import { FaLinkedin as LinkedInIcon } from 'react-icons/fa';

import { map, omitBy, isNull, isUndefined, isEmpty } from 'lodash';

import styles from './talent.module.scss';

export const ContactDetails = ({ address, email, skype, phones, socials }) => {
  const cleanAddress = omitBy(address, (v) => isNull(v) || isEmpty(v) || isUndefined(v));

  return (
    <div className={styles.talentContact}>
      {!isEmpty(socials) && (
        <List>
          {map(socials, (profile, k) => (
            <div key={k} className={styles.socialProfile}>
              <div className={styles.profileIcon}>
                {profile.type === 'Facebook' && <FacebookIcon size={20} />}
                {profile.type === 'Twitter' && <TwitterIcon size={20} />}
                {/* {profile.type === 'Google' && <GoogleIcon size={20} />} */}
                {profile.type === 'Instagram' && <InstagramIcon size={20} />}
                {profile.type === 'LinkedIn' && <LinkedInIcon size={20} />}
              </div>
              <a target="_blank" href={profile.profile_link}>
                {profile.type}
              </a>
            </div>
          ))}
        </List>
      )}
      {email && (
        <div className={styles.singleItem}>
          <div className={styles.addressBlock}>
            <div className={styles.profileIcon}>
              <EmailIcon size={20} />
            </div>
            <div className={styles.addressBlockData}>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          </div>
        </div>
      )}
      {skype && (
        <div className={styles.singleItem}>
          <div className={styles.addressBlock}>
            <div className={styles.profileIcon}>
              <SkypeIcon size={20} />
            </div>
            <div className={styles.addressBlockData}>
              <a href={`skype:${skype}`}>{skype}</a>
            </div>
          </div>
        </div>
      )}
      {!isEmpty(phones) && (
        <List>
          {map(phones, (phone, i) => (
            <div key={i} className={styles.socialProfile}>
              <div className={styles.profileIcon}>
                {phone.type === 'Home' && <HomePhoneIcon size={20} />}
                {phone.type === 'Work' && <WorkPhoneIcon size={20} />}
                {phone.type === 'Mobile' && <MobilePhoneIcon size={20} />}
              </div>
              <a href={'tel:' + phone.number}>{phone.number}</a>
            </div>
          ))}
        </List>
      )}

      {!isEmpty(cleanAddress) && (
        <div className={classNames(styles.addressBlock, styles.singleItem)}>
          <div className={styles.profileIcon}>
            <PlaceIcon size={20} />
          </div>
          <div className={styles.addressBlockData}>
            {cleanAddress?.country?.address_line_1 && (
              <p>{cleanAddress?.country?.address_line_1}</p>
            )}
            {cleanAddress?.country?.address_line_2 && (
              <p>{cleanAddress?.country?.address_line_2}</p>
            )}
            {cleanAddress.address_line_3 && <p>{cleanAddress.address_line_3}</p>}
            {cleanAddress?.country?.address_province && (
              <p>
                {cleanAddress?.country?.address_province}, {cleanAddress?.country?.address_zip_code}
              </p>
            )}
            {cleanAddress?.country?.capital && <p>{cleanAddress?.country?.capital}</p>}
            {cleanAddress.country && <p>{cleanAddress?.country?.title}</p>}
          </div>
        </div>
      )}
    </div>
  );
};
