import React from 'react';
import classNames from 'classnames';

import { Heading } from 'components/heading/heading';

import styles from './tooltip.module.scss';

export const Tooltip = ({ title, content, className, children }) => (
  <span className={classNames('tooltipeer', styles.tooltipWrapper, className)}>
    {children ? (
      children
    ) : (
      <>
        <Heading size="small" className={styles.title}>
          {title}
        </Heading>
        <p className={styles.content}>{content}</p>
      </>
    )}
  </span>
);
