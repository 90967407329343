import React from 'react';
import classNames from 'classnames';

import { AiFillCaretDown as CaretIcon } from 'react-icons/ai';

import styles from '../nav/nav.module.scss';

export const DropdownTrigger = ({ children, noCaret, className }) => (
  <div className={classNames(styles.dropdownTrigger, { [styles.noCaret]: noCaret }, className)}>
    <div>{children}</div>
    {!noCaret && <CaretIcon size={10} />}
  </div>
);

DropdownTrigger.defaultProps = {
  noCaret: false,
};
