import { isEmpty, map, size } from 'lodash';
import React from 'react';
import { Button } from 'components/button/button';
import { EmptyCard } from 'components/emptyCard/emptyCard';
import PageListSection from 'components/pageListSection/pageListSection';
import { ProjectCard } from 'components/projectCard/projectCard';
import styles from 'components/dashboard/dashboard.module.scss';
import { useNavigate } from 'react-router-dom';
import { routesPath } from '@constants/routesPath';
import classnames from 'classnames';

export const ProjectsListing = (props) => {
  const {
    projects,
    clients,
    visibleProjects,
    visibleClients,
    projectsFilter,
    clientsFilter,
    onProjectSearch,
    onProjectAdd,
    onProjectRemove,
    onProjectEdit,
    onClientSearch,
    onClientAdd,
    onClientRemove,
    onClientEdit,
    searchedProjectsData,
  } = props;
  const navigate = useNavigate();
  return (
    <div className={styles.dashboardWrappper}>
      <PageListSection
        title="Projects"
        onSearch={onProjectSearch}
        searchQuery={projectsFilter}
        onAdd={onProjectAdd}
        searchPlaceholder="Search project"
      >
        {isEmpty(projects) ? (
          <EmptyCard
            isCard
            title="Things are looking a little\nbare here, recruiter."
            description="You haven’t created any projects yet. Get started by looking at the example
          project alongside or create your first project now."
          >
            <Button submit onClick={onProjectAdd} inline>
              New project
            </Button>
          </EmptyCard>
        ) : (
          <div
            className={classnames(styles.pageSectionContainer, 'flex-wrap')}
            style={{ width: `${size(projects) * (364 + 20) - 20}px` }}
          >
            {map(projects, (project) => {
              return (
                <ProjectCard
                  key={`project-${project.id}`}
                  project={project}
                  onRemove={onProjectRemove}
                  onEdit={onProjectEdit}
                  client={clients}
                  onClick={() => {
                    navigate(routesPath.projectPipeline(project?.id, project?.pipeline_id), {
                      replace: false,
                    });
                  }}
                />
              );
            })}
          </div>
        )}
      </PageListSection>
    </div>
  );
};
