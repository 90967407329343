import React from 'react';
import { map } from 'lodash';

import { List } from 'components/list/list';
import { Heading } from 'components/heading/heading';

import styles from './talent.module.scss';

export const TalentEducation = ({ education }) => (
  <div className={styles.jobWrapper}>
    <List>
      {map(education, (item, i) => (
        <div key={`education-${i}`} className={styles.jobSection}>
          <div className={styles.jobYear}>{item.end_year}</div>
          <div className={styles.jobData}>
            {item.institution && (
              <Heading size="small" className={styles.mainHeading}>
                {item.institution}
              </Heading>
            )}
            {item.course_description && (
              <p className={styles.subHeading}>{item.course_description}</p>
            )}
            {item.qualification && <p className={styles.subHeading}>{item.qualification}</p>}
            <p className={styles.noteHeading}>
              {item.start_year} - {item.end_year}
            </p>
          </div>
        </div>
      ))}
    </List>
  </div>
);
