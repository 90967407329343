import classNames from 'classnames';
// import moment from 'moment'
import styles from './jobIndexStats.module.scss';
import dayjs from 'dayjs';

export const JobIndexStats = (props) => {
  const { views, applied, postedOn, expiresOn, job, matches, onClick } = props;
  return (
    <>
      <div
        className={classNames(styles.statsWrapper)}
        onClick={(e) => {
          // if (e.target != e.currentTarget) return;
          onClick();
        }}
      >
        <div className={styles.jobGrayStat}>
          <strong>{views}</strong>
          <div className="jobBlueStatLabel">Views </div>
        </div>
        {/*
          <span>
            &nbsp;&nbsp;&#xb7;&nbsp;&nbsp;
            <AdvancedLink to={ routes.jobApplicants(job.id) } >
              <strong>
                { applied }
              </strong>
              &nbsp;
              Applied
              </AdvancedLink>
              </span>
            */}
        {applied && (
          <div className={styles.jobBlueStat}>
            {applied?.length}
            <div className="jobBlueStatLabel">Applied</div>
          </div>
        )}
        {matches && (
          <div className={styles.jobBlueStat}>
            {matches}
            <div className="jobBlueStatLabel">Matches </div>
          </div>
        )}
        {postedOn &&
        !dayjs().isSame(dayjs(expiresOn)) &&
        !dayjs().isAfter(dayjs(expiresOn)) &&
        !dayjs().isBefore(dayjs(expiresOn).add(1, 'day')) ? (
          <div>Posted {dayjs(postedOn).format('D-MMM-YYYY')}</div>
        ) : (
          ''
        )}
        {dayjs().isBefore(dayjs(expiresOn).add(1, 'day')) && (
          <div className={classNames(styles.redExpiry)}>Expires Tomorrow</div>
        )}
        {dayjs().isSame(dayjs(expiresOn)) && (
          <div className={classNames(styles.redExpiry)}>Expires Today</div>
        )}
        {dayjs().isAfter(dayjs(expiresOn)) && (
          <div className={classNames(styles.redExpiry)}>Expired</div>
        )}
      </div>
    </>
  );
};
