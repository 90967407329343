import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './nav.module.scss';

export const NavSegment = ({ children, className, placement }) => {
  const dispatch = useDispatch();
  const navToggle = useSelector((state) => state.commonReducer.navBarToggle);
  return (
    <div
      className={`${navToggle && styles.mobileNavActive} ${styles.navBarSegment} ${
        placement && styles[placement]
      } ${className ? className : ''}`}
    >
      {children}
    </div>
  );
};
