import React, { useState } from 'react';
import classNames from 'classnames';
import { map, isEmpty, filter } from 'lodash';
import { Button } from 'components/button/button';
import { MdAdd as AddIcon } from 'react-icons/md';
import { FaEllipsisH as EllipsisIcon } from 'react-icons/fa';
import { AiFillFlag as FlagIcon } from 'react-icons/ai';
import { BsFillFileEarmarkTextFill as FileTextIcon } from 'react-icons/bs';

import { ApplicantCard } from 'components/applicantCard/applicantCard';
import ReactTooltip from 'rc-tooltip';
import { useDispatch, useSelector } from 'react-redux';

import { Tooltip } from 'components/tooltip/tooltip';

import styles from '@components/jobIndexCard/JobForm.module.scss';
import tableStyles from 'components/jobApplicantsTable/jobApplicantsTable.module.scss';
import { JobApplicantsTable } from '@components/jobApplicantsTable/jobApplicantsTable';
import { JobApplicantsListing } from '@components/jobApplicantsListing/jobApplicantsListing';
import { useEffect } from 'react';
import dataService from '../../services/dataService';
import { Avatar } from '../avatar/avatar';
import { OverlayContainer } from '../../containers/overlayContainer/OverlayContainer';
import { views } from '../../constants/views';
import { handleViewsData } from '../../redux/common/commonSlice';
import projects from '../../redux/modules/projects';
import { ProjectsDropdown } from '../projectsDropdown/projectsDropdown';

export const JobApplicantsSearchResults = (props) => {
  const {
    openOverlay,
    coverLetterOpen,
    setState,
    job,
    jobId,
    results,
    applicant,
    clientProjectsOptions,
    create,
    openSidebar,
    sidebarTalentProfileView,
    applicantsViewType,
    state,
  } = props;

  const dispatch = useDispatch();
  const [parsedApplicantsTable, setParsedApplicantsTable] = useState({});

  const { data: projectsData, isLoading: projectsLoading } = projects.list().useProjectlistQuery();

  useEffect(() => {
    if (applicantsViewType === 'tableView') {
      let result = dataService.jobApplicantsTable(
        results,
        getActions,
        getCoverLetter,
        getFlag,
        getName,
        getAlikeAttributes,
      );

      setParsedApplicantsTable(result);
    }
  }, [applicantsViewType]);

  const getCoverLetter = (coverLetter, talentName, talentJobTitle, talentAvatar) => {
    let tempData = {
      talentName: talentName,
      talentJobTitle: talentJobTitle,
      talentAvatar: talentAvatar,
    };
    return (
      <div className="d-flex">
        <Button inline submit square size={'small'} className={'centerAlignButton'}>
          <FileTextIcon
            size={15}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                tableApplicantCoverData: tempData,
                coverLetterOpen: coverLetter,
              }))
            }
          />
        </Button>
      </div>
    );
  };

  const getFlag = (avatar) => {
    return (
      <>
        {avatar && (
          <ReactTooltip
            placement="top"
            overlay={
              <Tooltip
                title="Flagged candidate"
                content="Candidate has either been added to a pipeline
                          before, or a note or attachment has been left on candidate."
              />
            }
          >
            <Button inline square size={'small'} className={'centerAlignButton'}>
              <span className={classNames(tableStyles.alert, tableStyles.small, tableStyles.green)}>
                <FlagIcon size={15} />
              </span>
            </Button>
          </ReactTooltip>
        )}
      </>
    );
  };

  const getActions = (applicant) => {
    return (
      <>
        <ProjectsDropdown
          projectsData={projectsData.data}
          applicant={applicant}
          // onClick={(project, column) => {
          //   create('cards', {
          //     talent: talent.url,
          //     pipeline: project.pipeline.url,
          //     column,
          //     before: null,
          //   });
          // }}
          zIndex={12}
          inline
        >
          <Button inline square submit size={'small'}>
            <AddIcon size={15} style={{ verticalAlign: 'center' }} />
          </Button>
        </ProjectsDropdown>
        {/* <Button inline square white size={'small'} className={'centerAlignButton ms-2'}>
          <EllipsisIcon size={15} />
        </Button> */}
      </>
    );
  };

  const getName = (name, avatr, talent) => {
    return (
      <div
        onClick={() => {
          dispatch(
            handleViewsData({
              talent: talent?.result,
              currentView: views.talentProfile,
            }),
          );
        }}
        className="d-flex justify-content-start align-items-center"
      >
        <Avatar
          name={'Ahmad Waleed'}
          size={25}
          src={avatr}
          style={{
            zIndex: '2',
            display: 'inline-block',
            float: 'left',
            position: 'relative',
            marginRight: '5px',
          }}
          className={classNames('jobsListingAvatar')}
        />
        <span> {name}</span>
      </div>
    );
  };

  const getAlikeAttributes = (attribute) => <span className={styles.greyText}>{attribute}</span>;
  const currentUIState = useSelector((state) => state.commonReducer.viewsData);
  return (
    <>
      <div
        className={classNames({
          ['col-lg-6']: currentUIState?.currentView,
          ['col-lg-8']: !currentUIState?.currentView,
        })}
      >
        <div className={classNames(styles.matchCompCardContent, 'row')}>
          {!isEmpty(results) ? (
            map(results, (talent, i) => (
              <>
                {applicantsViewType === 'cardView' ? (
                  <ApplicantCard
                    projects={projectsData?.data}
                    applicant={talent.result.user}
                    key={talent.id}
                    openOverlay={openOverlay}
                    coverLetter={talent.result.cover_letter}
                    job={talent.result.job}
                    clientProjectsOptions={clientProjectsOptions}
                    create={create}
                    openTalentSidebar={() => {
                      dispatch(
                        handleViewsData({
                          talent: talent?.result,
                          currentView: views.talentProfile,
                        }),
                      );
                    }}
                    coverLetterOpen={coverLetterOpen}
                    handleCoverLetterOpening={() =>
                      setState((prevState) => ({
                        ...prevState,
                        coverLetterOpen: !prevState.coverLetterOpen,
                      }))
                    }
                  />
                ) : (
                  applicantsViewType === 'listView' && (
                    <JobApplicantsListing
                      applicant={talent.result.user}
                      key={talent.id}
                      openOverlay={openOverlay}
                      coverLetter={talent.result.cover_letter}
                      job={talent.result.job}
                      clientProjectsOptions={clientProjectsOptions}
                      projects={projectsData?.data}
                      create={create}
                      openTalentSidebar={() => {
                        dispatch(
                          handleViewsData({
                            talent: talent?.result,
                            currentView: views.talentProfile,
                          }),
                        );
                      }}
                      coverLetterOpen={coverLetterOpen}
                      handleCoverLetterOpening={() =>
                        setState((prevState) => ({
                          ...prevState,
                          coverLetterOpen: !prevState.coverLetterOpen,
                        }))
                      }
                    />
                  )
                )}
              </>
            ))
          ) : (
            <ApplicantCard emptyMessage />
          )}
          {applicantsViewType === 'tableView' && !isEmpty(parsedApplicantsTable) && (
            <>
              <JobApplicantsTable data={parsedApplicantsTable} />

              {coverLetterOpen && (
                <OverlayContainer
                  closeOverlay={() =>
                    setState((prevState) => ({
                      ...prevState,
                      coverLetterOpen: !prevState.coverLetterOpen,
                    }))
                  }
                  talentName={state.tableApplicantCoverData.talentName}
                  talentJobTitle={state.tableApplicantCoverData.talentJobTitle}
                  talentAvatar={state.tableApplicantCoverData.talentAvatar}
                  currentView={views.coverLetter}
                  talentCoverLetter={coverLetterOpen}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
