import React, { useEffect } from 'react';
// import { connect } from "react-redux";
import classNames from 'classnames';
import { includes, isEmpty } from 'lodash';

import { Sidebar } from 'components/sidebar/sidebar';
import { SidebarWrapper } from 'components/sidebar/sidebarWrapper';
import { views } from '../../constants/views';

import { ProfileCard } from 'components/profileCard/profileCard';
import { TalentProfile } from 'components/talent/talentProfile';
import { TalentNotes } from 'components/talent/talentNotes';
import { SidebarTabs } from 'components/sidebar/sidebarTabs';
import { NavItem } from 'components/nav/navItem';
import { Labeled } from 'components/labeled/labeled';
// import { Role } from 'components/role/role';
// import { Button } from 'components/button/button';
import { Section } from 'components/section/section';
// import { ProjectsDropdown } from 'components/projectsDropdown/projectsDropdown';
// import { SearchFiltersForm } from 'components/search/searchFiltersForm';
import { ContactDetails } from 'components/talent/contactDetails';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { handleCurrentView, handleViewsData } from '@redux/common/commonSlice';
import { useLocation } from 'react-router-dom';
import { SearchFiltersForm } from '../../components/searchFiltersForm/searchFiltersForm';
import { avatarOptions } from '../../components/avatar/avatar';
// import { Button } from '../../components/button/button';
import { ContactConnectCard } from '../../components/contactConnectCard/contactConnectCard';
import { useState } from 'react';
import { Button } from '../../components/button/button';
// import { Button } from 'reactstrap';
// import { DropdownTrigger } from 'components/dropDownTrigger/dropDownTrigger';
// import { PipelineCardMenu } from 'components/pipeline/PipelineCardMenu';

// import { avatarOptions } from 'components/avatar/avatar';
// import { getLocation, routes } from 'routing';
// import apiService from '../services/apiService';
// import apiUrls from '../apiUrls';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.min.css';

export const SidebarContainer = (props) => {
  const {
    currentView,
    searchQuery,
    children,
    contact,
    recruiter,
    organization,
    talent,
    projects,
    level,
    talentNotes,
    create,
    openSidebar,
    closeSidebar,
    requireConfirmation,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const [editContact, setEditContact] = useState(false);
  const currentUIState = useSelector((state) => state.commonReducer.viewsData);

  useEffect(() => {
    return () => {
      setEditContact(false);
    };
  }, []);


  return (
    <div>
      <SidebarWrapper
        isOpen={
          !isEmpty(currentUIState) && currentUIState?.currentView !== views.confirmationDialog
        }
        className={classNames(
          { 'filters-sidebar': currentUIState?.currentView === views.filtersForm },
          { 'search-page': currentUIState?.currentView === views.filtersForm },
        )}
      >
        <Sidebar
          isOpen={currentUIState?.currentView}
          onClose={() => dispatch(handleViewsData(null))}
        >
          {currentUIState?.currentView == views.filtersForm && (
            <SearchFiltersForm
              isOpen={currentUIState?.currentView === views.filtersForm}
              isAside
              showContent
            />
          )}

          {includes(
            [
              views.talentProfile,
              views.talentEmail,
              views.talentNotes,
              views.talentNoteForm,
              views.talentEmails,
            ],
            currentUIState?.currentView,
          ) && !isEmpty(currentUIState?.talent) ? (
            <>
              <ProfileCard
                profileUrl={
                  currentUIState?.talent?.user?.holedo_url ||
                  currentUIState?.contact?.user?.holedo_url
                }
                firstHeading={
                  currentUIState?.talent?.user?.full_name ||
                  currentUIState?.contact?.user?.full_name
                }
                secondHeading={
                  currentUIState?.talent?.job?.title || currentUIState?.contact?.job?.title
                }
                thirdHeading={
                  currentUIState?.talent.user?.country?.title ||
                  currentUIState?.contact?.user?.title
                }
                label={
                  currentUIState?.talent.user?.date_of_birth ||
                  currentUIState?.contact?.user?.date_of_birth
                }
                avatar={{
                  src: currentUIState?.talent.user?.avatar || currentUIState?.contact?.user?.avatar,
                  name:
                    currentUIState?.talent.user?.full_name ||
                    currentUIState?.contact?.user?.full_name,
                }}
                slug={currentUIState?.talent?.user?.slug || currentUIState?.contact?.user?.slug}
              ></ProfileCard>

              <SidebarTabs>
                <NavItem
                  onClick={() => dispatch(handleCurrentView(views.talentProfile))}
                  active={currentUIState?.currentView === views.talentProfile}
                  link={''}
                >
                  Profile
                </NavItem>

                <NavItem
                  onClick={() => dispatch(handleCurrentView(views.talentNotes))}
                  active={currentUIState?.currentView === views.talentNotes}
                  link={''}
                >
                  <Labeled toCount={talent?.comments}>Notes</Labeled>
                </NavItem>

                <NavItem
                  onClick={() => dispatch(handleCurrentView(views.talentEmail))}
                  active={currentUIState?.currentView === views.talentEmail}
                  link={''}
                >
                  <Labeled toCount={talent?.comments}>Emails</Labeled>
                </NavItem>
              </SidebarTabs>

              {currentUIState?.currentView === views.talentProfile && (
                <TalentProfile
                  // getTags={() => this.getAllTags(talent?.user_id)}
                  userId={currentUIState?.talent?.user_id || currentUIState?.talent?.user?.id}
                  userTags={currentUIState?.talent?.user?.tags}
                  isLoading={'this.state.isLoading'}
                  talent={currentUIState?.talent}
                  onTagAdd={'(tag) => handleAddTag(tag)'}
                  onTagRemove={'(tag) => handleRemoveTag(tag)'}
                />
              )}
              {includes([views.talentNotes, views.talentNoteForm], currentUIState?.currentView) && (
                <TalentNotes
                  talent={currentUIState?.talent}
                  userId={currentUIState?.talent?.user?.id}
                  isLoading={false}
                  isNoteFormOpen={currentView === views.talentNoteForm}
                  onFormOpen={() => dispatch(handleCurrentView(views.talentNoteForm))}
                />
              )}
            </>
          ) : (
            ''
          )}
          {currentUIState?.currentView === views.contactProfile && currentUIState?.contact ? (
            <>
              <Button
                onClick={() => setEditContact(true)}
                style={{
                  color: '#4AAEFD',
                  position: 'absolute',
                  left: '-40%',
                  // top: '20%',
                  cursor: 'pointer',
                  zIndex: 9,
                }}
              >
                Edit
              </Button>
              <ProfileCard
                firstHeading={
                  currentUIState.contact?.first_name + ' ' + currentUIState.contact?.last_name
                }
                secondHeading={currentUIState.contact?.Founder}
                avatar={{
                  ...avatarOptions(currentUIState.contact ?? currentUIState.contact, 'contact'),
                }}
                profileUrl={currentUIState?.contact?.user?.holedo_url}
                thirdHeading={currentUIState?.contact?.user?.title}
                label={currentUIState?.contact?.user?.date_of_birth}
                slug={currentUIState?.contact?.user?.slug}
              />
              <Section title="Contact details" headingSize="large">
                <ContactDetails
                  phones={[{ type: 'Mobile', number: currentUIState.contact?.contact_number }]}
                  skype={
                    currentUIState.contact?.skype !== null ? currentUIState.contact?.skype : 'N/A'
                  }
                  email={currentUIState.contact?.email ? currentUIState.contact?.email : 'N/A'}
                  address={{
                    address_line_1: contact?.user?.address_line_1,
                    address_line_2: contact?.user?.address_line_2,
                    address_line_3: contact?.user?.address_line_3,
                    city: contact?.user?.city,
                    country: contact?.user?.country,
                  }}
                />
                <ContactConnectCard
                  avatar={{
                    ...avatarOptions(currentUIState.contact ?? currentUIState.contact, 'contact'),
                  }}
                  name={currentUIState.contact?.first_name}
                  id={currentUIState.contact?.id}
                  edit={editContact}
                />
              </Section>
            </>
          ) : (
            ''
          )}

          {currentUIState?.currentView === views.recruiterProfile && recruiter && (
            <>
              <ProfileCard
                firstHeading={'fullName(recruiter)'}
                secondHeading={level && <div>asas</div>}
                avatar={"{ ...avatarOptions(recruiter, 'user') }"}
              />
              <Section title="Recruiter details" headingSize="large">
                <ContactDetails email={recruiter?.email} />
              </Section>
            </>
          )}
        </Sidebar>
        {children}
      </SidebarWrapper>
    </div>
  );
};
