import React from 'react';
import { JobLabel } from 'components/jobLabel/jobLabel';
import styles from './JobForm.module.scss';
import { JobIndexActions } from '../jobIndexActions/JobIndexActions';
import stylesJob from './JobForm.module.scss';
import classNames from 'classnames';
import { JobIndexStats } from '../jobIndexStats/jobIndexStats';
import { useNavigate } from 'react-router-dom';
import { routesPath } from '../../constants/routesPath';

export const JobIndexCard = (props) => {
  const { colors, job, client, clientLogo, jobStatuses, clickHandlers, jobsFilter, onClick } =
    props;
  const navigate = useNavigate();

  return (
    <div className={classNames()}>
      <div
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          if (e.target != e.currentTarget) return;
          onClick();
        }}
        className={classNames(styles.compCardContainer, styles.smallMarginsHorizontal)}
      >
        <div className={classNames(styles.compCardContent, 'pb-0')}>
          <div
            className={classNames(styles.jobsMUp, styles.compCardContent, 'p-0')}
            onClick={(e) => {
              if (e.target != e.currentTarget) return;
              onClick();
            }}
          >
            <JobLabel
              job={job}
              colors={colors}
              client={client}
              clientLogo={clientLogo}
              show
              titleLink={() => navigate(routesPath.jobApplicants(job?.id), { replace: true })}
            />

            <JobIndexStats
              onClick={onClick}
              views={job.views}
              applied={job.job_applications} //dummy not available in api
              matches={45} //dummy not available in api
              postedOn={job.created}
              expiresOn={job.expiry_date}
              job={job}
              className={styles.jobIndexStats}
            />
            <JobIndexActions
              jobStatuses={jobStatuses}
              clickHandlers={clickHandlers}
              job={job}
              jobsFilter={jobsFilter}
              className={styles.jobIndexActions}
            />
          </div>
          <div className={classNames(styles.jobsMDown)}>
            <div className={styles.jobsTopRowMDown}>
              <JobLabel
                job={job}
                colors={colors}
                client={client}
                clientLogo={clientLogo}
                titleLink={() => navigate(routesPath.jobApplicants(job?.id), { replace: true })}
                className="jobListingAvatar"
              />

              <JobIndexActions
                jobStatuses={jobStatuses}
                clickHandlers={clickHandlers}
                job={job}
                jobsFilter={jobsFilter}
                className={styles.jobIndexActions}
              />
            </div>

            <JobIndexStats
              onClick={onClick}
              views={job.views}
              applied={10} //dummy not available in api
              matches={45} //dummy not available in api
              postedOn={job.created}
              expiresOn={job.expiry_date}
              job={job}
              className={styles.jobIndexStats}
            />
          </div>
        </div>
        <small
          onClick={(e) => {
            if (e.target != e.currentTarget) return;
            onClick();
          }}
          style={{ position: 'relative', left: '130px', bottom: '10px', color: '#888888' }}
          className={classNames(stylesJob.smallText, 'mb-0', 'me-2')}
        >
          {job?.client?.title}
        </small>
      </div>
    </div>
  );
};
