import destroy from '../apiService/destroy';
import form from '../apiService/form';
import list from '../apiService/list';

const pipeline = {};
pipeline.list = () => list('/rest/pipelines', 'pipeline');
pipeline.form = () => form('/rest/pipelines', 'pipeline');
pipeline.delete = () => destroy('/rest/pipelines/delete', 'pipeline');

export default pipeline;
