export const searchAliasis = {
  job_titles: {
    header: 'Job titles',
    alias: 'job_titles',
    type: 'array',
  },
  currentPositionOnly: {
    header: 'Current position only',
    alias: 'currentPositionOnly',
  },
  company: { header: 'From company', alias: 'company' },
  user_tags: {
    header: 'Tags',
    alias: 'user_tags',
    type: 'array',
  },
  countries: {
    header: 'Nationalities',
    alias: 'countries',
    type: 'array',
  },
  membership_grades: {
    header: 'Membership grades',
    alias: 'membership_grades',
  },
  rank_experience: {
    header: 'Rank/Experience',
    alias: 'rank_experience',
    type: 'array',
  },
  current_department: {
    header: 'Current department',
    alias: 'current_department',
  },
  studied_at_institution: {
    header: 'Studied at institution',
    alias: 'studied_at_institution',
  },
  gender: {
    header: 'Gender',
    alias: 'gender',
  },
  fluently_speaks: {
    header: 'Fluently speaks',
    alias: 'fluently_speaks',
    type: 'array',
  },
  marital_status: {
    header: 'Marital status',
    alias: 'marital_status',
  },
  qualification_achieved: {
    header: 'Qualification achieved',
    alias: 'qualification_achieved',
    type: 'array',
  },
};
