import axios from 'axios';
import { baseUrl } from '../constants/baseUrl';
import toast from 'react-hot-toast';
import storageService from './storageService';

export const listApiCall = async (url) => {
  try {
    const response = await axios.get(`${baseUrl}${url}`, {
      headers: {
        common: {
          AuthApi: `Bearer ${storageService.getToken()}`,
          Device: `TMR`,
        },
      },
    });
    return response?.data?.data;
  } catch (err) {
    toast.error('failed to get countries');
  }
};
