import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { handleNavToggle } from '../../redux/common/commonSlice';

import styles from './nav.module.scss';

export const NavBar = ({ children, type, className, ...rest }) => {
  const navToggle = useSelector((state) => state.commonReducer.navBarToggle);

  return (
    <div className={`navbar  ${styles.navBar} ${type && styles[type]}`} {...rest}>
      {children}
      {type == 'mainmenu' && <h4 className={styles.tmrHeadingMobile}>TMR</h4>}
    </div>
  );
};
