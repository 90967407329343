import React from 'react';
import { NavLink } from 'react-router-dom';
import { size } from 'lodash';

import { BaseCard } from '../baseCard/baseCard';
import Role from '../role/role';
import { MdOutlineWc as CandidatesIcon } from 'react-icons/md';
import { avatarOptions } from '../avatar/avatar';

import styles from './projectCard.module.scss';
import { routesPath } from '../../constants/routesPath';

export const ProjectCard = ({ client, project, onRemove, onEdit, color, onClick }) => {
  return (
    <>
      {project ? (
        <div className={styles.projectCard}>
          <BaseCard
            item={project}
            color={color}
            avatar={{ ...avatarOptions(project, 'project'), size: 40 }}
            title={project.title}
            subhead={client?.title}
            // link={routes.projectPipeline(project.client.id, project.id)}
            onEdit={onEdit}
            size={'medium'}
            onRemove={onRemove}
            onClick={onClick}
          >
            <div className={styles.contentWrapper}>
              <NavLink
                className={styles.candidates}
                to={routesPath.dashboard}
                // to={routes.projectPipeline(project.client.id, project.id)}
              >
                <CandidatesIcon size={20} /> {project.projects_pipeline_stages_users?.length}{' '}
                Candidates
              </NavLink>
              <Role className={project.minimum_level}>{project.minimum_level}</Role>
            </div>
          </BaseCard>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

// ProjectCard.propTypes = {
//   project: React.PropTypes.object.isRequired,
// };
