import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './wizardSteps.module.scss';
import { routesPath } from 'constants/routesPath';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

export const WizardSteps = (props) => {
  const { data, from } = props;
  const activeWizardStep = useSelector((state) => state.commonReducer.wizardCurrentView.step);

  return (
    <>
      <div className={styles.wizardParent}>
        {data.map((option, i) => {
          return (
            <div className={styles.wizardLink} key={i}>
              <NavLink
                to={
                  activeWizardStep > i ? option?.routesPath : option?.id ? option?.routesPath : '#'
                }
                className={classNames(
                  activeWizardStep == option.step ? styles.activeStep : styles.unActiveStep,
                )}
              >
                {option.label}
              </NavLink>
              <div
                className={classNames(
                  (activeWizardStep == option.step || activeWizardStep == 2) && option.step != 0
                    ? from
                      ? styles.jobUnderliners
                      : styles.underliners
                    : null,
                )}
              ></div>
            </div>
          );
        })}
      </div>
    </>
  );
};
