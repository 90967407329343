import React from 'react';
import classNames from 'classnames';

import styles from '../formColumns/forms.module.scss';
import { Heading } from '../heading/heading';

export const Fieldset = (props) => {
  const { title, description, className, children } = props;
  return (
    <fieldset className={classNames('form-fieldset', styles.fieldset, className)}>
      {title && (
        <Heading size="medium" className={styles.title}>
          {title}
        </Heading>
      )}
      {description && <p className={styles.description}>{description}</p>}
      <div className={classNames({ [styles.content]: title }, { 'mt-0': !description })}>
        {children}
      </div>
    </fieldset>
  );
};
