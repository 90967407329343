import React from 'react';
import classNames from 'classnames';
import { MenuItem as ReactMenuItem, MenuList } from 'react-menu-list';

import styles from '../nav/nav.module.scss';

export const MenuSlot = ({ onSelected, disabled, className, children }) => (
  <MenuList>
    <ReactMenuItem
      className={classNames(
        'menu-item-slot',
        { 'menu-item-disabled': disabled },
        styles.menuItemSlot,
        className,
      )}
      onItemChosen={onSelected}
    >
      {children}
    </ReactMenuItem>
  </MenuList>
);
