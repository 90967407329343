import { createApi } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../constants/baseUrl';
import { axiosBaseQuery } from './axiosBaseQuery';

// initialize an empty api service that we'll inject endpoints into later as needed
const baseSlice = createApi({
  reducerPath: 'baseSlice',

  baseQuery: axiosBaseQuery({ baseUrl: baseUrl }),

  endpoints: () => ({}),
});

export const baseSliceWithTags = baseSlice.enhanceEndpoints({
  addTagTypes: [
    'clients',
    'jobs',
    'note',
    'tag',
    'project',
    'pipeline',
    'stage',
    'search',
    'contact',
  ],
});
