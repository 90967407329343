import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from '../clientForm/clientForm.module.scss';
import quilStyles from './quil.module.scss';
import { useFormContext, useWatch } from 'react-hook-form';

export const Quil = (props) => {
  const { name, label, type, required, fieldDescription, maxLength } = props;
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const originalValue = useWatch({ name: name }) || '';

  const [content, setContent] = useState(originalValue);

  useEffect(() => {
    register(name);
  }, [register, name]);

  const getPlainText = (html) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const handleQuil = (data) => {
    const plainText = getPlainText(data);

    if (maxLength) {
      if (plainText.length < maxLength) {
        setContent(data);
        setValue(name, data, { shouldValidate: true });
      } else {
        const truncatedContent = data.slice(0, data.length - (plainText.length - maxLength + 1));
        setContent(truncatedContent);
        setValue(name, truncatedContent, { shouldValidate: true });
      }
    } else {
      setContent(data);
      setValue(name, data, { shouldValidate: true });
    }
  };

  return (
    <div className="form-group my-4" style={{ clear: 'both' }}>
      <label className={styles.clientDescriptionLabel} htmlFor={name}>
        {required && <small className="text-danger">* </small>}
        {label}
        {fieldDescription && (
          <span className={`${quilStyles.description} d-block mb-3`}>
            {fieldDescription}
          </span>
        )}
      </label>
      <div>
        <ReactQuill
          className={type === 'media' ? quilStyles.QuillEditormedia : quilStyles.QuillEditor}
          onChange={handleQuil}
          value={content}
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline', 'blockquote', { list: 'ordered' }, { list: 'bullet' }],
            ],
          }}
        />

        {maxLength && (
          <small className="text-muted float-end">
            {getPlainText(content).length}/{maxLength} characters
          </small>
        )}

        <small className="text-danger float-start" style={{ clear: 'both' }}>
          {errors[name]?.message}
        </small>
      </div>
    </div>
  );
};
