import React from 'react';
import classNames from 'classnames';
import styles from './nav.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { handleNavToggle } from '../../redux/common/commonSlice';

export const NavToggle = () => {
  const dispatch = useDispatch();
  const navToggle = useSelector((state) => state.commonReducer.navBarToggle);

  return (
    <div id={styles.menuToggle}>
      <input
        type="checkbox"
        id="checkbox4"
        checked={navToggle}
        className={classNames(styles.checkbox4, styles.visuallyHidden)}
        onChange={() => dispatch(handleNavToggle())}
      />
      <label htmlFor="checkbox4">
        <div className={classNames(styles.burger, styles.burger4)}>
          <span className={classNames(styles.bar, styles.bar1)}></span>
          <span className={classNames(styles.bar, styles.bar2)}></span>
          <span className={classNames(styles.bar, styles.bar3)}></span>
          <span className={classNames(styles.bar, styles.bar4)}></span>
          <span className={classNames(styles.bar, styles.bar5)}></span>
        </div>
      </label>
    </div>
  );
};
