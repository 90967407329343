import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { JobTalentSearchKeyword } from '../jobTalentSearchKeyword/jobTalentSearchKeyword';
import styles from './jobTalentSearchFilters.module.scss';
import yupSchemaService from '../../services/yupSchemaService';
import { JobTalentSearchFiltersFields } from '../jobTalentSearchFiltersFields/jobTalentSearchFiltersFields';
import jobApplicants from '../../redux/modules/jobApplicants';
import { apiUrls } from '../../constants/apiurls';
import { useParams } from 'react-router-dom';
import { useDebounce } from '../../utils/debounce';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

export const JobTalentSearchFilters = (props) => {
  const {
    jobData,
    languages,
    qualifications,
    countries,
    membershipGrades,
    positionLevels,
    jobsData,
    setState,
    handleSubmit,
    form,
    filterOpenMobile,
    currentFilters,
  } = props;
  const currentUIState = useSelector((state) => state.commonReducer.viewsData);

  const handleExperienceChange = (name, currentValue) => {
    let value = form.watch(name);

    if (Array.isArray(currentValue)) {
      if (typeof currentValue[currentValue.length - 1] === 'object' && currentValue !== null) {
        value =
          currentValue[currentValue.length - 1].id || currentValue[currentValue.length - 1].value;
      } else {
        value = currentValue.find((val) => val == value);
      }
    } else if (typeof currentValue === 'object' && currentValue !== null) {
      value = currentValue.id || currentValue.value;
    } else {
      value = currentValue;
    }

    if (jobsData?.data?.filter_options?.length) {
      jobsData?.data?.filter_options?.map((job) => {
        if (!job?.filters?.length && job?.header?.replaceAll(' ', '_') === name) {
          form.setValue(name, null, { shouldValidate: true });
          return;
        } else if (job?.filters?.length && job?.header?.replaceAll(' ', '_') === name) {
          let showError = true;
          job?.filters.map((filter) => {
            filter?.options.map((option) => {
              if (option.data?.id === value) {
                toast.success('Filter Applied!');
                let filters = option.link?.split('filters')[1];
                setState((prevState) => ({ ...prevState, currentFilters: filters }));
                if (option?.selected) {
                  form.setValue(name, null, { shouldValidate: true });
                }
                showError = false;
              } else {
                if (showError) {
                  showError = false;
                  toast.error('No Additional Data For This Selection!');
                }
                form.setValue(name, null, { shouldValidate: true });
              }
            });
          });
        }
      });
    }
  };

  const onClickClose = (val) => {
    setState((prevState) => ({ ...prevState, filterOpen: !prevState?.filterOpen }));
  };

  return (
    <>
      {currentUIState?.currentView && (
        <div
          className={classNames(styles.col_1_3, {
            ['col-lg-2']: currentUIState?.currentView,
            ['col-lg-1']: !currentUIState?.currentView,
          })}
        ></div>
      )}
      <div
        className={classNames(styles.col_1_3, {
          ['col-lg-4']: currentUIState?.currentView,
          ['col-lg-3']: !currentUIState?.currentView,
        })}
      >
        <div
          className={classNames(styles.compCardContainer, styles.filterMobile, {
            [styles.filterMobileOpen]: filterOpenMobile,
          })}
        >
          <form onSubmit={handleSubmit(handleSubmit)}>
            {/* KEYWORD SEARCH */}
            <JobTalentSearchKeyword
              form={form}
              filterOpenMobile={filterOpenMobile}
              onClickClose={onClickClose}
            />
            {/* <JobTalentSearchKeywordForm
            form={formSearch}
            getJobTalents={getJobTalents}
            jobId={jobId}
            initialValues={initialValues}
            onSubmit={onSubmit}
            handleFormSearch={handleSubmitForm}
            setSearchFormFieldValue={setSearchFormFieldValue}
            setSearchFilterFormFieldValue={setSearchFilterFormFieldValue}
          /> */}

            {/* SEARCH FILTER */}
            {/* <JobTalentSearchFilterForm
            form={formSearchFilters}
            onSubmit={onSubmit}
            handleFormSearch={handleSubmitForm}
          /> */}
            <JobTalentSearchFiltersFields
              languages={languages}
              qualifications={qualifications}
              membershipGrades={membershipGrades}
              countries={countries}
              positionLevels={positionLevels}
              handleExperienceChange={handleExperienceChange}
            />
          </form>
        </div>
      </div>
    </>
  );
};
