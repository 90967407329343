import { ClientHeader } from '@components/clientHeader/clientHeader';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import clients from '@redux/modules/clients';
import { apiUrls } from '@constants/apiurls';
import { routesPath } from 'constants/routesPath';

export const ClientContainer = () => {
  const params = useParams();

  const {
    data: clientData,
    error: clientError,
    isFetching: clientLoading,
  } = clients.list().useClientsdetailQuery(
    { params: { id: params.clientID }, extendedPath: apiUrls.getClientDetail() },
    {
      skip: !params.clientID,
    },
  );
  return (
    <>
      <ClientHeader
        client={clientData?.data}
        // projects={projects}
        newEntry={!params.clientID}
        onCancel={routesPath.dashboard}
        // active="settings"
        // onEdit={(client) => push(routes.editClient(client.id))}
        // onRemove={(client) =>
        //   requireConfirmation([destroy(client.uri), pushAction(routes.dashboard)])
        // }
      />
      <Outlet />
    </>
  );
};
