import React from 'react';
import { ProjectsListing } from '@components/projectsListing/projectsListing';
import { apiUrls } from '@constants/apiurls';
import projects from '@redux/modules/projects';
import styles from '@components/dashboard/dashboard.module.scss';
import { routesPath } from '@constants/routesPath';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ClientHeader } from '@components/clientHeader/clientHeader';
import clients from '@redux/modules/clients';
import { Spinner } from 'components/loader/loader';

export const CandidatesContainer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const {
    data: projectsData,
    isLoading: projectsLoading,
    error: projectsError,
    isSuccess: projectsSuccess,
  } = projects.list().useProjectlistQuery({ params: '', extendedPath: apiUrls.getProjects() });

  const {
    data: projectData,
    error: clientError,
    isFetching: clientLoading,
  } = projects.list().useProjectdetailQuery(
    { params: { id: params.projectId }, extendedPath: apiUrls.getProjectDetail() },
    {
      skip: !params.projectId,
    },
  );
  const onEdit = () => {
    navigate(routesPath.editClient(params.clientId));
  };
  return (
    <div className={styles.dashboardWrappper}>
      <ClientHeader
        // pipeline={{ id: params.projectId }}
        pipeline={projectData?.data}
        project={projectsData?.data}
        // clientData={clientData?.data}
        onEdit={onEdit}
      />
      <Outlet />
    </div>
  );
};
