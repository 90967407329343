import React from 'react';
import classNames from 'classnames';

import { Heading } from '../heading/heading';
// import { InfoIcon } from 'components/Icons';
import { MdInfoOutline as InfoIcon } from 'react-icons/md';
import styles from './emptyCard.module.scss';

export const EmptyCard = ({ isCard, title, description, children }) => (
  <div className={classNames('empty', styles.cardWrapper, { [styles.isCard]: isCard })}>
    <div className={styles.cardIcon}>
      <InfoIcon size={30} />
    </div>
    {title && (
      <div className={styles.cardTitle}>
        <Heading size="medium">{title}</Heading>
      </div>
    )}
    {description && (
      <div className={styles.cardDescription}>
        <p>{description}</p>
      </div>
    )}
    {children && <div className={styles.cardAction}>{children}</div>}
  </div>
);
