import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/core.scss';
// import createStore from './redux/createStore'

// ========================================================
// Store Instantiation
// ========================================================
// const store = createStore({})

// function restricted(f) {
//   return function () {
//     if (typeof requestAnimationFrame === 'undefined') return;
//     return f.apply(this, arguments);
//   };
// }

// restricted();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
