import axios from 'axios';
import dataService from './dataService';
import { baseUrl } from '../constants/baseUrl';
import toast from 'react-hot-toast';
import storageService from './storageService';

export const fetchAutoCompleteData = async (type = '', url) => {
  try {
    const response = await axios.get(`${baseUrl}${url}`, {
      headers: {
        common: {
          AuthApi: `Bearer ${storageService.getToken()}`,
          Device: `TMR`,          
        },
      },
    });

    let result = dataService.autoCompletionParser(type, response.data?.data);
    return result;
  } catch (err) {
    toast.error('failed to get countries');
  }
};
