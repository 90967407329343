import destroy from '../apiService/destroy';
import form from '../apiService/form';
import list from '../apiService/list';

const tags = {};
tags.list = () => list('/rest/user-tags', 'tag');
tags.form = () => form('/rest/user-tags', 'tag');
tags.delete = () => destroy('/rest/user-tags/delete', 'tag');

export default tags;
