import classNames from 'classnames';
import { map, isEmpty, isEqual } from 'lodash';
import styles from '../jobForm/jobForm.module.scss';
export const JobClientForm = (props) => {
  const { show } = props;
  return (
    <>
      {show && (
        <span>
          <div className={classNames(styles.compCardTitleContainer)}>
            <span className={classNames(styles.dividerTextLarge)}>
              {'EDIT COMPANY DETAILS FOR THIS JOB'}
            </span>
          </div>

          <div className={classNames(styles.compCardContent)}>
            <div className={classNames(styles.col_1_1)}>
              {/* COMPANY NAME */}
              <div className={classNames(styles.col_1_2, styles.fieldWrapper)}>
                <span className={classNames(styles.inputTitle)}>Company name</span>
                <div className={classNames(styles.textSmall)}>
                  {'What company is this job post for?'}
                </div>
                {/* <Field name="company_name" component={Input} placeholder={'Enter company name'} /> */}
              </div>

              {/* COMPANY DESCRIPTION */}
              <div className={classNames(styles.col_1_1, styles.fieldWrapper)}>
                <span className={classNames(styles.inputTitle)}>Company description</span>
                <div className={classNames(styles.textSmall)}>
                  {'Tell us more about the company'}
                </div>
                {/* <Field name="company_description" component={QuillEditor} /> */}
              </div>

              <div className={classNames(styles.col_1_1)}>
                {/* CITY */}
                <div className={classNames(styles.col_1_2, styles.fieldWrapper)}>
                  <span className={classNames(styles.inputTitle)}>City</span>
                  {/* <Field name="company_city" component={Input} placeholder={'Enter city name'} /> */}
                </div>

                {/* COUNTRY */}
                <div className={classNames(styles.col_1_2, styles.fieldWrapper)}>
                  <span className={classNames(styles.inputTitle)}>Country</span>
                  {/* <Field
                    name="company_country"
                    component={Select}
                    placeholder="Select a country"
                    options={map(filterCountries(countries.all), (c) => ({
                      value: c.alpha2,
                      label: c.name,
                    }))}
                    parse={(value) => value && value.value}
                  /> */}
                </div>
              </div>

              <div className={classNames(styles.col_1_1)}>
                {/* PROVINCE */}
                <div className={classNames(styles.col_1_2, styles.fieldWrapper)}>
                  <span className={classNames(styles.inputTitle)}>State / region / province</span>
                  {/* <Field
                    name="company_state_province_or_region"
                    component={Input}
                    placeholder={'Enter State / region / province name'}
                  /> */}
                </div>

                {/* WEBSITE */}
                {/*
          <div className={ classNames(styles.col_1_2, styles.fieldWrapper) }>
            <span className={ classNames(styles.inputTitle) }>Website</span>
            <Field
              name="company_website"
              component={ Input }
              placeholder={ 'Enter website url' }
            />
          </div>
          */}
              </div>
            </div>
          </div>
        </span>
      )}
    </>
  );
};
