import destroy from '../apiService/destroy';
import form from '../apiService/form';
import list from '../apiService/list';

const roles = {};
roles.list = () => list('/rest/company-roles', 'roles');
roles.form = () => form('/rest/company-roles', 'roles');
roles.delete = () => destroy('/rest/company-roles/delete', 'roles');

export default roles;
