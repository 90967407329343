import React from 'react';
import classNames from 'classnames';
import { MenuItem as ReactMenuItem } from 'react-menu-list';
import { Button } from '../button/button';

import styles from '../nav/nav.module.scss';

export const MenuAction = ({ onClick, className, children }) => (
  <ReactMenuItem className={classNames('menu-item', styles.menuAction, className)}>
    <Button submit onClick={onClick} size="small">
      {children}
    </Button>
  </ReactMenuItem>
);
