import React from 'react';
import { LogoUploader } from '../form/logoUploader';
import classNames from 'classnames';
import { JobImageLogoDescription } from '../jobImageLogoDescription/jobImageLogoDescription';
import styles from '../jobForm/jobForm.module.scss';
import buttonStyles from '../jobImageLogoDescription/jobImageLogoDescription.module.scss';
import { ImageUpload } from '../imageUpload/imageUpload';
import { Button } from '../button/button';
import { Badge } from 'reactstrap';

export const JobFormImageBranding = (props) => {
  const { state, setState } = props;

  return (
    <div className={classNames(styles.compCardContainer)}>
      <div className={classNames(styles.compCardTitleContainer, 'd-flex justify-content-between')}>
        <span className={classNames(styles.dividerTextLarge)}>JOB POST BRANDING</span>
        <span className={classNames(styles.dividerTextLarge)}>
          <Badge className={classNames('premiumPill d-flex', styles.jobPremiumPill)}>
            PREMIUM JOBS ONLY
          </Badge>
        </span>
      </div>
      <div className={classNames(styles.compCardContent)}>
        {/* <JobImageLogoInput job={job} onLogoClick={() => logoField.open()} /> */}

        <div className="w-100">
          <JobImageLogoDescription logo={state.logo}/>
          <ImageUpload
            state={state}
            setState={setState}
            name="job_images"
            label="Upload photos of your post"
            description="Upload and select which photos to display in your post. Jpg, Png, or Gif file types only."
          >
            <Button submit inline size="small">
              Upload photo
            </Button>
          </ImageUpload>

          {/* <Field
            name="job_images"
            ref={(node) => {
              if (node) {
                imagesField = node.getRenderedComponent();
              }
            }}
            withRef
            changeFunc={changeHandler}
            component={ImageUpload}
            props={{ form: 'job' }}
            images={job.job_images}
            onAdd={imageControls.onImageAdd}
            onRemove={imageControls.onImageRemove}
            onSelect={imageControls.onImageSelect}
            label="Upload photos of your post"
            description="Upload and select which photos to display in your post. Jpg, Png, or Gif file types only."
          >
            <Button submit inline size="small">
              Upload photo
            </Button>
          </Field> */}
        </div>
        {/* <div style={{ display: 'none' }}>
          <Field
            name="logo"
            changeFunc={changeHandler}
            component={ImageUpload}
            ref={(node) => {
              if (node) {
                logoField = node.getRenderedComponent();
              }
            }}
            withRef
            props={{ form: 'job' }}
            onAdd={imageControls.onLogoAdd}
            multiple={false}
            format={(value) => {
              if (isString(value) && !isEmpty(value)) {
                return [
                  {
                    name: value.split(/[\\/]/).pop(),
                    preview: value,
                  },
                ];
              }
              return value;
            }}
            parse={(value) => (isEmpty(value) ? null : value)}
          />
        </div> */}
      </div>
    </div>
  );
};
