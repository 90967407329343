import classNames from 'classnames';
import styles from '../formColumns/forms.module.scss';
import { MAX_INITIALS } from '../avatar/avatar';
import { TextInput } from '../form/textInput';

export const Acronym = (props) => {
  const { label, meta, className, description, ...rest } = props;
  const error = meta && meta.error;
  const id = `select-${label}`;

  return (
    <div
      className={classNames(
        'form-elements',
        styles.acronymtWrapper,
        styles.inputWrapper,
        { [styles.fieldHasError]: error },
        className,
      )}
    >
      <span className={classNames('form-elements-wrapper d-block')}>
        <span className={classNames('form-element', styles.acronymInnerWrapper)}>
          {/* <Input maxLength={MAX_INITIALS} {...rest} /> */}
          <TextInput maxlength={2} {...rest} name="acronym" />
        </span>

        {label && (
          <label htmlFor={id && id} className={classNames('element-label', styles.label)}>
            {label}
            {description && (
              <p className={classNames('element-description', styles.description)}>{description}</p>
            )}
          </label>
        )}
      </span>
    </div>
  );
};
