import React, { useState } from 'react';
import styles from './stageCard.module.scss';
import { MdClose as CloseIcon } from 'react-icons/md';
import { AiOutlineCheck as CheckIcon } from 'react-icons/ai';
import { MdEdit as EditIcon } from 'react-icons/md';
import { TextInput } from '../form/textInput';
import { Draggable } from 'react-beautiful-dnd';
import { useWatch, useFormContext } from 'react-hook-form';

export const StageCard = (props) => {
  const { deleted, id, index, name, remove, createStage, field_id } = props;
  const text = useWatch({ name: `pipeline_stages.${index}.title` });
  const [cardEdit, setCardEdit] = useState(text ? false : true);

  return (
    <Draggable index={index} draggableId={field_id} isDragDisabled={false}>
      {(provided) => (
        <div
          className={deleted ? styles.deleted : ''}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          key={id}
        >
          {cardEdit ? (
            <div className={styles.wrapper_input}>
              <TextInput
                name={name}
                id={id}
                type="text"
                placeholder="E.g. Senior position pipeline"
              />
              <CheckIcon
                onClick={() => {
                  if (text) {
                    setCardEdit(false);
                  }
                }}
                className={styles.checkInput}
              />
              <CloseIcon onClick={() => remove(index)} className={styles.closeInput} />
            </div>
          ) : (
            <div className={styles.wrapper}>
              {text}
              <EditIcon onClick={() => setCardEdit(true)} className={styles.edit} />
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};
