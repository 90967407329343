import React from 'react';

import { Page } from '../page/page';
import { PageSection } from '../pageSection/pageSection';
import { RoundButton } from '../roundButton/roundButton';
import { MdOutlineSearch as SearchIcon } from 'react-icons/md';
import { MdAdd as AddIcon } from 'react-icons/md';
import { GenericInput } from '../genericInput/genericInput';

const PageListSection = ({
  onAdd,
  onSearch,
  searchQuery,
  title,
  children,
  icon,
  className,
  searchPlaceholder,
}) => (
  <Page>
    <PageSection
      title={title}
      icon={icon}
      search={
        onSearch && (
          <GenericInput
            name="keyword"
            type="text"
            placeholder={searchPlaceholder}
            icon={<SearchIcon />}
            iconRight
            value={searchQuery}
            onChange={(event) => onSearch(event.target.value)}
          />
        )
      }
      className={className}
      action={
        onAdd && (
          <RoundButton onClick={onAdd}>
            <AddIcon size={30} />
          </RoundButton>
        )
      }
    >
      {children}
    </PageSection>
  </Page>
);

export default PageListSection;
