import React from 'react';
import classNames from 'classnames';
import styles from './nav.module.scss';
import { Heading } from '../heading/heading';
import { Dropdown } from '../dropDown/dropDown';
import { Link, NavLink } from 'react-router-dom';
import { DropdownTrigger } from '../dropDownTrigger/dropDownTrigger';
import { useSelector } from 'react-redux';

export const NavItem = ({
  title,
  separator,
  group,
  onClick,
  link,
  className,
  active,
  hasDropdown,
  zIndex,
  children,
  toRight,
  highlight,
  ...rest
}) => {
  const navToggle = useSelector((state) => state.commonReducer.navBarToggle);
  return (
    <div
      className={`navitem ${styles.navBarItem} ${separator ? styles.separator : ''} ${
        hasDropdown ? styles.hasDropdown : ''
      } ${highlight ? styles.highlight : ''} ${className ? className : ''}`}
      {...rest}
    >
      {group ? (
        <Heading className={styles.navItemGroup}>{title}</Heading>
      ) : hasDropdown ? (
        <Dropdown
          zIndex={zIndex}
          toRight={toRight}
          className={className}
          trigger={<DropdownTrigger>{title}</DropdownTrigger>}
        >
          {children}
        </Dropdown>
      ) : link || onClick ? (
        <NavLink to={link} onClick={onClick} className={classNames({ [styles.active]: active })}>
          {(title && title) || children}
        </NavLink>
      ) : (
        children
      )}
    </div>
  );
};
