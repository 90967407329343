import React from 'react';

import styles from 'components/talent/talent.module.scss';
import { NavBar } from '../nav/navBar';
import { NavSegment } from '../nav/navSegment';

export const SidebarTabs = ({ children }) => (
  <div className={styles.talentProfileNav}>
    <NavBar type="submenu">
      <NavSegment placement="left">{children}</NavSegment>
    </NavBar>
  </div>
);
