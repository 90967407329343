import destroy from '../apiService/destroy';
import form from '../apiService/form';
import list from '../apiService/list';

const jobs = {};
jobs.list = () => list('/rest/jobs', 'job');
jobs.form = () => form('/rest/jobs', 'job');
jobs.delete = () => destroy('/rest/jobs/delete', 'job');

export default jobs;
