import React from 'react';
import classNames from 'classnames';
import { map, size, compact } from 'lodash';
import styles from './contextMenu.module.scss';

export const ContextMenu = (props) => {
  const { visible, children, ...rest } = props;
  const visibleChildren = compact(children);
  return (
    <div className={classNames(styles.contextMenuWrapper, { [styles.visible]: visible })} {...rest}>
      {map(visibleChildren, (child, key) => (
        <div
          key={key}
          className={styles.contextMenuItem}
          style={{ width: `${100 / size(visibleChildren)}%` }}
        >
          {child}
        </div>
      ))}
    </div>
  );
};
