import React from 'react';
import classNames from 'classnames';

import { Heading } from '../heading/heading';

import styles from './wizard.module.scss';

export const Wizard = ({ step, action, icon, title, children, nextTitle, formName, id }) => (
  <>
    {!id ? (
      <div className={styles.wizardPageWrapper}>
        <div className={classNames(styles.wizardWrapper, { [styles[`wizardStep-${step}`]]: step })}>
          <div className={styles.wizardTitle}>
            <span className={styles.iconContainer}>{icon}</span>
            <Heading size="large">{title}</Heading>
            <Heading size="large">{nextTitle}</Heading>
          </div>
          <div className={styles.wizardContainer}>{children}</div>
          {action && <div className={styles.skipStep}>{action}</div>}
          {formName === 'project' && (
            <div className={styles.helperText}>
              <p className={styles.helperHead}>What are the projects?</p>
              <p className={styles.helperPara}>Projects are the job positions that you</p>
              <p className={styles.helperPara}>are recruiting for. Like a Head Chef or</p>
              <p className={styles.helperPara}>Food & Beverage Manager. You can</p>
              <p className={styles.helperPara}>add a project now or later stage</p>
              <p className={styles.helperPara}>from the client menu.</p>
            </div>
          )}
          {formName === 'addRecruiterForm' && (
            <div className={styles.helperText}>
              <p className={styles.helperHead}>Can't find the right person?</p>
              <p className={styles.helperPara}>In order to add a new recruiter you</p>
              <p className={styles.helperPara}>need to be connected with them</p>
              <p className={styles.helperPara}>Hospitality Leaders. First connect with</p>
              <p className={styles.helperPara}>them, then add as a recruiter</p>
            </div>
          )}
          {formName === 'contactForm' && (
            <div className={styles.helperText}>
              <p className={styles.helperHead}>Client Contacts are people who you</p>
              <p className={styles.helperPara}>need liase with during the hiring</p>
              <p className={styles.helperPara}> process who may need to approve or</p>
              <p className={styles.helperPara}> deny candidates. Like department</p>
              <p className={styles.helperPara}>heads or HR managers.</p>
            </div>
          )}
        </div>
      </div>
    ) : (
      <div className={styles.wizardContainer}>{children}</div>
    )}
  </>
);
