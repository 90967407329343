import React from 'react';

import { apiUrls } from '@constants/apiurls';
import projects from '@redux/modules/projects';
import styles from '@components/dashboard/dashboard.module.scss';
import { routesPath } from '@constants/routesPath';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ClientHeader } from '@components/clientHeader/clientHeader';
import clients from '@redux/modules/clients';
import { Spinner } from '@components/loader/loader';
import { ContactsListing } from '@components/contactsListing/contactsListing';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

export const ContactsListingContainer = () => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    data: clientData,
    error: clientError,
    isFetching: clientLoading,
  } = clients.list().useClientsdetailQuery(
    { params: { id: params.clientId }, extendedPath: apiUrls.getClientDetail() },
    {
      skip: !params.clientId,
    },
  );

  const [clientUpdate, clientUpdateData] = clients.form().useClientsupdateMutation();

  useEffect(() => {
    if (clientUpdateData?.isSuccess) {
      toast.success('Contact successfully deleted!');
    }
    if (clientUpdateData?.isError) {
      toast.error('Failed to delete contact!');
    }
  }, [clientUpdateData]);

  const onAdd = (contact) => {
    navigate(routesPath.addContacts(params.clientId), { state: { id: contact?.id } });
  };

  const deleteClientContact = (contacts) => {
    let { id } = contacts;
    let contactDetail = {};
    contactDetail['id'] = params?.clientId;
    const previousContacts = clientData.data.client_contacts.map((data) => ({
      id: data.id,
    }));
    const updatedContacts = previousContacts.filter(function (contact) {
      return contact.id != id;
    });
    contactDetail['client_contacts'] = updatedContacts;
    clientUpdate(contactDetail);
  };

  return (
    <div className={styles.dashboardWrappper}>
      {clientLoading ? (
        <div className="text-center ">
          <Spinner height="100" width="100" />
        </div>
      ) : (
        clientData?.data?.client_contacts?.length && (
          <ContactsListing
            clients={clientData?.data}
            contacts={clientData?.data?.client_contacts}
            onProjectAdd={onAdd}
            onRemove={deleteClientContact}
            onEdit={onAdd}
          />
        )
      )}
    </div>
  );
};
