import classNames from 'classnames';

import styles from './confirmationDialog.module.scss';
import { Button } from '../button/button';
import { Modal } from '../modal/modal';

export const ConfirmationDialog = ({
  visible,
  children,
  onConfirm,
  onCancel,
  acceptLabel,
  cancelLabel,
  onClose,
  ...rest
}) => {
  return (
    <>
      {visible && (
        <div className={classNames(styles.confirmationWrapper)} {...rest}>
          <div className={classNames(styles.confirmationModal)}>
            <Modal onClose={onClose}>
              <div className={styles.confirmationBody}>{children}</div>
              <div className={styles.confirmationActions}>
                <Button submit inline onClick={onConfirm}>
                  {acceptLabel}
                </Button>
                <Button regular inline onClick={onCancel}>
                  {cancelLabel}
                </Button>
              </div>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};
