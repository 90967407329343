import React from 'react';

import { apiUrls } from '@constants/apiurls';
import projects from '@redux/modules/projects';
import styles from '@components/dashboard/dashboard.module.scss';
import { routesPath } from '@constants/routesPath';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ClientHeader } from '@components/clientHeader/clientHeader';
import clients from '@redux/modules/clients';
import { Spinner } from '@components/loader/loader';
import { ContactsListing } from '@components/contactsListing/contactsListing';
import { RecruitersListing } from '../../components/recruitersListing/recruitersListing';

export const RecruitersListingContainer = () => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div className={styles.dashboardWrappper}>
      {/* {clientLoading ? (
        <div className="text-center ">
          <Spinner height="100vh" width="100" />
        </div>
      ) : (
        <RecruitersListing
          clients={clientData?.data}
          contacts={clientData?.data.client_contacts}
          onProjectAdd={onAdd}
        />
      )} */}
      <RecruitersListing />
    </div>
  );
};
