import React from 'react';
import classNames from 'classnames';
import { MenuButton } from 'react-menu-list';

import styles from './nav.module.scss';
import { DropdownMenu } from '../dropDownMenu/dropDownMenu';

export const Dropdown = ({
  zIndex,
  heading,
  trigger,
  children,
  disabled,
  className,
  toRight,
  buttonComponent,
}) => {
  return (
    <MenuButton
      menuZIndex={zIndex}
      className={classNames('dropdown', styles.navMenu, className)}
      disabled={disabled}
      ButtonComponent={buttonComponent}
      positionOptions={
        toRight ? { position: 'bottom', hAlign: 'right' } : { position: 'bottom', hAlign: 'left' }
      }
      menu={
        <DropdownMenu heading={heading} className={className}>
          {children}
        </DropdownMenu>
      }
    >
      {trigger}
    </MenuButton>
  );
};

Dropdown.defaultProps = {
  zIndex: 12,
  buttonComponent: 'div',
  toRight: false,
};
