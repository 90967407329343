import { testMode } from "constants/baseUrl";
const tokenKey = 'token';
const userEmailKey = 'userEmail';
const userIdKey = 'userId';
const userData = 'userData';

const setToken = (token, userEmail, userId) => {
  localStorage.setItem(tokenKey, token);
  localStorage.setItem(userEmailKey, userEmail);
  localStorage.setItem(userIdKey, userId);
};

const getToken = () => {
  if (testMode) {
    return 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjMsImV4cCI6MTkyNjU3NzIyNX0.pixhPry3e7uh4nN_nXRzaCcfSmA2MJcKhgmOCgnZdl8';
  }
  return localStorage.getItem(tokenKey);
};

const setUserInfo = (data) => {
  localStorage.setItem(userData, JSON.stringify(data));
};

const getUserInfo = (data) => {
  return localStorage.getItem(userData);
};

const clearStorage = () => {
  localStorage.clear();
};

export default {
  setToken,
  getToken,
  setUserInfo,
  getUserInfo,
  clearStorage,
};
