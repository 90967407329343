import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import roles from '../../redux/modules/roles';
import yupSchemaService from '../../services/yupSchemaService';
import { Button } from '../button/button';
import { Fieldset } from '../fieldSet/fieldSet';
import { SelectInput } from '../form/selectInput';
import { TextInput } from '../form/textInput';
import classNames from 'classnames';
import styles from './savedSearchForm.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spinner } from '../loader/loader';
import { BsGlobe as GlobeIcon } from 'react-icons/bs';

export const SavedSearchForm = (props) => {
  const { onSubmit } = props;

  const [loading, setLoading] = useState(false);

  const {
    data: rolesData,
    loading: rolesLoading,
    isError: rolesError,
  } = roles.list().useRoleslistQuery();
  const [parsedRoles, settParsedRoles] = useState([]);

  const form = useForm({
    resolver: yupResolver(yupSchemaService.saveSearchSchema()),
    mode: 'all',
    defaultValues: {},
    shouldUnregister: true,
  });

  useEffect(() => {
    setLoading(true);
    if (rolesData?.data?.length) {
      let tempRoles = [];
      rolesData?.data?.map((role) => {
        tempRoles.push({
          value: role.id,
          label: `Visible to ${role.title}`,
        });
      });
      settParsedRoles(tempRoles);
      setLoading(false);
    }
  }, [rolesData]);

  useEffect(() => {
    if (rolesError) {
      setLoading(false);
    }
  }, [rolesError]);

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  return (
    <div>
      {loading || rolesLoading ? (
        <Spinner />
      ) : (
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className={classNames(styles.formSaveSearch)}
          >
            <Fieldset>
              <TextInput
                name={'title'}
                type={'text'}
                placeHolder="Enter search name"
                className={styles.messageInput}
              />

              <SelectInput
                options={parsedRoles}
                icon={<GlobeIcon />}
                placeholder={'Select project visibility'}
                valueColor="#2FA4FD !important"
                name={'company_role_id'}
              />
              <div className="mt-2">
                <Button type="submit" className="mt-2" submit>
                  Save Search
                </Button>
              </div>
            </Fieldset>
          </form>
        </FormProvider>
      )}
    </div>
  );
};
