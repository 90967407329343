import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import styles from '../clientForm/clientForm.module.scss';
import stylesForms from '../formColumns/forms.module.scss';
import _ from 'lodash';

export const TextInput = (props) => {
  const {
    name,
    label,
    type,
    required,
    placeHolder,
    min,
    fieldDescription,
    icon,
    isFocused,
    maxlength,
    id,
  } = props;
  const {
    register,
    formState: { errors, touchedFields },
    unregister,
  } = useFormContext();

  const originalValue = useWatch({ name: name });
  const [focused, setFocused] = useState(props.isFocused);

  return (
    <div className={`form-group ${label ? `mt-sm-3 mt-2` : `mt-2`}`} style={{ clear: 'both' }}>
      <span
        className={classNames(
          'form-elements',
          stylesForms.inputWrapper,
          { [stylesForms.none]: isFocused },
          { [stylesForms.inputFocused]: focused },
        )}
      >
        <span
          className={classNames('form-elements-wrapper', stylesForms.inputInnerWrapper, {
            [stylesForms.hasIcon]: icon,
          })}
        >
          {label && (
            <label className={stylesForms.label} htmlFor={name}>
              {required && <small className="text-danger">* </small>}
              {label}
            </label>
          )}
          {icon && (
            <label className={classNames(stylesForms.icon)}>
              <span>{icon}</span>
            </label>
          )}

          {fieldDescription && <span className={styles.clientDescription}>{fieldDescription}</span>}
          <span className="form-element">
            {type === 'textarea' ? (
              <textarea
                type={type}
                value={originalValue}
                {...register(name)}
                key={id || name}
                className={`${stylesForms.input} ${
                  errors[name]?.message ? 'form-control border-danger' : 'form-control'
                }`}
                placeholder={placeHolder}
                min={min}
                maxLength={maxlength}
              />
            ) : (
              <input
                type={type}
                value={originalValue}
                {...register(name)}
                key={id || name}
                className={`${stylesForms.input} ${
                  errors[name]?.message ? 'form-control border-danger' : 'form-control'
                }`}
                placeholder={placeHolder}
                min={min}
                maxLength={maxlength}
              />
            )}
          </span>
        </span>
        <small className="text-danger float-start" style={{ clear: 'both' }}>
          {_.get(errors, name)?.message}
        </small>
      </span>
    </div>
  );
};
