import { JobApplicantsTable } from '@components/jobApplicantsTable/jobApplicantsTable';
import projects from '@redux/modules/projects';
import { useParams } from 'react-router-dom';
import { apiUrls } from '@constants/apiurls';
import { useEffect, useState } from 'react';
import dataService from '@services/dataService';
import { cloneDeep, isEmpty } from 'lodash';
import styles from './candidateTable.module.scss';
import { Avatar } from '@components/avatar/avatar';
import classNames from 'classnames';
import ReactTooltip from 'rc-tooltip';
import { BsBagCheckFill as BagIcon } from 'react-icons/bs';
import { MdEmail as EmailIcon } from 'react-icons/md';
import { BiPaperclip as AttachmentIcon } from 'react-icons/bi';
import { Tooltip } from 'components/tooltip/tooltip';
import { Button } from '@components/button/button';
import tableStyles from 'components/jobApplicantsTable/jobApplicantsTable.module.scss';
import { Heading } from '@components/heading/heading';
import { useDispatch } from 'react-redux';
import { handleViewsData } from '../../redux/common/commonSlice';
import { views } from '../../constants/views';

export const CandidateTable = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const {
    data: projectData,
    isLoading: isLoadingProject,
    error: errorProject,
    isSuccess: successProject,
  } = projects.list().useProjectlistQuery(
    { params: { id: params.projectId }, extendedPath: apiUrls.getProject() },
    {
      skip: !params.projectId,
    },
  );

  const getName = (name, avatr, talent) => {
    return (
      <div
        onClick={() => {
          dispatch(
            handleViewsData({
              talent: { user: talent?.user },
              currentView: views.talentProfile,
            }),
          );
        }}
        className="d-flex justify-content-start align-items-center"
      >
        <Avatar
          name={name}
          size={25}
          src={avatr}
          style={{
            zIndex: '2',
            display: 'inline-block',
            float: 'left',
            position: 'relative',
            marginRight: '5px',
          }}
          className={classNames('jobsListingAvatar')}
        />
        <span> {name}</span>
      </div>
    );
  };

  const getAttachment = (icon) => {
    return (
      <>
        {icon && (
          <ReactTooltip
            placement="top"
            overlay={<Tooltip title="Applicant Applied" content="Candidate attachement" />}
          >
            <Button inline square size={'small'} className={'centerAlignButton'}>
              <span
                className={classNames(tableStyles.alert, tableStyles.small, tableStyles.blueIcon)}
              >
                <AttachmentIcon size={20} />
              </span>
            </Button>
          </ReactTooltip>
        )}
      </>
    );
  };
  const getApplied = (icon) => {
    return (
      <>
        {icon && (
          <ReactTooltip
            placement="top"
            overlay={
              <Tooltip title="Applicant Applied" content="Candidate has applied to the job" />
            }
          >
            <Button inline square size={'small'} className={'centerAlignButton'}>
              <span className={classNames(styles.tableIcon, tableStyles.blueIcon)}>
                <BagIcon size={20} />
              </span>
            </Button>
          </ReactTooltip>
        )}
      </>
    );
  };
  const bagIcon = () => {
    return <BagIcon size={20} />;
  };
  const emailIcon = () => {
    return <EmailIcon size={20} />;
  };
  const attachmentIcon = () => {
    return <AttachmentIcon size={20} />;
  };
  const getEmail = (icon) => {
    return (
      <>
        {icon && (
          <ReactTooltip
            placement="top"
            overlay={<Tooltip title="Applicant Applied" content="Email sent to candidate" />}
          >
            <Button inline square size={'small'} className={'centerAlignButton'}>
              <span className={classNames(styles.tableIcon, tableStyles.greenIcon)}>
                <EmailIcon size={20} />
              </span>
            </Button>
          </ReactTooltip>
        )}
      </>
    );
  };
  const [parsedCandidatesTable, setparsedCandidatesTable] = useState();
  const getAlikeAttributes = (attribute) => <span className={styles.greyText}>{attribute}</span>;
  useEffect(() => {
    if (successProject) {
      const tableData = dataService.candidatesTable(
        projectData?.data,
        getAlikeAttributes,
        getApplied,
        getAttachment,
        getEmail,
        getName,
        bagIcon,
        emailIcon,
        attachmentIcon,
      );
      setparsedCandidatesTable(tableData);
    }
  }, [successProject]);

  return (
    <>
      {successProject && !isEmpty(parsedCandidatesTable) && (
        <div className={styles.candidateTable}>
          <Heading size="large">Project Candidates</Heading>
          <p className={styles.subheading}>
            {projectData?.data.projects_pipeline_stages_users.length} Candidates
          </p>
          <JobApplicantsTable
            expanded
            data={parsedCandidatesTable}
            pipelineData={projectData?.data?.pipeline?.pipeline_stages}
            isActionbar={true}
          />
        </div>
      )}

      {/* {coverLetterOpen && (
        <OverlayContainer
          closeOverlay={() =>
            setState((prevState) => ({
              ...prevState,
              coverLetterOpen: !prevState.coverLetterOpen,
            }))
          }
          talentName={state.tableApplicantCoverData.talentName}
          talentJobTitle={state.tableApplicantCoverData.talentJobTitle}
          talentAvatar={state.tableApplicantCoverData.talentAvatar}
          currentView={views.coverLetter}
          talentCoverLetter={coverLetterOpen}
        />
      )} */}
    </>
  );
};
