import classNames from 'classnames';
import styles from '@components/jobForm/jobForm.module.scss';
import stylesJob from '@components/jobIndexCard/JobForm.module.scss';
import { apiUrls } from '@constants/apiurls';
import { useState } from 'react';
import { useEffect } from 'react';
import { JobForm } from '@components/jobForm/jobForm';
import { Heading } from '@components/heading/heading';
import { Header } from '@components/header/header';
import clients from '@redux/modules/clients';
import yupSchemaService from '@services/yupSchemaService';
import dataService from 'services/dataService';
import { fetchAutoCompleteData } from '@services/autoCompleteService';
import jobs from '@redux/modules/jobs';
import _ from 'lodash';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { routesPath } from 'constants/routesPath';
import { Spinner } from '@components/loader/loader';
import dayjs from 'dayjs';
import { JobHeader } from '@components/jobHeader/jobHeader';
import { useJobConatiner } from '../jobContainer/jobContainer';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { handleJobData, updateWizardStep } from '../../redux/common/commonSlice';
import { isEmpty } from 'lodash';

export const JobsFormContainer = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const { form } = useJobConatiner();
  const jobsData = useSelector((state) => state.commonReducer.jobData);

  const [state, setState] = useState({
    colors: [],
    employmentTypes: [],
    positionLevels: [],
    preferredQualifications: [],
    languages: [],
    membershipGrades: [],
    images: [],
    durations: [],
    clientData: null,
    city: '',
    state: '',
    country_id: '',

    loading: false,
  });

  const {
    data: clientsList,
    isLoading: isLoadingClient,
    error: errorClient,
    isSuccess: successClient,
  } = clients.list().useClientslistQuery({ params: '', extendedPath: apiUrls.getClientListing() });

  const {
    data: jobData,
    error: jobError,
    isLoading: jobLoading,
  } = jobs.list().useJobdetailQuery(
    {
      params: { id: params.jobId },
      extendedPath: apiUrls.getJob(),
    },
    { skip: !params.jobId, refetchOnFocus: true },
  );

  const [updateJob, jobUpdateData] = jobs.form().useJobupdateMutation();

  const [createJob, createJobData] = jobs.form().useJobcreateMutation();

  useEffect(() => {
    getInitailData();
    dispatch(
      updateWizardStep({
        step: 0,
      }),
    );

    // return () => {
    //   dispatch(handleJobData({ state: state, job: form.getValues(),formValues:form.getValues() }));
    // };
  }, []);

  useEffect(() => {
    if (jobData?.data) {
      setState((prevState) => ({
        ...prevState,
        country_id: jobData?.data?.job?.country_id,
        city: jobData?.data?.job?.city,
        state: jobData?.data?.job?.city,
        images: jobData?.data?.job?.job_images || [],
      }));
      if (!jobsData?.formValues) {
        form.reset(dataService.parseFormDataForJobEdit(jobData?.data?.job));
        return;
      }
      form.reset(JSON.parse(localStorage.getItem('jobFormVlaues')));
    }
  }, [jobData]);

  useEffect(() => {
    if (jobUpdateData.isSuccess) {
      toast.success('Job Updated Successfully!');
      navigate(routesPath.jobs, { replace: false });
    }
    if (jobUpdateData.isError) {
      toast.error('Failed To Update Job!');
    }
  }, [jobUpdateData]);

  useEffect(() => {
    if (clientsList) {
      let result = dataService.autoCompletionParser('clients', _.cloneDeep(clientsList?.data));
      setState((prevState) => ({ ...prevState, clientData: result }));
    }
  }, [clientsList]);

  const getInitailData = async () => {
    const initailDataList = [
      { url: apiUrls.getColors() },
      { url: apiUrls.getEmploymentTypes() },
      { url: apiUrls.getPositionLevels() },
      { url: apiUrls.getQualifications() },
      { type: 'language', url: apiUrls.getLanguages() },
      { url: apiUrls.getMemberShipGrades() },
      { type: 'duration', url: apiUrls.getJobDuration() },
    ];
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const promises = initailDataList.map((data) => fetchAutoCompleteData(data.type, data.url));
      const response = await Promise.all(promises);

      setState((prevState) => ({
        ...prevState,
        colors: response[0],
        employmentTypes: response[1],
        positionLevels: response[2],
        preferredQualifications: response[3],
        languages: response[4],
        membershipGrades: response[5],
        durations: response[6],
      }));
      setState((prevState) => ({ ...prevState, loading: false }));
      if (jobData) {
        form.reset(dataService.parseFormDataForJobEdit(jobData?.data?.job)) || {};
        return;
      }
      form.reset(JSON.parse(localStorage.getItem('jobFormVlaues')));
      dispatch(handleJobData({ state: state }));
    } catch (err) {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  // useEffect(() => {
  //   if (!isEmpty(form.getValues())) {
  //     dispatch(
  //       handleJobData({ state: state, job: jobData?.data?.job, formValues: form.getValues() }),
  //     );
  //   }
  // }, [form.getValues()]);

  useEffect(() => {
    if (createJobData?.isSuccess) {
      toast.success('Job Successfully Created!');
      navigate(routesPath.jobs, { replace: false });
    }
    if (createJobData?.error) {
      toast.error('Failed To Post Job!');
    }
  }, [createJobData]);

  const getRedner = useCallback(() => {
    return (
      <div>
        <div className={styles.p20}>
          <div className={classNames(styles.sectionLabel)}>
            <Heading size="large">{'Tell us about your job'}</Heading>
          </div>
        </div>
        {state.loading || jobLoading ? (
          <div className="text-center">
            <Spinner height="150" width="150" />
          </div>
        ) : (
          <div className={classNames('px-3', 'px-xs-0')}>
            <JobForm
              colorValue={state?.colors}
              acronymValue={state?.acronymValue}
              colors={state?.colors}
              initialValues={state}
              clients={state?.clientData}
              employmentTypes={state?.employmentTypes}
              positionLevels={state.positionLevels}
              state={state}
              yupSchema={yupSchemaService.jobCreationSchema()}
              jobData={jobData?.data?.job}
              preferredQualifications={state.preferredQualifications}
              languageOptions={state.languages}
              membershipGrades={state.membershipGrades}
              setState={setState}
              isEditing={params.jobId}
              durations={state.durations}
              loading={jobUpdateData?.isLoading || createJobData?.isLoading}
            />
          </div>
        )}
      </div>
    );
  }, [state, form.getValues(), jobLoading, params?.jobId, createJobData]);

  return getRedner();
  //   </div>
  // </div>
};
