import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { MdClose as CloseIcon } from 'react-icons/md';
import yupSchemaService from '../../services/yupSchemaService';
import { Button } from '../button/button';
import { PipelineForm } from '../pipelineForm/pipelineForm';
import styles from './pipelineSettingsCard.module.scss';

export const PipelineSettingsCard = (props) => {
  const {
    pipeline,
    createPipeline,
    updatePipeline,
    pipelineUpdateData,
    deleteStage,
    pipelineDeleteData,
    formMode,
    stageDeleteData,
    pipelineDataLoading,
    deletePipeline,
  } = props;

  const [editId, setEditId] = useState(null);

  useEffect(() => {
    if (editId) {
      if (pipelineUpdateData?.isSuccess) {
        toast.success('Pipeline Updated Successfully!');
        setEditId(null);
      }
      if (pipelineUpdateData?.error) {
        toast.error('Failed To Update Pipeline!');
      }
    }
  }, [pipelineUpdateData]);

  useEffect(() => {
    if (editId) {
      if (pipelineDeleteData?.isSuccess) {
        toast.success('Pipeline Deleted Successfully!');
        setEditId(null);
      }
      if (pipelineDeleteData?.error) {
        toast.error('Failed To Delete Pipeline!');
      }
    }
  }, [pipelineDeleteData]);

  return (
    <>
      {!editId ? (
        <div className={styles.pipelineCard}>
          <h5 className={styles.title}>{pipeline?.title}</h5>
          {pipeline?.pipeline_stages.map((stage, i, row) => (
            <small key={i} className={styles.dividerTextSmall}>
              {stage.title}
              {i + 1 !== row.length ? ' - ' : ''}
            </small>
          ))}

          {pipeline?.id === editId ? (
            <CloseIcon onClick={() => setEditId(null)} className={styles.pipebtnClose} />
          ) : (
            <button
              type="button"
              onClick={() => {
                setEditId(pipeline?.id);
              }}
              className={styles.btnEdit}
            >
              Edit
            </button>
          )}
        </div>
      ) : (
        <div className={`${styles.pipelineForm} ${styles.show}`}>
          <PipelineForm
            formMode={formMode}
            createPipeline={createPipeline}
            pipelineData={pipeline}
            loading={pipelineDataLoading || pipelineDeleteData?.isLoading}
            yupSchema={yupSchemaService.createPipeline()}
            deleteSuccess={pipelineDeleteData?.isSuccess}
            isEdit={true}
            updatePipeline={updatePipeline}
            pipelineUpdateData={pipelineUpdateData?.data}
            deleteStage={deleteStage}
            stageDeleteData={stageDeleteData?.data}
            formOpenHandler={() => setEditId(false)}
          >
            <Button
              type="button"
              className={'mb-4'}
              cancel
              onClick={() =>
                deletePipeline({
                  body: {
                    id: pipeline.id,
                  },
                })
              }
            >
              Delete pipeline template
            </Button>
          </PipelineForm>
        </div>
      )}
    </>
  );
};
