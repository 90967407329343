import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoundButton } from '../../components/roundButton/roundButton';
import { SearchFiltersSwitch } from '../../components/searchFiltersSwitch/searchFiltersSwitch';
import { SearchForm } from '../../components/searchForm/searchForm';
import styles from '../../components/searchForm/searchForm.module.scss';
import { views } from '../../constants/views';
import { handleViewsData } from '../../redux/common/commonSlice';

export const CandidatesSearchContainer = () => {
  const dispatch = useDispatch();
  const currentUIState = useSelector((state) => state.commonReducer.viewsData);

  const [isAppear, setIsAppear] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(handleViewsData(null));
    };
  }, []);

  return (
    <>
      <div className="mainPanelRight">
        {/* {!isEmpty(currentUIState) && ( */}
        {isAppear && (
          <div
            style={
              currentUIState?.currentView === views.filtersForm
                ? { position: 'fixed', marginTop: '53px', left: '324px', zIndex: '11' }
                : { position: 'fixed', marginTop: '53px', left: '44px', zIndex: '11' }
            }
          >
            <SearchFiltersSwitch
              size={'large'}
              onToggle={() => {
                currentUIState?.currentView !== views.filtersForm
                  ? dispatch(handleViewsData(views.filtersForm))
                  : dispatch(handleViewsData(null));
              }}
              onClick={() =>
                dispatch(
                  handleViewsData(
                    currentUIState?.currentView === views.filtersForm
                      ? null
                      : { currentView: views.filtersForm },
                  ),
                )
              }
              isOpen={!(currentUIState?.currentView === views.filtersForm)}
            />
          </div>
        )}

        {/* // )} */}
        <div className={isEmpty(currentUIState) && 'mt-5 pt-5'}>
          <div className="mt-5 pt-5">
            <SearchForm setIsAppear={setIsAppear} />
          </div>
        </div>
      </div>
    </>
  );
};
