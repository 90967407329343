import React from 'react';
import classNames from 'classnames';
import { MenuList } from 'react-menu-list';

import styles from '../dropDown/nav.module.scss';
import { Divider } from '../divider/divider';

export const DropdownMenu = ({ heading, children, className }) => {
  return (
    <div
      className={classNames(
        'dropdown',
        styles.menuDropdown,
        { [styles.hasHeading]: heading },
        className,
      )}
    >
      <MenuList className={className}>
        {heading && <Divider>{heading}</Divider>}
        {children}
      </MenuList>
    </div>
  );
};
