import React from 'react';
import classNames from 'classnames';
import { size, isInteger, isArray } from 'lodash';

import styles from './labeled.module.scss';

const Counter = ({ children }) => (
  <em className={'labeled-count' + ' ' + styles.count}>{children}</em>
);

export const Labeled = ({ children, toCount, counted, className }) => (
  <span className={`labeled ${styles.labeled} ${className ? className : ''}`}>
    <span className={`labeled-title ${styles.title}`}>{children}</span>
    {isArray(toCount) && <Counter>{size(toCount)}</Counter>}
    {isInteger(counted) && <Counter>{counted}</Counter>}
  </span>
);

Labeled.defaultProps = {
  toCount: false,
  counted: false,
};
