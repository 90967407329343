import { routesPath } from './routesPath';
export const wizardViews = {
  client: (id) => [
    {
      step: 0,
      id: id,
      label: !id ? 'Create Client Profile' : 'Edit Client Profile',
      routesPath: !id ? routesPath.clientFormPage : routesPath.editClient(id),
    },
    {
      step: 1,
      id: id,
      label: 'Add primary contacts',
      routesPath: routesPath.addContacts(id),
    },
    {
      step: 2,
      id: id,
      label: !id ? 'Add Projects' : 'Add / Remove Projects',
      routesPath: routesPath.addProjects(id),
    },
  ],
  job: (id) => [
    {
      step: 0,
      id: id,
      label: 'Job Details',
      routesPath: -1,
    },
    {
      step: 1,
      id: id,
      label: 'Publish   ',
      routesPath: id ? routesPath.publishOldJob(id) : routesPath.publishJob(),
    },
  ],
};
