import React, { useState } from 'react';
import classNames from 'classnames';
import { Divider } from '../divider/divider';
import { Labeled } from '../labeled/labeled';

import { MdChevronLeft as ChevronLeftIcon } from 'react-icons/md';

import styles from './section.module.scss';

export const Section = (props) => {
  const {
    content,
    title,
    contentClassName,
    count,
    isToggleable,
    children,
    action,
    headingSize,
    ...rest
  } = props;

  const [contentVisible, setContentVisible] = useState(props.contentVisible);

  const toggleContent = () => {
    if (isToggleable) {
      setContentVisible(!contentVisible);
    }
  };

  return (
    <div
      {...rest}
      className={classNames(styles.talentSection, { [styles.contentVisible]: contentVisible })}
    >
      {content ? (
        content
      ) : (
        <>
          <Divider
            size={headingSize}
            onClick={() => toggleContent()}
            className={classNames({ [styles.isToggleable]: isToggleable })}
          >
            <Labeled counted={count}>{title}</Labeled>
            {isToggleable && (
              <ChevronLeftIcon
                size={15}
                className={classNames(styles.toggleArrow, {
                  [styles.isOpen]: !contentVisible,
                })}
              />
            )}
          </Divider>
          {action && <div className={styles.sectionAction}>{action}</div>}
        </>
      )}
      <div className={classNames({ [styles.invisibleContent]: !contentVisible }, contentClassName)}>
        {children}
      </div>
    </div>
  );
};

Section.defaultProps = {
  count: false,
  isToggleable: false,
  contentVisible: true,
};
