import React from 'react';
import classNames from 'classnames';
import { isString } from 'lodash';

import { Heading } from '../heading/heading';
import styles from './pageSection.module.scss';

export const PageSection = ({ action, search, title, subtitle, children, icon, className }) => (
  <div className={classNames(styles.section, className)}>
    <div className={styles.sectionHeader}>
      <div
        className={classNames(styles.sectionHeaderWrapper, {
          [styles.sectionHeaderTitle]: isString(title),
        })}
      >
        {icon && <div className={styles.iconWrapper}>{React.cloneElement(icon, { size: 20 })}</div>}
        {isString(title) ? <Heading size="large">{title}</Heading> : title}

        {subtitle && (
          <Heading size="small" className={styles.subtitle}>
            {subtitle}
          </Heading>
        )}
      </div>
      <div className={styles.actions}>
        {search && <div className={styles.sectionSearch}>{search}</div>}
        {action && <div className={styles.sectionAction}>{action}</div>}
      </div>
    </div>
    <div className={classNames('page-section', styles.sectionWrapper)}>{children}</div>
  </div>
);
