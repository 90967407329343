import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import styles from '../clientForm/clientForm.module.scss';
import stylesForms from '../formColumns/forms.module.scss';

export const ColorInput = (props) => {
  const circleStyle = (bg) => ({
    display: 'inline-block',
    position: 'relative',
    backgroundColor: '#' + bg,
    borderRadius: '50%',
    width: 28,
    height: 28,
    cursor: 'pointer',
  });
  const checkmark = {
    display: 'inline-block',
    transform: 'rotate(45deg)',
    height: '17px',
    width: '7px',
    //marginLeft: "60%",
    borderBottom: '2px solid white',
    borderRight: '2px solid white',
    position: 'absolute',
    top: '3.5px',
    left: '10px',
  };

  const {
    id,
    input,
    meta,
    name,
    label,
    type,
    required,
    placeHolder,
    min,
    fieldDescription,
    icon,
    isFocused,
    colorsOptions,
  } = props;

  const {
    register,
    formState: { errors, touchedFields },
    unregister,
    setValue,
  } = useFormContext();

  const originalValue = useWatch({ name: name });

  const handleColorChange = (color) => {
    setValue(name, color.id, { shouldValidate: true });
  };

  return (
    <>
      <div
        className={classNames(
          'form-group',
          { ['mt-4']: label, [`mt-2`]: !label },
          'd-flex gap-2 flex-wrap',
          stylesForms.jobColors,
        )}
        style={{ clear: 'both' }}
      >
        <input {...register(name)} className="d-none" value={originalValue} />
        {colorsOptions &&
          colorsOptions.map((color, i) => (
            <div key={i} onClick={() => handleColorChange(color)} style={circleStyle(color.hex)}>
              <div style={originalValue === color.id ? checkmark : {}}></div>
            </div>
          ))}
      </div>
      <small className="text-danger px-3 float-start" style={{ clear: 'both' }}>
        {errors[name]?.message}
      </small>
    </>
  );
};
