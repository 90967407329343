import { Heading } from 'components/heading/heading';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { MdAdd as AddIcon } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { routesPath } from '../../constants/routesPath';
import pipeline from '../../redux/modules/pipeline';
import stages from '../../redux/modules/stages';
import yupSchemaService from '../../services/yupSchemaService';
import { Button } from '../button/button';
import { Spinner } from '../loader/loader';
import { PipelineForm } from '../pipelineForm/pipelineForm';
import { PipelineSettingsCard } from '../pipelineSettingsCard/pipelineSettingsCard';
import styles from './pipelineTemplates.module.scss';

export const PipelineTemplates = (props) => {
  const { pipelines } = props;
  const params = useParams();
  const navigate = useNavigate();
  const [formMode, setFormMode] = useState(null);

  const [createPipeline, pipelineCreateData] = pipeline.form().usePipelinecreateMutation();

  const [deletePipeline, pipelineDeleteData] = pipeline.delete().usePipelinedeleteMutation();

  const [updatePipeline, pipelineUpdateData] = pipeline.form().usePipelineupdateMutation();

  const [deleteStage, stageDeleteData] = stages.delete().useStagedeleteMutation();

  const [state, setState] = useState({
    showPipelineForm: false,
  });

  useEffect(() => {
    if (pipelineCreateData.isSuccess) {
      toast.success('Pipeline Created Successfully!');
      setFormMode(false);
    }
    if (pipelineCreateData.error) {
      toast.error('Failed To Create Pipeline!');
    }
  }, [pipelineCreateData]);

  useEffect(() => {
    if (stageDeleteData?.isSuccess) {
      toast.success('Stage Deleted Successfully!');
    }
    if (stageDeleteData?.error) {
      toast.error('Failed To Delete Stage!');
    }
  }, [stageDeleteData]);

  return (
    <>
      <Heading size={'large'}>Pipeline Templates</Heading>
      <p>
        Pipeline templates consist of different pipeline stages. These stages represent the
        recruitment journey / flow in your recruitment process. Define stages to match your
        recruitment process. You can definite as many templates as you like for different
        recruitment journeys.
      </p>
      {pipelines ? (
        pipelines.map((pipeline, i) => (
          <PipelineSettingsCard
            formMode={formMode}
            createPipeline={createPipeline}
            yupSchema={yupSchemaService.createPipeline()}
            deleteSuccess={pipelineDeleteData?.isSuccess}
            isEdit={params?.pipelineId}
            updatePipeline={updatePipeline}
            pipelineUpdateData={pipelineUpdateData}
            deleteStage={deleteStage}
            stageDeleteData={stageDeleteData?.data}
            pipeline={pipeline}
            editHandler={() => navigate(routesPath.pipelineEdit(pipeline?.id), { replace: false })}
            key={i}
            setState={setState}
            deletePipeline={deletePipeline}
            pipelineDeleteData={pipelineDeleteData}
          />
        ))
      ) : (
        <Spinner height={'100'} width={'100'} />
      )}
      {state?.showPipelineForm ? (
        <div className={`${styles.pipelineForm} ${styles.show} my-3`}>
          <PipelineForm
            formMode={formMode}
            createPipeline={createPipeline}
            pipelineData={{}}
            loading={pipelineDeleteData?.isLoading}
            yupSchema={yupSchemaService.createPipeline()}
            deleteSuccess={pipelineDeleteData?.isSuccess}
            isEdit={params?.pipelineId}
            updatePipeline={updatePipeline}
            pipelineUpdateData={pipelineUpdateData?.data}
            deleteStage={deleteStage}
            stageDeleteData={stageDeleteData?.data}
            formOpenHandler={() =>
              setState((prevState) => ({ ...prevState, showPipelineForm: false }))
            }
          />
        </div>
      ) : (
        <Button
          type="submit"
          id="newPipelineTemplateButton"
          onClick={() => setState((prevState) => ({ ...prevState, showPipelineForm: true }))}
          className={'addPipelineButton'}
        >
          <AddIcon />
          Add new pipeline template
        </Button>
      )}
    </>
  );
};
