import { PipelineTemplates } from 'components/pipelineTemplates/pipelineTemplates';
import { apiUrls } from 'constants/apiurls';
import React from 'react';
import pipeline from 'redux/modules/pipeline';

export const PipelineTemplateContainer = () => {
  const {
    data: pipelinesData,
    isLoading: pipelinesDataLoading,
    error: pipelinesDataError,
    isSuccess: pipelinesDataSuccess,
  } = pipeline.list().usePipelinelistQuery(
    { params: '', extendedPath: apiUrls.getPipelines() },
    {
      // skip: activeTabIndex !== 1,
    },
  );
  return (
    <>
      <PipelineTemplates pipelines={pipelinesData?.data} />
    </>
  );
};
