import { useEffect } from 'react';
import classNames from 'classnames';
import { useFormContext, useWatch } from 'react-hook-form';
import styles from '../formColumns/forms.module.scss';
import membershipStyles from './memberShipGradesCheckBox.module.scss';

export const MemberShipGradesCheckBox = (props) => {
  const { options, type, name, label, description, error, disabled, className, onChange, ...rest } =
    props;

  const {
    register,
    formState: { errors, touchedFields },
    unregister,
    setValue,
  } = useFormContext();
  const originalValue = useWatch({ name: name });

  const changeHandler = (value) => {
    setValue(name, value, { shouldValidate: true });
    onChange && onChange(name, value);
  };

  return (
    <span className={classNames('form-elements', styles.inputWrapper, className)}>
      {label && (
        <label htmlFor={name} className={classNames('element-label', styles.label)}>
          {label}
          {description && (
            <p className={classNames('element-description', styles.description)}>{description}</p>
          )}
        </label>
      )}
      <span className={classNames('form-elements-wrapper', styles.inputInnerWrapper)}>
        <div className={classNames(membershipStyles.imageCheckboxWrapper)}>
          {options &&
            options.map((option) => {
              return (
                <div className={classNames(membershipStyles.imageCheckbox)}>
                  <input
                    className={classNames(membershipStyles.imageCheckboxinput)}
                    type={type}
                    value={option.id}
                    {...register(name)}
                    id={name + option.id}
                    onChange={() => changeHandler(option.id)}
                  />
                  <label
                    htmlFor={name + option.id}
                    key={`${option.id}`}
                    className={classNames('form-element', styles.inputCheckboxLabel, {
                      [styles.checked]: originalValue == option.id,
                    })}
                  >
                    <div>
                      <span
                        className={classNames(membershipStyles.imgCheckbox)}
                        style={{ backgroundImage: `url('${option.icon}')` }}
                      />
                      <span className={classNames(membershipStyles.imgCheckboxLabel)}>
                        {option.title}
                      </span>
                    </div>
                    {option.description && (
                      <p
                        className={classNames(styles.fieldLabelDescription, 'd-none', 'd-md-block')}
                      >
                        {option.description}
                      </p>
                    )}
                  </label>
                </div>
              );
            })}
        </div>
      </span>
      {errors[name]?.message && (
        <span className={classNames(styles.fieldError, 'form-error-element')}>
          {errors[name]?.message}
        </span>
      )}
    </span>
  );
};
