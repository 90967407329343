import React from 'react';

import { Section } from 'components/section/section';
import { TalentWorkHistory } from './talentWorkHistory';
import { TalentEducation } from './talentEducation';
// import { TalentProjects } from './talentProjects';
import { TalentJobs } from './talentJobs';
import { TalentTags } from './talentTags';

import { ContactDetails } from './contactDetails';
import { isEmpty } from 'lodash';

import styles from './talent.module.scss';

export const TalentProfile = (props) => {
  const { talent, userTags, onTagAdd, onTagRemove, isLoading, userId, getTags } = props;

  const getTalentWorkHistory = () => {
    let history = [];

    // history.push({
    //   job_title: talent?.user?.current_membership_grade?.title,
    //   company: talent?.user?.company?.name,
    // isCurrent: true,
    // });
    talent?.user?.experiences?.map((experience) => {
      history.push({
        start_year: experience?.from_date?.split('-')[0],
        end_year: experience?.to_date?.split('-')[0],
        job_title: experience?.title,
        company: experience?.company_name,
        salary: experience?.salary,
        currency: experience?.country?.currency,
        leaveDays: experience?.leave || 'Friday',
        isCurrent: true,
      });
    });
    return history;
  };

  return (
    <>
      {talent && (
        <div className={styles.talentSidebarWrapper}>
          <Section title="Tags" headingSize="large" isToggleable>
            <TalentTags
              tags={userTags}
              onAdd={onTagAdd}
              onRemove={onTagRemove}
              userId={userId || 1}
            />
          </Section>
          {getTalentWorkHistory() && (
            <Section title="Work Experience" headingSize="large" isToggleable>
              <TalentWorkHistory history={getTalentWorkHistory()} />
            </Section>
          )}
          {/* {talent.education && ( */}
          <Section title="Education" headingSize="large" isToggleable>
            <TalentEducation education={talent.education} />
          </Section>
          {/* )} */}

          <Section title="Contact details" headingSize="large" isToggleable>
            <ContactDetails
              socials={[
                { type: 'Facebook', profile_link: talent?.user?.facebook },
                { type: 'Twitter', profile_link: talent?.user?.twitter },
                // { type: 'Google', profile_link: talent?.user?.google },
                { type: 'Instagram', profile_link: talent?.user?.instagram },
                { type: 'LinkedIn', profile_link: talent?.user?.linkedin },
              ]}
              skype={talent?.user?.skype}
              phones={[{ type: 'Work', number: talent?.user?.contact_number }]}
              email={talent?.user?.email}
              address={{
                address_line_1: talent?.user?.address_line_1,
                address_line_2: talent?.user?.address_line_2,
                address_province: talent?.user?.address_province,
                address_zip_code: talent?.user?.address_zip_code,
                city: talent?.user?.city,
                country: talent?.user?.country,
              }}
            />
          </Section>
          {/* {talent.membership_grades && ( */}
          <Section title="Membership Grades" headingSize="large" isToggleable>
            <TalentJobs content={null} />
          </Section>
          {/* )} */}
          {talent?.user?.profile_summary && (
            <Section title="Job Aspirations" headingSize="large" isToggleable>
              <TalentJobs content={talent?.user?.profile_summary} />
            </Section>
          )}
          {!isEmpty(talent.projects) && (
            <Section title="Added to projects" headingSize="large" isToggleable>
              {/* <TalentProjects projects={talent.projects} /> */}
            </Section>
          )}
        </div>
      )}
    </>
  );
};
