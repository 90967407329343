import React from 'react';
import classNames from 'classnames';
// import { Field, change } from 'redux-form';
import { map } from 'lodash';
import { GenericInput } from 'components/genericInput/genericInput';
// import { SeniorityLevels } from 'components/seniorityLevels/seniorityLevels';
import { Button } from 'components/button/button';
import { Form } from 'components/form/form';
import { Fieldset } from 'components/fieldSet/fieldSet';
import styles from './noteForm.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import yupSchemaService from '../../services/yupSchemaService';
import { TextInput } from '../form/textInput';
import { ImageUpload } from '../imageUpload/imageUpload';
import { views } from '../../constants/views';

// @ Wasif
// import FileUpload from 'components/FileUpload';
// import { openSidebar, sidebarViews } from 'redux/modules/ui';
// import { ids as forms } from 'redux/modules/forms';
// import apiService from '../services/apiService';
// import apiUrls from '../apiUrls';
// import { reset } from 'redux-form';

export const NoteForm = (props) => {
  const {
    userId,
    isOpen,
    isSubmitting,
    onSubmit,
    onCancel,
    onOpen,
    onFileAdd,
    onFileRemove,
    getNotes,
    onSubmitSuccess,
    seniorityLevels,
    setTalentNoteFormFieldValue,
    notes,
    state,
    setState,
    handleSubmitForm,
    initialValues,
    form,
  } = props;

  return (
    <div className={classNames(styles.formAddNotesWrapper)}>
      <Form
        form={form}
        onSubmit={form.handleSubmit(handleSubmitForm)}
        initialValues={initialValues}
        className={classNames(styles.formAddNotes)}
      >
        {props && state.open ? (
          <>
            <Fieldset className={styles.notesFormWrapper}>
              <div className={styles.fileInput}>
                <TextInput
                  name="note"
                  placeHolder="Type your note here..."
                  rows="3"
                  required={true}
                />
              </div>
              <ImageUpload
                state={state}
                setState={setState}
                currentView={views.talentNotes}
                className={styles.messageInput}
              />
            </Fieldset>
            <Button type="submit" submit>
              Submit
            </Button>
            <Button
              cancel
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  open: !prevState?.open,
                }))
              }
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                open: true,
              }))
            }
            submit
          >
            New note or attachment
          </Button>
        )}
      </Form>
    </div>
  );
};
