import styles from '../formColumns/forms.module.scss';
import classNames from 'classnames';
import { ColorInput } from '../form/colorInput';
export const Color = (props) => {
  const {
    label,
    meta,
    input,
    className,
    colors,
    description,
    circleStyle,
    name,
    isFetchingColors,
    ...rest
  } = props;
  const error = meta && meta.error;
  const id = `select-${label}`;

  return (
    <span
      className={classNames(
        // 'form-elements',
        // 'form-select',
        styles.selectWrapper,
        styles.colorWrapper,
        { [styles.fieldHasError]: error },
      )}
    >
      <span className={classNames()}>
        {label && (
          <label htmlFor={(id && id) || name} className={classNames('element-label', styles.label)}>
            {label}
            {description && (
              <p className={classNames('element-description', styles.description)}>{description}</p>
            )}
          </label>
        )}

        <span className={classNames('form-element', styles.selectInnerWrapper)}>
          {isFetchingColors ? (
            <p>Loading colors...</p>
          ) : (
            <>
              <div style={circleStyle}>
                <ColorInput colorsOptions={colors} name={name} />
              </div>
            </>
          )}
        </span>
      </span>
    </span>
  );
};
