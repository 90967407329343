import { IoMdMailOpen as MailIcon } from 'react-icons/io';
import { AiFillFlag as FlagIcon } from 'react-icons/ai';
import { FaTimes as CloseIcon } from 'react-icons/fa';
import { FaChevronDown as DownIcon } from 'react-icons/fa';
import { MdChevronLeft as ChevronLeftIcon } from 'react-icons/md';
import { AiOutlineUserAdd as UserAddIcon } from 'react-icons/ai';
import styles from './navMobile.module.scss';
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { handleNavToggle } from '@redux/common/commonSlice';
import { Button } from '../button/button';
import { NavLink, useNavigate } from 'react-router-dom';
import { routesPath } from '../../constants/routesPath';
import storageService from '../../services/storageService';
import { baseSliceWithTags } from '../../redux/baseSlice';

export const NavbarMobile = (props) => {
  const [contentVisible, setContentVisible] = useState(props.contentVisible);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleContent = () => {
    setContentVisible(!contentVisible);
  };
  const navActive = useSelector((state) => state.commonReducer.navBarToggle);

  useEffect(() => {
    if (navActive) {
      document.body.className = 'overflow-hidden';
    } else {
      document.body.className = '';
    }
  }, [navActive]);

  const authHandler = () => {
    dispatch(baseSliceWithTags.util.resetApiState());
    storageService.clearStorage();

    navigate(routesPath.login, { replace: false });
  };
  return (
    <div>
      <header
        id={styles.mobileHeader}
        className={classNames(styles.visibleMobile, { [styles.isActive]: !navActive })}
        onClick={(e) => {
          dispatch(handleNavToggle(false));
        }}
      >
        <div className={styles.accountOptions}>
          <div className={styles.messages}>
            <a href="#">
              <span className={styles.icon}>
                {/* <i className="zmdi zmdi-email-open"></i> */}
                <MailIcon size={20} />
                <span>2</span>
              </span>
            </a>
          </div>

          <div className={styles.notifications}>
            <a href="#">
              <span className={styles.icon}>
                {/* <i className="zmdi zmdi-flag"></i> */}
                <FlagIcon size={20} />
                <span>1</span>
              </span>
            </a>
          </div>

          <div className={styles.connections}>
            {/* <a href="#">
              <span className={styles.icon}>
                <UserAddIcon size={20} />
              </span>
              <span className={styles.number}>352</span>
            </a> */}
          </div>

          <a onClick={() => dispatch(handleNavToggle(false))} className={styles.close}>
            {/* <i className="zmdi zmdi-close"></i> */}

            <CloseIcon size={20} />
          </a>
        </div>

        <nav>
          <ul>
            <li>
              <NavLink
                className={({ isActive }) => (!isActive ? 'unselected' : styles.activity)}
                to={routesPath.dashboard}
                strict
                exact
              >
                Home <span></span>
              </NavLink>
            </li>
            {/* <li className={styles.multLevel}>
              <a href="#">
                Profile
                <ChevronLeftIcon
                  size={15}
                  className={classNames(styles.toggleArrow, {
                    [styles.isOpen]: !contentVisible,
                  })}
                />
              </a>
              <ul>
                <li>
                  <a href="#">View profile</a>
                </li>
                <li>
                  <a href="#" className={styles.editProfileBtn}>
                    Edit profile
                  </a>
                </li>
                <li>
                  <a href="#">Membership grade</a>
                </li>
                <li>
                  <a href="#">References</a>
                </li>
                <li>
                  <a href="#">Recommendations</a>
                </li>
                <li>
                  <a href="#">Network activity</a>
                </li>
              </ul>
            </li> */}
            {/* <li>
              <a href="#">News</a>
            </li> */}
            <li className={styles.multiLevel}>
              <NavLink
                exact
                className={({ isActive }) => (!isActive ? 'unselected' : styles.activity)}
                to={routesPath.jobs}
              >
                Jobs<span></span>
              </NavLink>
              {/* <ul>
                <li>
                  <a href="#">Find a job</a>
                </li>
                <li>
                  <a href="#">Post a job</a>
                </li>
              </ul> */}
            </li>
            <li className={styles.multiLevel}>
              <NavLink
                onClick={() => {
                  dispatch(handleNavToggle(false));
                }}
                to={routesPath.recruitersList()}
              >
                Recruiters
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                className={({ isActive }) => (!isActive ? 'unselected' : styles.activity)}
                to={routesPath.searchCandidates()}
              >
                Search <span></span>
              </NavLink>
            </li>
            <li className={styles.multiLevel}>
              <NavLink
                exact
                className={({ isActive }) => (!isActive ? 'unselected' : styles.activity)}
                to={routesPath.settings()}
              >
                Settings<span></span>
              </NavLink>
            </li>

            {/* <li>
              <a href="#">Recruitment</a>
            </li>
            <li>
              <a href="#">Help</a>
            </li> */}
          </ul>
        </nav>

        <div className={classNames(styles.profile)}>
          <a href="#">
            <img
              src={JSON.parse(storageService.getUserInfo()).avatar}
              alt="photo"
              className={styles.avatar}
            />
          </a>
          <div className={styles.text}>
            <h6>
              <a href="#">{JSON.parse(storageService.getUserInfo()).full_name}</a>
            </h6>
            <p>{JSON.parse(storageService.getUserInfo()).professional_title}</p>
            <p> {JSON.parse(storageService.getUserInfo()).area}</p>
          </div>
        </div>

        <nav>
          {/* <ul>
            <li>
              <a href="#">Account settings</a>
            </li>
            <li>
              <a href="#">Privacy settings</a>
            </li>
          </ul> */}
        </nav>

        <div className={(styles.btnWrapper, 'mx-3 my-2')}>
          <Button submit onClick={authHandler}>
            {' '}
            Logout
          </Button>
        </div>
      </header>
      {navActive && (
        <div onClick={() => dispatch(handleNavToggle())} className={styles.overlay}></div>
      )}
    </div>
  );
};
