import React from 'react';
import { JobHeader } from 'components/jobHeader/jobHeader';
import classNames from 'classnames';
import styles from 'components/jobIndexCard/JobForm.module.scss';
import { Heading } from 'components/heading/heading';
import { JobApplicantsSearchResults } from 'components/jobApplicantsSearchResults/jobApplicantsSearchResults';
import { JobTalentSearchFilters } from '../../components/jobTalentSearchFilters/jobTalentSearchFilters';
import { useEffect } from 'react';
import { apiUrls } from '../../constants/apiurls';
import { useState } from 'react';
import { fetchAutoCompleteData } from '../../services/autoCompleteService';
import { useLocation, useParams } from 'react-router-dom';
import jobApplicants from '../../redux/modules/jobApplicants';
import { MdFormatListBulleted as ListIcon } from 'react-icons/md';
import { AiOutlineTable as TableIcon } from 'react-icons/ai';
import { BsGrid3X2Gap as GridIcon } from 'react-icons/bs';
import { MdTune as FilterIcon } from 'react-icons/md';
import { useDebounce } from '../../utils/debounce';
import { FormProvider, useForm } from 'react-hook-form';
import yupSchemaService from '../../services/yupSchemaService';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spinner } from '../../components/loader/loader';
import toast from 'react-hot-toast';
import { handleViewsData } from '../../redux/common/commonSlice';
import { useDispatch } from 'react-redux';
import { useRef } from 'react';

export const JobApplicantsContainer = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const prevFilters = useRef();
  const prevSearchKey = useRef();

  const [state, setState] = useState({
    positionLevels: [],
    preferredQualifications: [],
    languages: [],
    membershipGrades: [],
    countries: [],
    loading: false,
    coverLetterOpen: null,
    view: 'cardView',
    filterOpen: false,
    currentFilters: '',
    colors: [],
    tableApplicantCoverData: null,
  });

  const [initialValues] = useState(() => {
    return {};
  });

  const form = useForm({
    resolver: yupResolver(yupSchemaService.clientCreationSchema()),
    mode: 'all',
    defaultValues: initialValues,
    shouldUnregister: true,
  });
  const debouncedSearchKey = useDebounce(form.watch()?.keyword, 200);
  useEffect(() => {
    if (debouncedSearchKey) {
      prevSearchKey.current = state.currentFilters;
    }
    if (state.currentFilters) {
      prevFilters.current = debouncedSearchKey;
    }
  }, [state.currentFilters, debouncedSearchKey]);
  let parameters = {};

  if (state.currentFilters != prevFilters && state.currentFilters) {
    parameters = {
      jobId: params?.jobId + 'filters' + state.currentFilters,
    };
  } else if (debouncedSearchKey !== prevSearchKey && debouncedSearchKey) {
    parameters = {
      jobId: params?.jobId,
      keyword: debouncedSearchKey && debouncedSearchKey,
    };
  } else if (params?.jobId) {
    parameters = {
      jobId: params?.jobId,
    };
  }

  const {
    data: applicantsData,
    isFetching: isApplicantsLoading,
    error: applicantsError,
    isSuccess: applicantsSuccess,
  } = jobApplicants.list().useJobApplicantlistQuery({
    params: parameters,
    extendedPath: apiUrls.jobApplicantsSearch(),
    refetch: true,
  });

  useEffect(() => {
    getInitailData();
  }, []);

  const getInitailData = async () => {
    const initailDataList = [
      { url: apiUrls.getPositionLevels() },
      { url: apiUrls.getQualifications() },
      { type: 'language', url: apiUrls.getLanguages() },
      { url: apiUrls.getMemberShipGrades() },
      { type: 'language', url: apiUrls.getCountries() },
      { url: apiUrls.getColors() },
    ];
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const promises = initailDataList.map((data) => fetchAutoCompleteData(data.type, data.url));
      const response = await Promise.all(promises);

      setState((prevState) => ({
        ...prevState,
        positionLevels: response[0],
        preferredQualifications: response[1],
        languages: response[2],
        membershipGrades: response[3],
        countries: response[4],
        colors: response[5],
      }));
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (err) {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  useEffect(() => {
    if (applicantsError) {
      toast.error('Unexpected Error occured while fetching jobs1');
    }
  }, [applicantsError]);

  useEffect(() => {
    return () => {
      dispatch(handleViewsData(null));
    };
  }, []);

  return (
    <>
      <JobHeader
        step={1}
        job={applicantsData?.data?.results[0]?.result?.job}
        colors={state?.colors}
        jobId={applicantsData?.data?.results[0]?.result?.job_id}
        location={location}
      />
      <div className={classNames(styles.page, styles.applicantsPage)}>
        {/* TITLE */}
        <div className={classNames(styles.p20)}>
          <div className={classNames(styles.sectionLabel, 'd-flex', 'justify-content-between')}>
            <Heading size="large">
              <span style={{ display: 'flex', flexWrap: 'wrap' }}>
                {'Applicants '}
                <div className={styles.applicantsCounter}>
                  {applicantsData?.data?.results && applicantsData?.data?.results.length} people
                  applied to this job
                </div>
                <span style={{ display: 'flex' }}></span>
              </span>
            </Heading>
            <div className={classNames(styles.applicantViewSwitch, 'col-6')}>
              <button
                className={classNames(state.view === 'cardView' && 'active', 'viewBtn')}
                onClick={() => setState((prevState) => ({ ...prevState, view: 'cardView' }))}
              >
                <GridIcon size={20} />
              </button>
              <button
                className={classNames(state.view === 'listView' && 'active', 'viewBtn')}
                onClick={() => setState((prevState) => ({ ...prevState, view: 'listView' }))}
              >
                <ListIcon size={20} />
              </button>
              <button
                className={classNames(state.view === 'tableView' && 'active', 'viewBtn')}
                onClick={() => setState((prevState) => ({ ...prevState, view: 'tableView' }))}
              >
                <TableIcon size={20} />
              </button>
              <button
                onClick={() =>
                  setState((prevState) => ({ ...prevState, filterOpen: !state.filterOpen }))
                }
                className={classNames('viewBtn', 'filterBtn', 'd-md-none')}
              >
                <FilterIcon size={20} />
              </button>
            </div>
          </div>
        </div>
        <div className={classNames('row', 'px-3', 'px-xs-0')}>
          <FormProvider {...form}>
            <JobTalentSearchFilters
              languages={state.languages}
              qualifications={state.preferredQualifications}
              countries={state.countries}
              membershipGrades={state.membershipGrades}
              positionLevels={state.positionLevels}
              currentFilters={state.currentFilters}
              setState={setState}
              handleSubmit={form.handleSubmit}
              jobsData={applicantsData}
              form={form}
              filterOpenMobile={state.filterOpen}
            />
          </FormProvider>
          {isApplicantsLoading ? (
            <Spinner height={50} width={20} />
          ) : (
            <JobApplicantsSearchResults
              results={applicantsData?.data?.results}
              coverLetterOpen={state.coverLetterOpen}
              setState={setState}
              applicantsViewType={state.view}
              state={state}
            />
          )}
        </div>
      </div>
    </>
  );
};
