import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { Avatar } from '../avatar/avatar';
import { Heading } from '../heading/heading';
import styles from './cardTitle.module.scss';

const CardContent = ({ title, subhead, avatar, color, projectCount }) => {
  const avatarSize = (avatar && avatar.size) || 50;
  const marginStyle = avatar ? { marginLeft: `${16}px` } : { marginLeft: `${10}px` };
  // const heightStyle = { lineHeight: subhead ? `${avatarSize / 2}px` : `${avatarSize}px` };

  return (
    <div className={styles.cardContent}>
      {avatar && avatar.icon ? (
        <div
          className={styles.icon}
          style={
            avatarSize && {
              lineHeight: `${avatarSize}px`,
              width: `${avatarSize}px`,
              height: `${avatarSize}px`,
            }
          }
        >
          {avatar.icon}
        </div>
      ) : (
        avatar && (
          <Avatar
            {...avatar}
            color={color}
            name={title}
            size={avatarSize}
            className={styles.avatar}
            style={
              avatarSize && {
                lineHeight: `${avatarSize}px`,
                width: `${avatarSize}px`,
                height: `${avatarSize}px`,
              }
            }
          />
        )
      )}

      <div style={marginStyle} className={styles.cardHeadingPara}>
        <Heading size="small" className={styles.title} style={{ marginBottom: 0 }}>
          {title}
        </Heading>
        {subhead ? (
          !Number.isInteger(subhead) ? (
            <p className={styles.subhead}>{subhead}</p>
          ) : subhead === 1 ? (
            <p className={styles.subheadAdmin}>Admin</p>
          ) : subhead === 2 ? (
            <p className={styles.subheadSenior}>Senior</p>
          ) : (
            subhead === 3 && <p className={styles.subheadJunior}>Junior</p>
          )
        ) : (
          ''
        )}
        {projectCount && <p className={styles.subhead}>{`${projectCount} Projects`}</p>}
      </div>
    </div>
  );
};

export const CardTitle = (props) => {
  const {
    title,
    size,
    subhead,
    avatar,
    link,
    className,
    children,
    isSelected,
    onClick,
    onSelect,
    colors,
    color,
    projectCount,
    ...rest
  } = props;

  return (
    <div
      className={`card-titles ${styles.cardTitleWrapper} ${
        onSelect ? styles.selectable : ''
      } ${className}`}
      {...rest}
    >
      {onSelect && (
        <div className={styles.select}>
          {/* <Input type="checkbox" checked={isSelected} onClick={onSelect} /> */}
        </div>
      )}

      {link || onClick ? (
        <div
          to={link}
          onClick={onClick}
          className={classNames(styles.titleLink, 'p-1', { [styles.hover]: link || onClick })}
        >
          <CardContent color={color} title={title} size={size} subhead={subhead} avatar={avatar} />
        </div>
      ) : (
        <CardContent
          color={color}
          title={title}
          size={size}
          subhead={subhead}
          avatar={avatar}
          projectCount={projectCount}
        />
      )}
      {children}
    </div>
  );
};

CardTitle.defaultProps = {
  size: 'large',
  subhead: false,
};
