import destroy from '../apiService/destroy';
import form from '../apiService/form';
import list from '../apiService/list';

const teams = {};
teams.list = () => list('/rest/teams', 'teams');
teams.form = () => form('/rest/teams', 'teams');
teams.delete = () => destroy('/rest/teams/delete', 'teams');

export default teams;
