import destroy from '../apiService/destroy';
import form from '../apiService/form';
import list from '../apiService/list';

const notes = {};
notes.list = () => list('/rest/notes', 'note');
notes.form = () => form('/rest/notes', 'note');
notes.delete = () => destroy('/rest/notes/delete', 'note');

export default notes;
