import React from 'react';
import classNames from 'classnames';
import { MdSettings as SettingsIcon } from 'react-icons/md';
import { MenuItem } from '../menuItem/menuItem';
import { Dropdown } from '../dropDown/dropDown';
import { CardTitle } from '../cardTitle/cardTitle';

import styles from './baseCard.module.scss';

export const BaseCard = ({
  item,
  size,
  onRemove,
  onEdit,
  children,
  isStandalone,
  onClick,
  color,
  isTeamCard,

  ...rest
}) => {
  return (
    <>
      <div
        className={classNames(
          'base-card',
          { [styles[size]]: size },
          styles.card,
          styles.cardWrapper,
          {
            [styles.isStandalone]: isStandalone,
          },
          {
            [styles.isTeamCard]: isTeamCard,
          },
        )}
      >
        <div
          onClick={(e) => {
            onClick && onClick();
          }}
          className={classNames({ [styles.headerWrapper]: !isTeamCard })}
        >
          <CardTitle color={color} size={size} {...rest} />
        </div>
        {children}
        {(onEdit || onRemove) && (
          <div className={classNames(styles.settingsLinkWrapper)}>
            <Dropdown trigger={<SettingsIcon size={20} />}>
              {onEdit && <MenuItem onClick={() => onEdit(item)}>Edit</MenuItem>}
              {onRemove && <MenuItem onClick={() => onRemove(item)}>Remove</MenuItem>}
            </Dropdown>
          </div>
        )}
      </div>
    </>
  );
};

BaseCard.defaultProps = {
  isStandalone: true,
  size: 'large',
};
