import classNames from 'classnames';

import styles from './overLay.module.scss';

export const OverLay = (props) => {
  const { isOpen, children, fullSize } = props;
  return (
    <>
      {isOpen ? (
        <div className={classNames(styles.overlayWrapper, { [styles.opened]: isOpen })}>
          <div className={styles.titleWrapper}>
            <div className={classNames(styles.overlayContainer, { [styles.fullSize]: fullSize })}>
              <div className={classNames(styles.overlayInner, { [styles.fullSize]: fullSize })}>
                {children}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};
