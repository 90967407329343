import storageService from '../services/storageService';

export const authHeader = () => {
  return {
    common: {
      AuthApi: `Bearer ${storageService.getToken()}`,
      Device: `TMR`,
    },
  };
};

export const deviceHeader = () => {
  return {
    common: {
      Device: `TMR`,
    },
  };
};
