import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../button/button';
import styles from './JobIndexActions.module.scss';
import classNames from 'classnames';
import { JobIndexCardMenu } from '../jobIndexCardMenu/jobIndexCardMenu';
import { routesPath } from '../../constants/routesPath';
// import {pus} from 'lodash'

export const JobIndexActions = (props) => {
  const navigate = useNavigate();
  const { job, jobStatuses, clickHandlers, jobsFilter } = props;

  return (
    <div className={classNames(styles.actionWrapper)}>
      <div className={classNames(styles.actionContent, 'me-2 me-md-3')}>
        <div>
          <Button
            onClick={() => navigate(routesPath.jobEdit(job?.id), { replace: true })}
            link={routesPath.jobEdit(job?.id)}
            regular
            submit
            size={'small'}
            className="jobIndexActionBtn"
          >
            Edit
          </Button>
        </div>
      </div>
      <div className={classNames(styles.actionContent)}>
        <div className={classNames(styles.ellipsisButton)}>
          <JobIndexCardMenu
            jobStatuses={jobStatuses}
            clickHandlers={clickHandlers}
            jobsFilter={jobsFilter}
          />
        </div>
      </div>
    </div>
  );
};
