import { configureStore } from '@reduxjs/toolkit';
import { baseSliceWithTags } from './baseSlice';
import commonReducer from './common/commonSlice';

export default configureStore({
  reducer: {
    [baseSliceWithTags.reducerPath]: baseSliceWithTags.reducer,
    commonReducer: commonReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseSliceWithTags.middleware),
});
