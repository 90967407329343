import React from 'react';
import { NavLink } from 'react-router-dom';

import { MdClose as CloseIcon } from 'react-icons/md';

import styles from './tag.module.scss';
import classNames from 'classnames';

export const Tag = ({ children, onClick, isDeletable, onDelete, className }) => {
  return (
    <div className={classNames(styles.tagWrapper, className)} onClick={onClick}>
      {children}

      {isDeletable && onDelete && (
        <div
          onClick={(event) => {
            event.stopPropagation();
            onDelete();
          }}
        >
          <CloseIcon size={14} />
        </div>
      )}
    </div>
  );
};
