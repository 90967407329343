import React from 'react';
import { isEmpty, round } from 'lodash';
import classNames from 'classnames';
import styles from './jobApplyStats.module.scss';

export const JobApplyRateStates = (props) => {
  const { views, applied } = props;
  const perc = views && applied && (applied / views) * 100;
  return (
    <>
      {!isEmpty(views) && !isEmpty(applied) && (
        <div className={classNames(styles.col_1_1, styles.statsContainerWrapper)}>
          <div className={classNames(styles.col_1_3, styles.statWrapper)}>
            <div className={classNames(styles.statValue)}>
              <center>{views}</center>
            </div>
            <div className={classNames(styles.statLabel)}>
              <center>{'VIEWS'}</center>
            </div>
          </div>
          <div className={classNames(styles.col_1_3, styles.statWrapper)}>
            <div className={classNames(styles.statValue)}>
              <center>
                {round(perc, 0)}
                {'%'}
              </center>
            </div>
            <div className={classNames(styles.statLabel)}>
              <center>{'APPLY RATE'}</center>
            </div>
          </div>
          <div className={classNames(styles.col_1_3, styles.statWrapper)}>
            <div className={classNames(styles.statValue)}>
              <center>{applied}</center>
            </div>
            <div className={classNames(styles.statLabel)}>
              <center>{'APPLIED'}</center>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
