import React from 'react';
import { map } from 'lodash';

import styles from './forms.module.scss';

export const FormColumns = (props) => {
  const { cols, className, children } = props;

  return (
    <div className={'form-columns' + ' ' + styles.formColumns + ' ' + className ? className : ''}>
      {map(children, (child, key) => {
        return (
          <div className={'form-column' + ' ' + styles[`formColumn${cols}`]} key={key}>
            {child}
          </div>
        );
      })}
    </div>
  );
};
