export const routesPath = {
  dashboard: '/',
  login: '/login',
  editClient: (id) => `/client/${id}/edit`,
  client: `/client`,
  job: () => `/job`,
  clientFormPage: `/client/create`,
  jobs: '/jobs',
  jobsFormPage: `/job/add`,
  jobEdit: (id) => `/job/${id}/edit`,
  publishOldJob: (id) => `/job/${id}/publish`,
  publishJob: () => `/job/publish`,
  jobApplicants: (id) => `/job/${id}/applicants`,
  addProjects: (clientId) => `/client/${clientId}/projects/add`,
  addContacts: (id) => `/client/${id}/contacts/add`,
  clientProjects: `/client-projects`,
  clientContacts: `/client-contacts`,
  getClientProjects: (clientId) => `/client-projects/${clientId}`,
  getClientContacts: (clientId) => `/client-contacts/${clientId}`,
  settings: () => `/settings`,
  pipelineList: () => `/settings/pipelines`,
  pipelineEdit: (id) => `/settings/pipeline/${id}/edit`,
  RolesAndPermissions: `/settings/roles`,
  searchCandidates: () => `/search`,
  project: () => `/project`,
  projectPipeline: (projectId, pipelineId) => `/project/${projectId}/pipeline/${pipelineId}`,
  projectCandidates: (id) => `/project/${id}/candidates`,
  projectTasks: (id) => `/project/${id}/tasks`,
  projectNotes: (id) => `/project/${id}/notes`,
  recruiters: '/recruiters',
  recruitersList: () => '/recruiters/list',
  recruitersAdd: () => '/recruiters/new',
  recruitersEdit: (id) => `/recruiters/edit/${id}`,
  recruitersArchived: () => '/recruiters/archived',
  recruitersTeams: () => '/recruiters/teams',
  updateProject: (clientId, projectId) => `/client/${clientId}/projects/${projectId}`,
};
