import React from 'react';
import { zip, split } from 'lodash';

import styles from './heading.module.scss';

export const Heading = (props) => {
  const { size, style, className, children } = props;
  const headings = {
    large: 'h1',
    medium: 'h2',
    small: 'h3',
    xsmall: 'h4',
  };
  const Head = headings[size];
  return (
    <Head
      key={size}
      className={`${styles.heading} ${styles[props.size]} ${className} `}
      style={style}
    >
      {typeof children === 'string' ? zip(split(children.split('\\n'), [<br />])) : children}
    </Head>
  );
};

Heading.defaultProps = {
  size: 'small',
};
