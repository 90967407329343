import React from 'react';
import { TableWrapper } from 'components/table/table';

export const JobApplicantsTable = (props) => {
  const { data, className, expanded, pipelineData, isActionbar, isSeacrh } = props;
  return (
    <>
      <TableWrapper
        tableClass={'jobApplicantsTable'}
        data={data?.tableData}
        columns={data?.tableHeader}
        tableStriped={true}
        className={className}
        pipelineData={pipelineData}
        // expanded
        isActionbar={isActionbar}
        isSeacrh={isSeacrh}
      />
    </>
  );
};
