import RolesAndPermissions from 'components/rolesAndPermissions/RolesAndPermissions';
import React from 'react';

export const RolesAndPermissionsContainer = () => {
  return (
    <div>
      <RolesAndPermissions />
    </div>
  );
};
