import list from '../apiService/list';
import form from '../apiService/form';
import destroy from '../apiService/destroy';

const users = {};
users.list = () => list('/rest/users', 'users');
users.form = () => form('/rest/users', 'users');
users.delete = () => destroy('/rest/users', 'users');

export default users;
