import React from 'react';
import { Link } from 'react-router-dom';

import styles from './advancedLink.scss';
import classNames from 'classnames';

export const AdvancedLink = (props) => {
  const { to, children, disabled, gray, href = false } = props;
  const handleClick = (e) => {
    const { disabled } = props;
    if (disabled === true) {
      e.preventDefault();
    } else if (props && props.onClick) {
      this.props.onClick();
    }
  };

  return (
    <span
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      className={classNames(styles.advancedLink, { [styles.gray]: gray })}
    >
      {href ? (
        <span>
          <a href={href} {...props} onClick={handleClick}>
            {children}
          </a>
        </span>
      ) : (
        <Link {...props} to={to} onClick={handleClick}>
          {children}
        </Link>
      )}
    </span>
  );
};
