import React from 'react';
import { AddRecuriterForm } from 'components/addRecruiterForm/addRecruiterForm';

export const AddRecuriterContainer = () => {
  return (
    <div>
      <AddRecuriterForm />
    </div>
  );
};
