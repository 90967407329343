import destroy from '../apiService/destroy';
import form from '../apiService/form';
import list from '../apiService/list';

const jobApplicants = {};
jobApplicants.list = () => list('rest/job-applications', 'jobApplicant');
jobApplicants.form = () => form('rest/job-applications', 'jobApplicant');
jobApplicants.delete = () => destroy('rest/job-applications', 'jobApplicant');

export default jobApplicants;
