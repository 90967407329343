import { isEmpty, map, omit } from 'lodash-es';
import React from 'react';
import { EmptyCard } from 'components/emptyCard/emptyCard';
import { JobIndexCard } from 'components/jobIndexCard/JobIndexCard';
import styles from './JobIndexList.module.scss';
import classNames from 'classnames';
import { Button } from 'components/button/button';
import { useNavigate } from 'react-router-dom';
import { routesPath } from '../../constants/routesPath';

export const JobIndexList = (props) => {
  const {
    jobs,
    colors,
    jobStatuses,
    deleteJob,
    update,
    create,
    activeJobStatus,
    archivedJobStatus,
    deletedJobStatus,
    jobsFilter,
  } = props;

  const navigate = useNavigate();

  return (
    <div className={classNames(styles.listWrapper)}>
      {!isEmpty(jobs) ? (
        map(jobs, (job, i) => {
          const newJob = omit({ ...job?.job }, [
            'id',
            'created',
            'published',
            'modified',
            'hl5_url',
            'last_synced',
            'num_views',
          ]);

          return (
            job.hidden && (
              <>
                <JobIndexCard
                  job={job}
                  colors={colors}
                  client={job?.company_name}
                  clientLogo={job?.company_logo}
                  key={job?.id}
                  jobStatuses={jobStatuses}
                  clickHandlers={{
                    activatedHandler: () => update(job?.url, { job_status: activeJobStatus }),
                    archivedHandler: () => update(job?.url, { job_status: archivedJobStatus }),
                    deletedHandler: () => deleteJob(job?.id),
                    duplicateHandler: () => create('jobs', newJob),
                  }}
                  onClick={(e) => {
                    // e.stopPropogation();
                    navigate(routesPath.jobApplicants(job.id), { replace: false });
                  }}
                  jobsFilter={jobsFilter}
                />
              </>
            )
          );
        })
      ) : isEmpty(jobs) ? (
        <div className={styles.card_container}>
          <center>
            <EmptyCard
              isCard
              title={"You don't have any job posts to display here. Create one now."}
              description={
                "It's quick and easy to create and publish jobs on Hospitality Leaders. \
        In no time your job post will be live for thousands of potential candidates."
              }
            >
              <Button
                submit
                inline
                onClick={() => navigate(routesPath.jobsFormPage, { replace: false })}
              >
                New job post
              </Button>
            </EmptyCard>
          </center>
        </div>
      ) : (
        <div className={classNames(styles.alert, styles.green, styles.big)}>
          <center>There has been an error</center>
        </div>
      )}
    </div>
  );
};
