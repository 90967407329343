import classNames from 'classnames';
import styles from '../jobForm/jobForm.module.scss';
import formStyles from '../formColumns/forms.module.scss';
import { CheckBoxGroup } from '../form/checkBoxGroup';
import { SelectInput } from '../form/selectInput';
import { MemberShipGradesCheckBox } from '../memberShipGradesCheckBox/memberShipGradesCheckBox';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';

export const JobFormCandidatesReq = (props) => {
  const {
    isFetchingQualifications,
    isFetchingLanguages,
    isFetchingMembershipGrade,
    preferredQualifications,
    languageOptions,
    membershipGrades,
    clients,
    setState,
  } = props;

  const client_id = useWatch({ name: 'client_id' });

  useEffect(() => {
    let selectedCandidate = clients?.find((client) => client.value == client_id);
    if (selectedCandidate) {
      setState((prevState) => ({
        ...prevState,
        images: selectedCandidate.images,
        logo: selectedCandidate.logo,
      }));
    }
  }, [client_id]);

  return (
    <div className={classNames(styles.compCardContainer)}>
      <div className={classNames(styles.compCardTitleContainer)}>
        <span className={classNames(styles.dividerTextLarge)}>CANDIDATE REQUIREMENTS</span>
      </div>
      <div className={classNames(styles.compCardContent, 'd-block')}>
        {/* PREFERRED QUALIFICATION */}
        <div className={classNames(formStyles.formInnerWrapper, 'form-inner-wrapper')}>
          <span className={classNames(styles.inputTitle)}>
            Preferred Qualifications
            <div className={classNames(styles.textSmall)}>
              {'What are the preferred qualifications candidates should have?'}
            </div>
          </span>
          {isFetchingQualifications ? (
            <p>Loading...</p>
          ) : (
            <div className={classNames(styles.fieldWrapper)}>
              <CheckBoxGroup
                classNameLabel="mb-2"
                options={preferredQualifications}
                name="qualifications"
                type="checkbox"
              />
            </div>
          )}
        </div>

        {/* LANGUAGE */}
        <span className={classNames(styles.inputTitle)}>
          Language
          <div className={classNames(styles.textSmall)}>
            {'What languages must the candidate know?'}
          </div>
        </span>
        {isFetchingLanguages ? (
          <p>Loading...</p>
        ) : (
          <div className={classNames(styles.fieldWrapper)}>
            <SelectInput
              name="languages"
              options={languageOptions}
              mode="multiple"
              required={true}
              searchable
              placeholder="Start typing to add languages"
              // multi
            />
            {/* <Field
                name="language"
                component={Select}
                placeholder="Start typing to add languages"
                options={this.state.languages}
                multi
              /> */}
          </div>
        )}
        {/* MEMBERSHIP GRADES */}
        <div className={classNames(formStyles.formInnerWrapper, 'form-inner-wrapper')}>
          <span className={classNames(styles.inputTitle)}>Membership Grades</span>
          <div className={classNames(styles.textSmall)}>
            {'Select if the candidate should have specific membership grades. '}
            <a
              className={styles.textLink}
              // href={siteLinks.membershipGradesIntro}
              target="_blank"
              rel="noopener noreferrer"
            >
              What are membership grades?
            </a>
          </div>
          {isFetchingMembershipGrade ? (
            <p>Loading...</p>
          ) : (
            <div className={classNames(styles.fieldWrapper)}>
              <MemberShipGradesCheckBox
                name="membership_grade_id"
                type="radio"
                options={membershipGrades}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
