import destroy from '../apiService/destroy';
import form from '../apiService/form';
import list from '../apiService/list';

const projects = {};
projects.list = () => list('/rest/projects', 'project');
projects.form = () => form('/rest/projects', 'project');
projects.delete = () => destroy('/rest/projects/delete', 'project');

export default projects;
