import React from 'react';
import { AdvancedLink } from '../../components/advancedLink/advancedLink';
import { routesPath } from '../../constants/routesPath';
import styles from 'components/jobIndexCard/JobForm.module.scss';
import classNames from 'classnames';
import { Heading } from '../../components/heading/heading';
import { JobConfirmForm } from '../../components/jobConfirmForm/jobConfirmForm';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateWizardStep } from '../../redux/common/commonSlice';
import { useDispatch } from 'react-redux';
import { useJobConatiner } from '../jobContainer/jobContainer';
import { JobsFormContainer } from '../jobsFormContainer/JobsFormContainer';

export const JobConfirmationContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jobData = useSelector((state) => state.commonReducer.jobData);

  const { form } = useJobConatiner();

  useEffect(() => {
    // if (!jobData?.state) {
    //   navigate(-1);
    //   return;
    // }
    dispatch(
      updateWizardStep({
        step: 1,
      }),
    );
  }, []);

  return (
    <span>
      <div className="d-none">
        <JobsFormContainer />
      </div>
      <div className={'mt-4 pt-3'}>
        <div className={classNames(styles.matchCompCardContent)}>
          {/* TITLE */}
          <div className={styles.sectionLabel}>
            <Heading size="large">{'Confirm details'}</Heading>
          </div>
        </div>

        <div className={classNames(styles.col_1_1)}>
          <JobConfirmForm />
        </div>
      </div>
    </span>
  );
};
