import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { MdClose as CloseIcon } from 'react-icons/md';

// import { avatarSize } from 'redux/modules/ui';
// import Avatar, { avatarOptions } from 'components/Avatar';

import styles from './coverLetter.module.scss';
import { Avatar, avatarOptions } from '../avatar/avatar';

export const CoverLetter = (props) => {
  const { onClose, talentName, talentJobTitle, talentAvatar, talentCoverLetter } = props;
  return (
    <div className={classNames(styles.coverLetterWrapper)}>
      <div className={styles.inner}>
        <div className={classNames(styles.header)}>
          <div className="d-flex">
            <Avatar
              {...avatarOptions(talentAvatar, 'talent')}
              src={talentAvatar}
              className={styles.avatar}
              name={talentName}
              // size={20}
              size={50}
            />
            <div className={styles.text}>
              <div className={styles.name}>{`${
                talentName ? talentName : 'Applicant'
              }'s Cover Letter`}</div>
              <div className={styles.description}>
                {talentJobTitle || 'Job Title (Holedo Manager)'}
              </div>
            </div>
          </div>
          <span className={classNames(styles.modalClose, styles.close)} onClick={onClose}>
            <CloseIcon size={20} />
          </span>
        </div>
        <div className={styles.body}>
          <div className={styles.content}>{talentCoverLetter}</div>
        </div>
      </div>
    </div>
  );
};
