import React from 'react';
import classNames from 'classnames';
import { SubMenuItem as ReactSubMenuItem, MenuList } from 'react-menu-list';
import { DropdownMenu } from 'components/dropDownMenu/dropDownMenu';
import { BiArrowToRight as CaretIcon } from 'react-icons/bi';

import styles from 'components/nav/nav.module.scss';

export const SubMenu = ({ zIndex, menuItem, className, children }) => (
  <DropdownMenu>
    <ReactSubMenuItem
      menuZIndex={zIndex}
      highlightedStyle={{ background: '#d6edff' }}
      menu={
        <div className={classNames('submenu', styles.subMenu, className)}>
          <MenuList>{children}</MenuList>
        </div>
      }
    >
      {menuItem} <CaretIcon size={16} />
    </ReactSubMenuItem>
  </DropdownMenu>
);
