import React from 'react';
import classNames from 'classnames';

import styles from './page.module.scss';

export const Page = ({ children, isNotSubmenu, bin }) => (
  <div
    className={classNames(
      { [styles.pageWrapper]: !bin },
      { [styles.pageWrapperBin]: bin },
      { [styles.notSubMenuWrapper]: isNotSubmenu },
    )}
  >
    {children}
  </div>
);
