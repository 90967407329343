import destroy from '../apiService/destroy';
import form from '../apiService/form';
import list from '../apiService/list';

const stages = {};
stages.list = () => list('/rest/pipeline-stages', 'stage');
stages.form = () => form('/rest/pipeline-stages', 'stage');
stages.delete = () => destroy('/rest/pipeline-stages/delete', 'stage');

export default stages;
