import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import { Avatar, avatarOptions } from 'components/avatar/avatar';
import { map, isEmpty, slice, size } from 'lodash';

import PageListSection from 'components/pageListSection/pageListSection';
import { ProjectCard } from 'components/projectCard/projectCard';
import { Section } from 'components/section/section';
import { ClientCard } from 'components/clientCard/clientCard';
import { EmptyCard } from 'components/emptyCard/emptyCard';
import { MdAdd as AddIcon } from 'react-icons/md';

import { RoundButton } from 'components/roundButton/roundButton';
import styles from './dashboard.module.scss';
import { Button } from 'components/button/button';
import { NavLink } from 'react-router-dom';
import { routesPath } from '../../constants/routesPath';
import { Divider } from '../divider/divider';
import { useNavigate } from 'react-router-dom';
// import apiService from 'components/services/apiService';
// import apiUrls from 'components/apiUrls';
import classNames from 'classnames';

export const Dashboard = (props) => {
  const {
    projects,
    clients,
    visibleProjects,
    visibleClients,
    projectsFilter,
    clientsFilter,
    onProjectSearch,
    onProjectAdd,
    onProjectRemove,
    onProjectEdit,
    onClientSearch,
    onClientAdd,
    onClientRemove,
    onClientEdit,
    searchedProjectsData,
    colors,
    onlogoClick,
  } = props;

  const navigate = useNavigate();

  const [parsedClients, setParsedClients] = useState([]);

  useEffect(() => {
    if (clients?.length) {
      setParsedClients(clients);
    }
  }, [clients]);

  const findColor = (colorId) => {
    let color = colors?.find((col) => col.id === colorId);
    return color?.hex;
  };
  return (
    <div className={styles.dashboardWrappper}>
      <PageListSection
        title="All clients"
        onSearch={onClientSearch}
        searchQuery={clientsFilter}
        onAdd={onClientAdd}
        className={styles.dashboardSectionList}
        searchPlaceholder="Search client"
      >
        {isEmpty(parsedClients) ? (
          <EmptyCard
            isCard
            title="Let’s get started.\nAdd your first client profile."
            description="Whose the company that you’re recruiting for? We first need to create the client
          profile and then we can start by adding a projects we need to recruit for."
          >
            <Button submit onClick={onClientAdd} inline>
              New client
            </Button>
          </EmptyCard>
        ) : (
          <div
            className={styles.pageSectionContainer}
            // style={{ width: `${size(clients) * (364 + 20) - 20}px` }}
          >
            {parsedClients.length &&
              map(parsedClients, (client, index) => {
                return (
                  <ClientCard
                    parsedClients={parsedClients}
                    setParsedClient={setParsedClients}
                    key={`client-${client.id}`}
                    showUpdateCover
                    client={client}
                    onClientRemove={onClientRemove}
                    onEdit={onClientEdit}
                    showSettings
                    className={styles.dashboardClient}
                    extraClass={'clientCardDashboard'}
                    onlogoClick={() => onlogoClick(client)}
                    onProjectAdd={onProjectAdd}
                    id={client?.id}
                    index={index}
                  >
                    <Section title="Projects" headingSize="large" count={size(client.projects)}>
                      <div
                        className={styles.clientProjects}
                        style={{ display: 'flex', overflow: 'auto' }}
                      >
                        {map(slice(client.projects, 0, 20), (project, k) => (
                          <div className={styles.clientProjectsItem} key={k}>
                            <NavLink
                              key={`client-project-${project.id}`}
                              to={routesPath.projectPipeline(project.id, project.pipeline_id)}
                            >
                              <Avatar
                                // onClick={() => navigate(routesPath.dashboard, { replace: false })}
                                color={findColor(project.colour_id)}
                                {...avatarOptions(project, 'project')}
                                size={45}
                              />
                            </NavLink>
                            {/* <NavLink
                              key={`client-project-${project.id}`}
                              to={routesPath.projectPipeline(project.id, project.pipeline_id)}
                            >
                              <Avatar
                                // onClick={() => navigate(routesPath.dashboard, { replace: false })}
                                color={findColor(project.colour_id)}
                                {...avatarOptions(project, 'project')}
                                size={45}
                              />
                            </NavLink>
                            <NavLink
                              key={`client-project-${project.id}`}
                              to={routesPath.projectPipeline(project.id, project.pipeline_id)}
                            >
                              <Avatar
                                // onClick={() => navigate(routesPath.dashboard, { replace: false })}
                                color={findColor(project.colour_id)}
                                {...avatarOptions(project, 'project')}
                                size={45}
                              />
                            </NavLink>
                            <NavLink
                              key={`client-project-${project.id}`}
                              to={routesPath.projectPipeline(project.id, project.pipeline_id)}
                            >
                              <Avatar
                                // onClick={() => navigate(routesPath.dashboard, { replace: false })}
                                color={findColor(project.colour_id)}
                                {...avatarOptions(project, 'project')}
                                size={45}
                              />
                            </NavLink>
                            <NavLink
                              key={`client-project-${project.id}`}
                              to={routesPath.projectPipeline(project.id, project.pipeline_id)}
                            >
                              <Avatar
                                // onClick={() => navigate(routesPath.dashboard, { replace: false })}
                                color={findColor(project.colour_id)}
                                {...avatarOptions(project, 'project')}
                                size={45}
                              />
                            </NavLink>
                            <NavLink
                              key={`client-project-${project.id}`}
                              to={routesPath.projectPipeline(project.id, project.pipeline_id)}
                            >
                              <Avatar
                                // onClick={() => navigate(routesPath.dashboard, { replace: false })}
                                color={findColor(project.colour_id)}
                                {...avatarOptions(project, 'project')}
                                size={45}
                              />
                            </NavLink> */}
                          </div>
                        ))}
                        <div className={styles.clientProjectsItem}>
                          <RoundButton
                            size="medium"
                            theme="default"
                            onClick={() => onProjectAdd(client)}
                          >
                            <AddIcon size={25} />
                          </RoundButton>
                        </div>
                      </div>
                    </Section>
                  </ClientCard>
                );
              })}
          </div>
        )}
      </PageListSection>
      <hr className={styles.separator} />

      <PageListSection
        title="All projects"
        onSearch={onProjectSearch}
        searchQuery={projectsFilter}
        // onAdd={onProjectAdd}
        searchPlaceholder="Search project"
      >
        {isEmpty(projects) ? (
          <EmptyCard
            isCard
            title="Things are looking a little\nbare here, recruiter."
            description="You haven’t created any projects yet. Get started by looking at the example
      project alongside or create your first project now."
          >
            <Button submit onClick={onProjectAdd} inline>
              New project
            </Button>
          </EmptyCard>
        ) : (
          <div
            className={classNames(styles.pageSectionContainer)}
            // style={{ width: `${size(projects) * (364 + 20) - 20}px` }}
          >
            {map(searchedProjectsData, (project) => {
              return (
                <ProjectCard
                  key={`project-${project.id}`}
                  project={project}
                  onRemove={onProjectRemove}
                  onClick={() =>
                    navigate(routesPath.projectPipeline(project?.id, project?.pipeline_id), {
                      replace: false,
                    })
                  }
                  onEdit={onProjectEdit}
                  color={findColor(project?.colour_id)}
                />
              );
            })}
          </div>
        )}
      </PageListSection>
    </div>
  );
};
