import React from 'react';
import { map, isEmpty } from 'lodash';
import classNames from 'classnames';

import { List } from 'components/list/list';
import { MdCheck as CheckIcon } from 'react-icons/md';
import { Heading } from 'components/heading/heading';

import styles from './talent.module.scss';

const Job = ({ title, company, startYear, endYear, salary, currency, leaveDays, isCurrent }) => (
  <div className={classNames(styles.jobWrapper, { [styles.isCurrent]: isCurrent })}>
    <div className={styles.jobSection}>
      <div className={styles.jobYear}>
        {isCurrent && <CheckIcon size={20} />}
        {!isCurrent && endYear}
      </div>
      <div className={styles.jobData}>
        <Heading size="small" className={styles.mainHeading}>
          {title}
        </Heading>
        <p className={styles.subHeading}>{company}</p>
        <p className={styles.noteHeading}>
          {isCurrent && startYear}
          {!isCurrent && (
            <>
              {startYear} <span> - </span> {endYear}
            </>
          )}
        </p>
      </div>
    </div>
    {isCurrent && (
      <>
        {salary && (
          <div className={styles.jobSection}>
            <div className={styles.jobData}>
              <Heading size="small" className={styles.mainHeading}>
                {currency} {salary}
              </Heading>
              <Heading size="xsmall" className={styles.noteHeading}>
                Salary
              </Heading>
            </div>
          </div>
        )}
        {leaveDays && (
          <div className={styles.jobSection}>
            <div className={styles.jobData}>
              <Heading size="small" className={styles.mainHeading}>
                {leaveDays}+ days
              </Heading>
              <Heading size="xsmall" className={styles.noteHeading}>
                Leave per annum
              </Heading>
            </div>
          </div>
        )}
      </>
    )}
  </div>
);

export const TalentWorkHistory = ({ history }) => (
  <div className={styles.talentWorkHistory}>
    {!isEmpty(history) && (
      <List>
        {map(history, (item, i) => (
          <Job
            key={`work-history-${i}`}
            startYear={item.start_year}
            endYear={item.end_year}
            title={item.job_title}
            company={item.company}
            salary={item.salary}
            currency={item.currency}
            leaveDays={item.leave_days_per_annum}
            isCurrent={item?.isCurrent}
          />
        ))}
      </List>
    )}
  </div>
);
