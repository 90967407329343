import React, { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import { MdAddAPhoto } from 'react-icons/md';
import { dropZoneimageUploader } from '../../utils/imageUploadHelper';
import styles from '../clientCard/clientCard.module.scss';
import { Button } from '../button/button';

export const LogoUploader = (props) => {
  const { name, children, isIcon, size, noImg, logoDefault } = props;

  const {
    register,
    formState: { errors },
    unregister,
    setValue,
  } = useFormContext();
  const originalValue = useWatch({ name: name });
  const [loading, setLoading] = useState(false);

  const input = useRef();

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  useEffect(() => {
    if (logoDefault) {
      setValue(name, logoDefault, { shouldValidate: true });
    }
  }, [logoDefault]);

  const handleChange = async (event) => {
    let file = event.target.files[0];

    if (!file) {
      return;
    }

    try {
      setLoading(true);
      const response = await dropZoneimageUploader(file);

      setValue(name, response.image, { shouldValidate: true });
      input.current.value = '';
      setLoading(false);
    } catch (error) {
      input.current.value = '';
      setLoading(false);
      alert(error);
    }
  };

  const handleFileOpening = () => {
    input.current.click();
  };

  return (
    <>
      <div>
        {children}
        <div>
          <Button inline={true} submit size={size} onClick={handleFileOpening}>
            {isIcon && <MdAddAPhoto size={30} />}
            Upload Logo
            <input
              className="d-none"
              disabled={loading}
              accept="image/*"
              type="file"
              onChange={handleChange}
              ref={input}
            />
            {originalValue && !noImg && (
              <img
                src={originalValue}
                width="110"
                height="110"
                alt={originalValue}
                className={styles.clientLogoImage}
              />
            )}
          </Button>
        </div>
      </div>
      {errors[name]?.message && (
        <span className={classNames(styles.fieldError, 'form-error-element')}>
          {errors[name]?.message}
        </span>
      )}
    </>
  );
};
