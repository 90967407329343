import { authHeader } from '../../constants/authenticationHeader';
import { baseSliceWithTags } from '../baseSlice';

const destroy = (path, module) => {
  const destroyApi = baseSliceWithTags.injectEndpoints({
    endpoints: (builder) => {
      return {
        [module + 'delete']: builder.mutation({
          query: (payload) => ({
            url: path + (payload?.extendedPath || ''),
            method: 'post',
            data: payload?.body,
            params: payload?.params,

            csrf: authHeader(),
          }),
          invalidatesTags: [module],
        }),
      };
    },
  });
  return destroyApi;
};
export default destroy;
