import React from 'react';
import classNames from 'classnames';

import styles from '../nav/nav.module.scss';

export const MenuScrollable = ({ className, children }) => (
  <div className={classNames('menu-items-scroll', styles.menuItemsScroll, className)}>
    {children}
  </div>
);
