import React, { Component } from 'react';

import { Section } from 'components/section/section';

import { size, isEqual, map, filter } from 'lodash';

import styles from './talent.module.scss';
import { NoteFormContainer } from '../../containers/noteFormContainer/noteFormContainer';
import { NotesList } from '../notesList/notesList';
import dayjs from 'dayjs';
import notes from '../../redux/modules/notes';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useState } from 'react';
var relativeTime = require('dayjs/plugin/relativeTime');

export const TalentNotes = (props) => {
  const { talent, isNoteFormOpen, onFormOpen, onNoteDelete, userId, isLoading, userNotes } = props;

  const [initialValue, setInitialValue] = useState(null);
  const {
    data: notesData,
    error: notesError,
    isFetching: notesLoading,
  } = notes.list().useNotelistQuery(
    { skip: userId },
    {
      selectFromResult: ({ data }) => ({
        data: data?.data?.filter((el) => el.user_id === userId),
      }),
    },
  );

  const [deleteNote, noteDeleteData] = notes.delete().useNotedeleteMutation();

  const getTalentNotes = () => {
    dayjs.extend(relativeTime);

    let notes = [];
    notesData?.reverse().map((note) => {
      notes.push({
        id: note.id,
        note: note?.note === 'emptyNote' ? 'N/A' : note?.note,
        attachment: note.attachment,
        author: note.author,
        created: note?.created && dayjs(note.created).toNow(true),
      });
    });
    return notes;
  };

  useEffect(() => {
    if (noteDeleteData) {
      if (noteDeleteData?.isSuccess) {
        toast.success('Note Deleted SuccessFully!');
      }
      if (noteDeleteData?.error) {
        toast.error('Failed To Delete Note!');
      }
    }
  }, [noteDeleteData]);

  const onEditNote = (note) => {
    setInitialValue({
      note: note?.note,
      id: note?.id,
      attachment: note?.attachment,
      user_id: userId,
    });
  };
  return (
    <div className={styles.talentSidebarNotesWrapper}>
      <div className={styles.notesForm}>
        <NoteFormContainer
          userNotes={getTalentNotes()}
          initialValue={initialValue}
          // getNotes={getTalentNotes()}
          userId={userId}
          talent={talent}
          isOpen={isNoteFormOpen}
          onOpen={onFormOpen}
          className={styles.sidebarNoteForm}
          setInitialValue={setInitialValue}
        />
      </div>
      {notesLoading ? (
        <h4>Loading...</h4>
      ) : (
        <Section
          title="Notes & Attachments"
          count={size(getTalentNotes())}
          headingSize="large"
          isToggleable
        >
          {notes && (
            <NotesList
              userId={userId}
              notes={getTalentNotes()}
              onDeleteNote={(id) => deleteNote({ body: { id: id } })}
              onEditNote={onEditNote}
            />
          )}
        </Section>
      )}
    </div>
  );
};
