import React from 'react';
import classNames from 'classnames';

import styles from './role.module.scss';

const Role = ({ className, children }) => (
  <span className={classNames('role', styles.role, { [styles[className]]: className })}>
    {children}
  </span>
);

export default Role;
