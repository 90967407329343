import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { isInteger, map } from 'lodash';
import { MdMore as TagsIcon } from 'react-icons/md';
import { MdVisibility as VisibleIcon } from 'react-icons/md';
import { MdOutlineVisibilityOff as InVisibleIcon } from 'react-icons/md';
import { MdDownload as DownloadIcon } from 'react-icons/md';
import { MdArrowDropDown as CaretIcon } from 'react-icons/md';
import { MdViewWeek as ColumnsIcon } from 'react-icons/md';
import { WithContext as ReactTags } from 'react-tag-input';

import { ContextMenu } from '../contextMenu/contextMenu';
import { Heading } from '../heading/heading';
import { Button } from '../button/button';
import styles from './actionsBar.module.scss';
import { Dropdown } from '../dropDown/dropDown';
import { MenuSlot } from '../menuSlot/menuSlot';
import tags from 'redux/modules/tags';
import dataService from '../../services/dataService';
import toast from 'react-hot-toast';
import { apiUrls } from '../../constants/apiurls';

export const ActionsBar = (props) => {
  const {
    className,
    count,
    tableColumns,
    isShowOnlySelectedActive,
    onShowSelected,
    setIsFiltered,
    children,
    isFiltered,
    data,
  } = props;

  const [createTag, tagsCreateData] = tags.form().useTagcreateMutation();
  const [deleteTag, tagsDeleteData] = tags.delete().useTagdeleteMutation();
  const {
    data: tagsData,
    isFetching: isTagsLoading,
    error: tagsError,
    isSuccess: tagsSuccess,
  } = tags.list().useTaglistQuery({ extendedPath: apiUrls.getTags() });

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const [tages, setTags] = useState([]);

  const handleFilters = () => {
    setIsFiltered((prevState) => !prevState);
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  useEffect(() => {
    if (tagsCreateData.isSuccess) {
      toast.success('Tag Added To Candidates!');
      let result = dataService.parseTags(tagsCreateData?.data);
      setTags([...tages, result]);
    }
    if (tagsCreateData.isError) {
      toast.error('Failed To Add Tags!');
    }
  }, [tagsCreateData]);

  useEffect(() => {
    if (tagsData?.data?.length || data?.length) {
      let result = dataService.parseTagsForTalent(tagsData?.data, data);
      setTags([...result]);
    }
  }, [tagsData?.data, data]);

  const handleDelete = (i) => {
    deleteTag({
      body: {
        id: tages[i].tagId,
      },
    });

    setTags(tages.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    createTag({
      body: {
        name: tag.text,
        users: data.map((dat) => {
          return { id: dat.original['#'] };
        }),
      },
    });
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tages.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  return (
    <div
      className={classNames(styles.actionsBar, className)}
      style={{ position: 'relative', top: '0', width: '30%', marginBottom: '10px' }}
    >
      {isInteger(count) && (
        <div className={styles.resultsCount}>
          <Heading size="small" className={styles.resultsCountHeading}>
            {count} results
          </Heading>
        </div>
      )}

      <div className={styles.contextMenu}>
        <ContextMenu visible>
          <Button
            onClick={() => {
              handleFilters();
              // onShowSelected();
            }}
            className={{ [styles.active]: isShowOnlySelectedActive }}
          >
            {!isFiltered ? <VisibleIcon size={20} /> : <InVisibleIcon size={20} />}
          </Button>
          <Button
            onClick={onShowSelected}
            className={{ [styles.active]: isShowOnlySelectedActive }}
          >
            {!isFiltered ? (
              <div>
                <DownloadIcon size={20} />
                <span style={{ color: '#B1B7BC' }}>CV</span>
              </div>
            ) : (
              <>
                <DownloadIcon size={20} />
                <span style={{ color: '#B1B7BC' }}>CV</span>
              </>
            )}
          </Button>

          <Dropdown
            heading="Add Tags"
            disabled={!data?.length}
            trigger={
              <Button>
                <TagsIcon style={!data?.length && { pointerEvents: 'none' }} size={20} />
                <CaretIcon style={!data?.length && { pointerEvents: 'none' }} size={18} />
              </Button>
            }
          >
            <div className={styles.ReactTags}>
              <ReactTags
                classNames={'tagsStyles'}
                tags={tages || []}
                delimiters={delimiters}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                // handleTagClick={handleTagClick}
                inputFieldPosition="top"
                inline={true}
                autocomplete
                editable
              />
            </div>
          </Dropdown>

          <Dropdown
            heading="Show Columns"
            className={styles.showColumnsDropdown}
            trigger={
              <Button>
                <ColumnsIcon size={20} />
                <CaretIcon size={18} />
              </Button>
            }
          >
            {map(tableColumns, (column, index) => (
              <MenuSlot key={index} onSelected={(e) => e.stopPropagation()}>
                <div className={styles.columnInput}>
                  <label>
                    <input
                      type="checkbox"
                      className="form-element"
                      {...column.getToggleHiddenProps()}
                    />{' '}
                    <span style={{ fontSize: '18px' }} className="">
                      {column.id.charAt(0).toUpperCase() + column.id.slice(1)}
                    </span>
                  </label>
                </div>
              </MenuSlot>
            ))}
          </Dropdown>
        </ContextMenu>
      </div>
      <div className={styles.pushRight}>{children}</div>
    </div>
  );
};
