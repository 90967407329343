import form from '../apiService/form';
import list from '../apiService/list';
import destroy from '../apiService/destroy';

const clients = {};
clients.list = () => list('/rest/clients', 'clients');
clients.form = () => form('/rest/clients', 'clients');
clients.delete = () => destroy('/rest/clients/delete', 'clients');

export default clients;
