import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { routesPath } from '../constants/routesPath';
import { SidebarContainer } from '../containers/sidebarContainer/sidebarContainer';
import { CoreLayout } from '../layouts/CoreLayout';
import storageService from '../services/storageService';

export const PrivateRoute = ({ component: RouteComponent, roles }) => {
  const isAuthenticated = storageService.getToken();

  if (isAuthenticated) {
    return (
      <CoreLayout>
        <SidebarContainer>
          <RouteComponent />
        </SidebarContainer>
      </CoreLayout>
    );
  }

  return <Navigate to={routesPath.login} />;
};
