import classNames from 'classnames';
import styles from './jobFormDescription.module.scss';
import formStyles from '../formColumns/forms.module.scss';
import { RadioButtonGroup } from '../radioButtonGroup/radioButtonGroup';
import { RadioGroup } from '../form/radioGroup';
import { CheckBoxGroup } from '../form/checkBoxGroup';
import { Quil } from '../form/quil';
import { SelectInput } from '../form/selectInput';
import { useWatch } from 'react-hook-form';

export const JobFormDescription = (props) => {
  const {
    isFetchingPositionLevels,
    isFetchingEmpTypes,
    employmentTypes,
    positionLevels,
    durations,
    handleDurationType,
  } = props;

  const employment_type_id = useWatch({ name: 'employment_type_id' });

  return (
    <div className={classNames(styles.compCardContainer)}>
      <div className={classNames(styles.compCardTitleContainer)}>
        <span className={classNames(styles.dividerTextLarge)}>JOB DETAILS</span>
      </div>
      <div className={classNames(styles.compCardContent, 'd-block')}>
        {/* EMPLOYMENT TYPE */}
        <span className={classNames(styles.inputTitle)}>Employment type*</span>
        {isFetchingEmpTypes ? (
          <p>Loading...</p>
        ) : (
          <div className={classNames(styles.fieldWrapper, 'd-flex')}>
            <RadioGroup
              name="employment_type_id"
              options={employmentTypes}
              onChange={handleDurationType}
              type="radio"
            />
            <div className="d-flex align-self-end ms-sm-4 ms-auto">
              <SelectInput
                name="employment_duration_id"
                options={durations}
                placeholder="Select duration"
                disabled={employment_type_id != '3'}
              />
            </div>
          </div>
        )}
        {/* POSITION LEVEL */}
        <div className={classNames(formStyles.formInnerWrapper, 'form-inner-wrapper')}>
          <span className={classNames(styles.inputTitle)}>
            Position level*
            <div className={classNames(styles.textSmall)}>
              What level or rank best describes this job position?
            </div>
          </span>
          {isFetchingPositionLevels ? (
            <p>Loading...</p>
          ) : (
            <div className={classNames(styles.fieldWrapper)}>
              <CheckBoxGroup
                classNameLabel="mb-2 positionLevelCheckbox"
                options={positionLevels}
                name="position_levels"
                type="checkbox"
              />
            </div>
          )}
        </div>

        {/* JOB DESCRIPTION */}
        <span className={classNames(styles.inputTitle)}>
          Job description*
          <div className={classNames(styles.textSmall)}>{'Describe the job position'}</div>
        </span>
        <div className={styles.fieldWrapper}>
          <Quil name="description" />
        </div>
      </div>
    </div>
  );
};
